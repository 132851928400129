// THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT!
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type ActiveAlarmDetails = {
  __typename?: 'ActiveAlarmDetails';
  date?: Maybe<Scalars['String']>;
  events?: Maybe<Scalars['JSON']>;
};

export type AlarmGraphDetails = {
  __typename?: 'AlarmGraphDetails';
  alarmsCount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
};

export type AssignUsersInput = {
  oktaIds: Array<Scalars['String']>;
  siteId: Scalars['String'];
};

export type AssignedTenantItem = {
  __typename?: 'AssignedTenantItem';
  count: Scalars['Int'];
  tenant: Tenant;
};

export type AssignedTenantsListOutput = {
  __typename?: 'AssignedTenantsListOutput';
  assignedTenantsList: Array<Maybe<AssignedTenantItem>>;
  queryStats?: Maybe<QueryStatistics>;
};

export enum BusinessUnit {
  Autronica = 'Autronica',
  Marioff = 'Marioff'
}

export enum ClientDeviceType {
  Android = 'Android',
  Ios = 'Ios',
  Web = 'Web'
}

export type CompleteInspectionInput = {
  checklists?: InputMaybe<Array<CompleteInspectionInputChecklist>>;
  comments?: InputMaybe<Array<CompleteInspectionInputComment>>;
  completedAt: Scalars['Float'];
  inspectionId: Scalars['String'];
};

export type CompleteInspectionInputChecklist = {
  id: Scalars['String'];
};

export type CompleteInspectionInputComment = {
  createdAt: Scalars['Float'];
  mediaFilePath?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export enum ConfigStatus {
  Failed = 'Failed',
  Ok = 'Ok',
  Pending = 'Pending',
  Unknown = 'Unknown'
}

export type ConnectivityStatusDetails = {
  __typename?: 'ConnectivityStatusDetails';
  componentName?: Maybe<Scalars['String']>;
  componentState?: Maybe<Scalars['String']>;
  connectivity?: Maybe<Scalars['Boolean']>;
  loopUnits?: Maybe<Scalars['Int']>;
  systemId?: Maybe<Scalars['String']>;
  systemType?: Maybe<Scalars['String']>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateInstallationDeviceInput = {
  detectionZone: Scalars['String'];
  flash: Scalars['Boolean'];
  heat: Scalars['Boolean'];
  serialNumber?: InputMaybe<Scalars['String']>;
  sounder: Scalars['Boolean'];
  tag: Scalars['String'];
};

export type CreateNodeInput = {
  ipAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serialNumber: Scalars['String'];
  siteId: Scalars['ID'];
  subnetMask?: InputMaybe<Scalars['String']>;
};

export enum CreateNodeMessageStatus {
  MODEM_IMEI_REGISTERED = 'MODEM_IMEI_REGISTERED',
  SAME_NODE_DEVICE_ID = 'SAME_NODE_DEVICE_ID'
}

export enum CreateNodeMessageType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

export type CreateNodeWithDevice = {
  __typename?: 'CreateNodeWithDevice';
  message: Scalars['String'];
  messageStatus?: Maybe<CreateNodeMessageStatus>;
  messageType: CreateNodeMessageType;
  node?: Maybe<NodeWithDevice>;
};

export type CreateSystemInput = {
  businessSystemId: Scalars['String'];
  ipAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  port?: InputMaybe<Scalars['String']>;
  type: SystemType;
  userName?: InputMaybe<Scalars['String']>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  permissions: Scalars['String'];
  user?: Maybe<User>;
};

export type Device = {
  __typename?: 'Device';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  deviceId: Scalars['String'];
  id: Scalars['String'];
  isHistorical: Scalars['Boolean'];
  lastHeartbeatAt?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  locationDetails?: Maybe<Scalars['String']>;
  model: Scalars['String'];
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  properties: Array<Maybe<DeviceProperty>>;
  serialNumber: Scalars['String'];
  state: DeviceState;
  system?: Maybe<System>;
  systemConfig?: Maybe<SystemConfig>;
  type: Scalars['String'];
};

export type DeviceFilterInput = {
  deviceId?: InputMaybe<Scalars['String']>;
  isHistorical?: InputMaybe<Scalars['Boolean']>;
  lastHeartbeatAt?: InputMaybe<Scalars['String']>;
  locationDetails?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<DeviceState>;
  type?: InputMaybe<Scalars['String']>;
};

export type DeviceProperty = {
  __typename?: 'DeviceProperty';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type DeviceRegistration = {
  clientDeviceType: ClientDeviceType;
  token: Scalars['String'];
};

export enum DeviceState {
  NeverConnected = 'NeverConnected',
  Offline = 'Offline',
  Online = 'Online'
}

export type DevicesSummary = {
  __typename?: 'DevicesSummary';
  devices: Array<Device>;
  totalCount: Scalars['Int'];
};

export type EventHistoryResult = {
  __typename?: 'EventHistoryResult';
  historyData?: Maybe<Array<Maybe<EventsHistory>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EventStatus = {
  __typename?: 'EventStatus';
  type: Scalars['String'];
  value: Scalars['Int'];
};

export enum EventType {
  alarm = 'alarm',
  event = 'event'
}

export type Events = {
  __typename?: 'Events';
  blockName?: Maybe<Scalars['String']>;
  buDeviceType?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  eventDescription?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inverted?: Maybe<Scalars['Boolean']>;
  maintenanceQueue?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type EventsHistory = {
  __typename?: 'EventsHistory';
  blockName?: Maybe<Scalars['String']>;
  buDeviceType?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventValue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastUserActivity?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export enum ExternalNetworkStatus {
  Connecting = 'Connecting',
  NeverConnected = 'NeverConnected',
  Offline = 'Offline',
  Online = 'Online'
}

export type FalseActivation = {
  __typename?: 'FalseActivation';
  activationTimeLimit?: Maybe<Scalars['Float']>;
  averageStandByPumpingTimeSec?: Maybe<Scalars['Float']>;
  eventDate?: Maybe<Scalars['String']>;
  standByFrequency?: Maybe<Scalars['Float']>;
  standByPumpingStatus?: Maybe<PumpingStatus>;
};

export enum FilterOperator {
  Contains = 'Contains',
  EndsWith = 'EndsWith',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
  StartsWith = 'StartsWith'
}

export type FilteringParameters = {
  filterByColumn: Scalars['String'];
  filterOperator: FilterOperator;
  filterValue?: InputMaybe<Scalars['String']>;
};

export type GatewayDetails = {
  __typename?: 'GatewayDetails';
  connected: Scalars['String'];
  notConnected: Scalars['String'];
  total: Scalars['String'];
};

export enum GeneralSystemStatus {
  Alarm = 'Alarm',
  Archived = 'Archived',
  Commissioning = 'Commissioning',
  Fault = 'Fault',
  InTest = 'InTest',
  Installation = 'Installation',
  NeedAttention = 'NeedAttention',
  Normal = 'Normal',
  Offline = 'Offline',
  Standalone = 'Standalone'
}

export type GetInspectionsByDateInput = {
  scheduledFrom: Scalars['Float'];
  scheduledTo: Scalars['Float'];
  status?: InputMaybe<Array<InspectionStatus>>;
};

export type GetInspectionsInput = {
  queryParams?: InputMaybe<QueryParameters>;
  scheduledFrom?: InputMaybe<Scalars['Float']>;
  scheduledTo?: InputMaybe<Scalars['Float']>;
  siteId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InspectionStatus>>;
};

export type GetPumpConditionMonitoringInput = {
  endDate: Scalars['Float'];
  siteId: Scalars['String'];
  startDate: Scalars['Float'];
  unitId: Scalars['String'];
};

export type GetSiteSystemAnalyticsInput = {
  endDate: Scalars['Float'];
  siteId: Scalars['String'];
  startDate: Scalars['Float'];
  systemId: Scalars['String'];
};

export enum HttpVerbs {
  GET = 'GET',
  PUT = 'PUT'
}

export type Inspection = {
  __typename?: 'Inspection';
  checklists: Array<InspectionCheckList>;
  comments?: Maybe<Array<Maybe<InspectionComment>>>;
  completedAt?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  device?: Maybe<Device>;
  id: Scalars['String'];
  name: Scalars['String'];
  scheduledAt: Scalars['Float'];
  site?: Maybe<Site>;
  startedBy?: Maybe<User>;
  status?: Maybe<InspectionStatus>;
  template?: Maybe<InspectionTemplateItem>;
  type?: Maybe<InspectionType>;
  version?: Maybe<Scalars['String']>;
};

export type InspectionCheckList = {
  __typename?: 'InspectionCheckList';
  id: Scalars['String'];
  isChecked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  steps: Array<InspectionCheckListStep>;
  title: Scalars['String'];
};

export type InspectionCheckListStep = {
  __typename?: 'InspectionCheckListStep';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mediaFilePath?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
};

export type InspectionComment = {
  __typename?: 'InspectionComment';
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  mediaFilePath?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type InspectionListOutput = {
  __typename?: 'InspectionListOutput';
  inspections: Array<Maybe<Inspection>>;
  queryStats?: Maybe<QueryStatistics>;
};

export type InspectionMasterTemplateItem = {
  __typename?: 'InspectionMasterTemplateItem';
  archivedAt?: Maybe<Scalars['Float']>;
  archivedBy?: Maybe<User>;
  assignedTenants?: Maybe<Array<Maybe<TemplateAssignedTenant>>>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InspectionTemplateStatus>;
  type?: Maybe<InspectionType>;
};

export type InspectionMasterTemplatesListOutput = {
  __typename?: 'InspectionMasterTemplatesListOutput';
  inspectionMasterTemplates: Array<Maybe<InspectionMasterTemplateItem>>;
  queryStats?: Maybe<QueryStatistics>;
};

export type InspectionOutput = {
  __typename?: 'InspectionOutput';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<InspectionType>;
};

export enum InspectionStatus {
  Canceled = 'Canceled',
  Completed = 'Completed',
  Created = 'Created',
  InProgress = 'InProgress'
}

export type InspectionTemplateCheckListInput = {
  steps?: InputMaybe<Array<InspectionTemplateCheckListStepInput>>;
  title: Scalars['String'];
};

export type InspectionTemplateCheckListStepInput = {
  description?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type InspectionTemplateInput = {
  checkLists: Array<InspectionTemplateCheckListInput>;
  name: Scalars['String'];
  type: InspectionType;
};

export type InspectionTemplateItem = {
  __typename?: 'InspectionTemplateItem';
  archivedAt?: Maybe<Scalars['Float']>;
  archivedBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InspectionType>;
};

export enum InspectionTemplateStatus {
  Active = 'Active',
  Archived = 'Archived'
}

export enum InspectionType {
  Custom = 'Custom',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type InstallationDevice = {
  __typename?: 'InstallationDevice';
  completedAt: Scalars['String'];
  createdAt: Scalars['String'];
  detectionZone?: Maybe<Scalars['String']>;
  flash?: Maybe<Scalars['Boolean']>;
  heat?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  serialNumber?: Maybe<Scalars['String']>;
  sounder?: Maybe<Scalars['Boolean']>;
  status: InstallationDeviceStatus;
  tag: Scalars['String'];
};

export type InstallationDeviceListOutput = {
  __typename?: 'InstallationDeviceListOutput';
  installationDevice?: Maybe<Array<Maybe<InstallationDevice>>>;
  queryStats?: Maybe<QueryStatistics>;
};

export type InstallationDeviceOutput = {
  __typename?: 'InstallationDeviceOutput';
  id: Scalars['ID'];
  tag: Scalars['String'];
};

export enum InstallationDeviceStatus {
  Completed = 'Completed',
  Created = 'Created'
}

export type InstallationLoop = {
  __typename?: 'InstallationLoop';
  commissionedAt: Scalars['String'];
  createdAt: Scalars['String'];
  devicesCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: InstallationLoopStatus;
};

export type InstallationLoopOutput = {
  __typename?: 'InstallationLoopOutput';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum InstallationLoopStatus {
  Commissioned = 'Commissioned',
  Locked = 'Locked',
  Pending = 'Pending'
}

export enum LiveDataStatus {
  Failed = 'Failed',
  Ok = 'Ok',
  Pending = 'Pending',
  Unknown = 'Unknown'
}

export type Location = {
  __typename?: 'Location';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentLocation?: Maybe<Location>;
  site?: Maybe<Site>;
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addUser?: Maybe<User>;
  applySiteNodeConfigurations?: Maybe<Scalars['Boolean']>;
  archiveMasterTemplate?: Maybe<Scalars['Boolean']>;
  archiveSystem?: Maybe<Scalars['Boolean']>;
  assignMasterTemplateToTenants?: Maybe<Scalars['Boolean']>;
  assignSystemToNode?: Maybe<Scalars['Boolean']>;
  assignUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  completeInspection?: Maybe<InspectionOutput>;
  createInspectionTemplate?: Maybe<Scalars['String']>;
  createInstallationDevice: InstallationDeviceOutput;
  createInstallationLoop: InstallationLoopOutput;
  createMasterTemplate?: Maybe<Scalars['Boolean']>;
  createNode?: Maybe<NodeWithDevice>;
  createSite: Site;
  createSystem?: Maybe<System>;
  createSystemConnection: SystemConnection;
  createTenant?: Maybe<Tenant>;
  deleteInspection?: Maybe<Scalars['Boolean']>;
  deleteInstallationDevice?: Maybe<Scalars['Boolean']>;
  deleteInstallationLoop: Scalars['Boolean'];
  deleteSystem?: Maybe<Scalars['Boolean']>;
  deleteSystemConnection?: Maybe<Scalars['Boolean']>;
  dismissAllNotifications?: Maybe<Scalars['Boolean']>;
  dismissNotification?: Maybe<Scalars['Boolean']>;
  populateDBWithInstallationFileContent?: Maybe<Scalars['Boolean']>;
  readAllNotifications?: Maybe<Scalars['Boolean']>;
  readNotification?: Maybe<Scalars['Boolean']>;
  registerDevice: Scalars['Boolean'];
  resetNotificationSettings?: Maybe<Scalars['Boolean']>;
  scheduleInspection?: Maybe<InspectionOutput>;
  shareInspection?: Maybe<Scalars['Boolean']>;
  startInspection?: Maybe<Inspection>;
  unRegisterDevice: Scalars['Boolean'];
  unassignMasterTemplates?: Maybe<Scalars['Boolean']>;
  unassignSystemFromNode?: Maybe<Scalars['Boolean']>;
  unassignUsers?: Maybe<Scalars['Boolean']>;
  updateCustomTemplate?: Maybe<Scalars['Boolean']>;
  updateInstallationDevice: InstallationDeviceOutput;
  updateInstallationLoop: InstallationLoopOutput;
  updateMasterTemplate?: Maybe<Scalars['Boolean']>;
  updateNode?: Maybe<NodeWithDevice>;
  updateNotificationSettings?: Maybe<Scalars['Boolean']>;
  updateSiteWithBusinessUnit?: Maybe<Scalars['Boolean']>;
  updateSystem?: Maybe<System>;
};


export type MutationAddUserArgs = {
  user?: InputMaybe<UserProfileInput>;
};


export type MutationApplySiteNodeConfigurationsArgs = {
  nodeId: Scalars['ID'];
  siteId: Scalars['ID'];
};


export type MutationArchiveMasterTemplateArgs = {
  archiveReason: Scalars['String'];
  templateId: Scalars['String'];
};


export type MutationArchiveSystemArgs = {
  systemId: Scalars['String'];
};


export type MutationAssignMasterTemplateToTenantsArgs = {
  templateId: Scalars['String'];
  tenantIds: Array<Scalars['String']>;
};


export type MutationAssignSystemToNodeArgs = {
  nodeId: Scalars['String'];
  systemId: Scalars['String'];
};


export type MutationAssignUsersArgs = {
  siteUsers: AssignUsersInput;
};


export type MutationCompleteInspectionArgs = {
  inspection?: InputMaybe<CompleteInspectionInput>;
};


export type MutationCreateInspectionTemplateArgs = {
  template: InspectionTemplateInput;
};


export type MutationCreateInstallationDeviceArgs = {
  loopId: Scalars['String'];
  params: CreateInstallationDeviceInput;
};


export type MutationCreateInstallationLoopArgs = {
  loopName: Scalars['String'];
  systemID: Scalars['String'];
};


export type MutationCreateMasterTemplateArgs = {
  template: InspectionTemplateInput;
};


export type MutationCreateNodeArgs = {
  input: CreateNodeInput;
};


export type MutationCreateSiteArgs = {
  site: SiteInput;
};


export type MutationCreateSystemArgs = {
  input: CreateSystemInput;
  siteId: Scalars['String'];
};


export type MutationCreateSystemConnectionArgs = {
  systemConnection: SystemConnectionInput;
};


export type MutationCreateTenantArgs = {
  tenant?: InputMaybe<TenantInput>;
};


export type MutationDeleteInspectionArgs = {
  inspectionId: Scalars['String'];
};


export type MutationDeleteInstallationDeviceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInstallationLoopArgs = {
  loopID: Scalars['String'];
};


export type MutationDeleteSystemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSystemConnectionArgs = {
  nodeId: Scalars['String'];
  systemId: Scalars['String'];
};


export type MutationDismissNotificationArgs = {
  id: Scalars['String'];
};


export type MutationPopulateDbWithInstallationFileContentArgs = {
  installationFileContent: Scalars['String'];
  systemId: Scalars['String'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['String'];
};


export type MutationRegisterDeviceArgs = {
  deviceDetails: DeviceRegistration;
};


export type MutationScheduleInspectionArgs = {
  inspection: ScheduleInspectionInput;
};


export type MutationShareInspectionArgs = {
  inspectionId: Scalars['String'];
  toEmailAddress: Array<Scalars['String']>;
};


export type MutationStartInspectionArgs = {
  inspectionId: Scalars['String'];
};


export type MutationUnRegisterDeviceArgs = {
  token: Scalars['String'];
};


export type MutationUnassignMasterTemplatesArgs = {
  templateIds: Array<Scalars['String']>;
  tenantId: Scalars['String'];
};


export type MutationUnassignSystemFromNodeArgs = {
  nodeId: Scalars['String'];
  systemId: Scalars['String'];
};


export type MutationUnassignUsersArgs = {
  siteUsers: UnassignUserInput;
};


export type MutationUpdateCustomTemplateArgs = {
  template: InspectionTemplateInput;
  templateId: Scalars['String'];
};


export type MutationUpdateInstallationDeviceArgs = {
  params: UpdateInstallationDeviceInput;
};


export type MutationUpdateInstallationLoopArgs = {
  params: UpdateInstallationLoopInput;
};


export type MutationUpdateMasterTemplateArgs = {
  template: InspectionTemplateInput;
  templateId: Scalars['String'];
};


export type MutationUpdateNodeArgs = {
  input: UpdateNodeInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateNotificationSettingsInput;
};


export type MutationUpdateSiteWithBusinessUnitArgs = {
  businessUnit: BusinessUnit;
  siteId: Scalars['String'];
};


export type MutationUpdateSystemArgs = {
  input: UpdateSystemInput;
  siteId: Scalars['String'];
};

export type Node = {
  __typename?: 'Node';
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  externalNetworkStatus: ExternalNetworkStatus;
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  isConfigChanged: Scalars['Boolean'];
  lastHeartbeatAt: Scalars['String'];
  name: Scalars['String'];
  nodeDeviceId: Scalars['String'];
  subnetMask: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<User>;
};

export type NodeDevice = {
  __typename?: 'NodeDevice';
  createdAt: Scalars['String'];
  iccid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imei?: Maybe<Scalars['String']>;
  imsi?: Maybe<Scalars['String']>;
  manufacturedForBU: BusinessUnit;
  serialNumber: Scalars['String'];
  status: NodeDeviceStatus;
  type: NodeDeviceType;
};

export enum NodeDeviceStatus {
  Installation = 'Installation',
  Manufactured = 'Manufactured',
  Operation = 'Operation',
  Registered = 'Registered'
}

export enum NodeDeviceType {
  Edge = 'Edge',
  Gateway = 'Gateway'
}

export type NodeFilter = {
  buSystemId?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isConfigUploaded?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NodeState>;
  type?: InputMaybe<Scalars['String']>;
};

export enum NodeState {
  HasIncorrectConfiguration = 'HasIncorrectConfiguration',
  NeverConnected = 'NeverConnected',
  Offline = 'Offline',
  Online = 'Online'
}

export type NodeWithDevice = {
  __typename?: 'NodeWithDevice';
  node: Node;
  nodeDevice: NodeDevice;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  areNotificationsEnabled: Scalars['Boolean'];
  businessUnit: Scalars['String'];
  id: Scalars['String'];
  notificationTypeSettings?: Maybe<Array<NotificationTypeSetting>>;
};

export type NotificationSettingInput = {
  areNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  notificationTypeSettings?: InputMaybe<NotificationTypeSettingInput>;
};

export type NotificationTypeSetting = {
  __typename?: 'NotificationTypeSetting';
  id: Scalars['String'];
  isEmailEnabled: Scalars['Boolean'];
  isPushEnabled: Scalars['Boolean'];
  type: Scalars['String'];
};

export type NotificationTypeSettingInput = {
  id?: InputMaybe<Scalars['String']>;
  isEmailEnabled?: InputMaybe<Scalars['Boolean']>;
  isPushEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  currentPage: Scalars['Int'];
  filteredRecords?: Maybe<Scalars['Int']>;
  nextPage?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type PaginationParameters = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PressureTrend = {
  __typename?: 'PressureTrend';
  arrow: PressureTrendArrow;
  motor: Scalars['Int'];
  pressure: Scalars['Int'];
};

export enum PressureTrendArrow {
  DOWNWARDS = 'DOWNWARDS',
  HORIZONTAL = 'HORIZONTAL',
  UPWARDS = 'UPWARDS'
}

export type PropertiesMap = {
  __typename?: 'PropertiesMap';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PumpConditionMonitoring = {
  __typename?: 'PumpConditionMonitoring';
  averageTargetPressure: Scalars['Float'];
  numberOfPumps: Scalars['Int'];
  pressureTrend?: Maybe<Array<PressureTrend>>;
  pumpMonitoring?: Maybe<Array<PumpMonitoring>>;
  thresholdPressure: Scalars['Float'];
};

export type PumpMonitoring = {
  __typename?: 'PumpMonitoring';
  data: Array<PumpMonitoringData>;
  date: Scalars['String'];
};

export type PumpMonitoringData = {
  __typename?: 'PumpMonitoringData';
  color: Scalars['String'];
  motor: Scalars['Int'];
  pressure: Scalars['Int'];
};

export enum PumpingStatus {
  Critical = 'Critical',
  Good = 'Good',
  Warning = 'Warning'
}

export type PushNotification = {
  __typename?: 'PushNotification';
  businessUnit: Scalars['String'];
  clickAction?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  deepLink?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  propertiesMap: Array<Maybe<PropertiesMap>>;
  resourceId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  currentUser: CurrentUser;
  devicesBySiteId: DevicesSummary;
  downloadMasterTemplate?: Maybe<Scalars['String']>;
  downloadReport?: Maybe<Scalars['String']>;
  downloadSystemConfiguration: Scalars['String'];
  eventDetails?: Maybe<Events>;
  eventsBySiteId?: Maybe<Array<Maybe<Events>>>;
  eventsHistoryData?: Maybe<EventHistoryResult>;
  getCustomTemplateDetails?: Maybe<TemplateDetails>;
  getDeviceByDeviceId?: Maybe<Device>;
  getInspectionById?: Maybe<Inspection>;
  getLocationsBySiteId: Array<Maybe<Location>>;
  getMasterTemplateDetails?: Maybe<TemplateDetails>;
  getSignedUrl?: Maybe<Scalars['String']>;
  getSignedUrlForTemplateFile?: Maybe<Scalars['String']>;
  getSignedUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSystemsBySiteId: Array<Maybe<System>>;
  getTemplateAssignedTenantsList?: Maybe<AssignedTenantsListOutput>;
  getTemplatesCount?: Maybe<TemplatesCount>;
  getTenantBusinessUnit?: Maybe<BusinessUnit>;
  getUserByOktaId?: Maybe<UserInfoWithRole>;
  getUsers?: Maybe<UsersList>;
  inspectionDetails?: Maybe<Inspection>;
  inspectionMasterTemplates?: Maybe<InspectionMasterTemplatesListOutput>;
  inspectionTemplates?: Maybe<TemplatesListOutput>;
  inspections?: Maybe<InspectionListOutput>;
  inspectionsByDate?: Maybe<InspectionListOutput>;
  installationDevice?: Maybe<InstallationDevice>;
  installationDevices?: Maybe<InstallationDeviceListOutput>;
  installationDownloadCount?: Maybe<Scalars['Int']>;
  installationLoop?: Maybe<InstallationLoop>;
  installationLoopAsCsv?: Maybe<Scalars['String']>;
  installationLoops?: Maybe<Array<Maybe<InstallationLoop>>>;
  installationTagNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  nodeWithSiteIdNodeId: NodeWithDevice;
  pumpConditionMonitoring?: Maybe<PumpConditionMonitoring>;
  pushNotifications: Array<Maybe<PushNotification>>;
  requestLatestSystemConfiguration?: Maybe<Scalars['Boolean']>;
  siteDashboardDetails?: Maybe<SiteDashboardDetails>;
  siteDetails?: Maybe<Site>;
  siteNodes: SiteNodesResult;
  siteSystemAnalytics?: Maybe<SiteSystemAnalytics>;
  sites?: Maybe<SiteListOutput>;
  systemConfigsWithSystemId: Array<Maybe<SystemConfig>>;
  systemWithSystemId: System;
  systemsWithNoConnection: Array<Maybe<System>>;
  systemsWithSiteIdNodeId: Array<Maybe<System>>;
  templateUnAssignedTenantsList?: Maybe<UnAssignedTenantsOutput>;
  tenantDetails?: Maybe<Tenant>;
  tenantSitesStatuses?: Maybe<TenantSitesStatuses>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};


export type QueryDevicesBySiteIdArgs = {
  businessUnit?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DeviceFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteId: Scalars['String'];
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  systemId?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadMasterTemplateArgs = {
  templateId: Scalars['String'];
};


export type QueryDownloadReportArgs = {
  inspectionId: Scalars['String'];
  templateName: Scalars['String'];
};


export type QueryDownloadSystemConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryEventDetailsArgs = {
  id: Scalars['String'];
};


export type QueryEventsBySiteIdArgs = {
  eventType: EventType;
  showActive?: InputMaybe<Scalars['Boolean']>;
  siteId: Scalars['String'];
};


export type QueryEventsHistoryDataArgs = {
  endTime?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<EventType>;
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortEnum>;
  startTime?: InputMaybe<Scalars['String']>;
};


export type QueryGetCustomTemplateDetailsArgs = {
  templateId: Scalars['String'];
};


export type QueryGetDeviceByDeviceIdArgs = {
  deviceId: Scalars['String'];
};


export type QueryGetInspectionByIdArgs = {
  inspectionId: Scalars['String'];
};


export type QueryGetLocationsBySiteIdArgs = {
  siteId: Scalars['String'];
};


export type QueryGetMasterTemplateDetailsArgs = {
  templateId: Scalars['String'];
};


export type QueryGetSignedUrlArgs = {
  input: SignedUrlInput;
};


export type QueryGetSignedUrlForTemplateFileArgs = {
  templateFileName: Scalars['String'];
};


export type QueryGetSignedUrlsArgs = {
  input: Array<SignedUrlInput>;
};


export type QueryGetSystemsBySiteIdArgs = {
  filter?: InputMaybe<SystemsFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteId: Scalars['String'];
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryGetTemplateAssignedTenantsListArgs = {
  queryParams?: InputMaybe<QueryParameters>;
};


export type QueryGetUserByOktaIdArgs = {
  oktaId: Scalars['String'];
};


export type QueryGetUsersArgs = {
  pagination?: InputMaybe<UserPagination>;
  sorting?: InputMaybe<UsersSorting>;
  tenantId?: InputMaybe<Scalars['String']>;
};


export type QueryInspectionDetailsArgs = {
  inspectionId: Scalars['String'];
};


export type QueryInspectionMasterTemplatesArgs = {
  queryParams?: InputMaybe<QueryParameters>;
  status?: InputMaybe<InspectionTemplateStatus>;
  tenantId?: InputMaybe<Scalars['String']>;
};


export type QueryInspectionTemplatesArgs = {
  queryParams?: InputMaybe<QueryParameters>;
  status?: InputMaybe<InspectionTemplateStatus>;
  type?: InputMaybe<InspectionType>;
};


export type QueryInspectionsArgs = {
  input?: InputMaybe<GetInspectionsInput>;
};


export type QueryInspectionsByDateArgs = {
  input: GetInspectionsByDateInput;
};


export type QueryInstallationDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryInstallationDevicesArgs = {
  loopId: Scalars['String'];
  queryParams?: InputMaybe<QueryParameters>;
};


export type QueryInstallationDownloadCountArgs = {
  loopId: Scalars['String'];
};


export type QueryInstallationLoopArgs = {
  loopID: Scalars['String'];
};


export type QueryInstallationLoopAsCsvArgs = {
  loopId: Scalars['String'];
};


export type QueryInstallationLoopsArgs = {
  systemID: Scalars['String'];
};


export type QueryInstallationTagNamesArgs = {
  systemId: Scalars['String'];
};


export type QueryNodeWithSiteIdNodeIdArgs = {
  nodeId: Scalars['ID'];
  siteId: Scalars['ID'];
};


export type QueryPumpConditionMonitoringArgs = {
  input: GetPumpConditionMonitoringInput;
};


export type QueryRequestLatestSystemConfigurationArgs = {
  systemId: Scalars['String'];
};


export type QuerySiteDashboardDetailsArgs = {
  params: SiteDashboardInput;
};


export type QuerySiteDetailsArgs = {
  siteId: Scalars['String'];
};


export type QuerySiteNodesArgs = {
  filter?: InputMaybe<NodeFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteId: Scalars['ID'];
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QuerySiteSystemAnalyticsArgs = {
  input: GetSiteSystemAnalyticsInput;
};


export type QuerySitesArgs = {
  businessUnits?: InputMaybe<Array<BusinessUnit>>;
  queryParams?: InputMaybe<QueryParameters>;
  tenantId?: InputMaybe<Scalars['String']>;
};


export type QuerySystemConfigsWithSystemIdArgs = {
  systemId: Scalars['String'];
};


export type QuerySystemWithSystemIdArgs = {
  systemId: Scalars['String'];
};


export type QuerySystemsWithNoConnectionArgs = {
  siteId: Scalars['String'];
};


export type QuerySystemsWithSiteIdNodeIdArgs = {
  nodeId: Scalars['String'];
  siteId: Scalars['String'];
};


export type QueryTemplateUnAssignedTenantsListArgs = {
  queryParams?: InputMaybe<QueryParameters>;
  templateId: Scalars['ID'];
};


export type QueryTenantDetailsArgs = {
  id: Scalars['String'];
};


export type QueryTenantSitesStatusesArgs = {
  queryParams?: InputMaybe<QueryParameters>;
  tenantId: Scalars['ID'];
};

export type QueryParameters = {
  filteringParams?: InputMaybe<FilteringParameters>;
  paginationParams: PaginationParameters;
  sortingParams: SortingParameters;
};

export type QueryStatistics = {
  __typename?: 'QueryStatistics';
  pagination?: Maybe<PaginationOutput>;
};

export type ScheduleInspectionInput = {
  commentText?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  inspectionTemplateId: Scalars['String'];
  inspectionTemplateName: Scalars['String'];
  scheduledAt: Scalars['Float'];
  siteId: Scalars['String'];
  type: InspectionType;
};

export type SignedUrlInput = {
  contentType: Scalars['String'];
  httpVerb: HttpVerbs;
  objectKey: Scalars['String'];
};

export type Site = {
  __typename?: 'Site';
  address1: Scalars['String'];
  address2: Scalars['String'];
  assignableUsers?: Maybe<Array<Maybe<AssignableUser>>>;
  assignedUsers?: Maybe<Array<Maybe<AssignedUser>>>;
  buSiteIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  businessUnit?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  contactPerson?: Maybe<ContactPerson>;
  country: Scalars['String'];
  createdAt: Scalars['Float'];
  createdInspectionsCount: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  status?: Maybe<SiteStatus>;
  statusUpdatedAt?: Maybe<Scalars['Float']>;
  tenant?: Maybe<Tenant>;
  tenantId: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  userSiteId: Scalars['String'];
};

export type SiteDashboardDetails = {
  __typename?: 'SiteDashboardDetails';
  alarmsByDate?: Maybe<Array<Maybe<ActiveAlarmDetails>>>;
  alarmsHeatMap?: Maybe<Array<Maybe<AlarmGraphDetails>>>;
  connectivityStatus?: Maybe<Array<Maybe<ConnectivityStatusDetails>>>;
  eventStatus?: Maybe<Array<Maybe<EventStatus>>>;
  gateways?: Maybe<GatewayDetails>;
  units?: Maybe<UnitsDetails>;
};

export type SiteDashboardInput = {
  businessUnit?: InputMaybe<Scalars['String']>;
  devicesLimit?: InputMaybe<Scalars['Int']>;
  devicesOffset?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Float']>;
  fetchHeatMap?: InputMaybe<Scalars['Boolean']>;
  fetchStackMap?: InputMaybe<Scalars['Boolean']>;
  siteId: Scalars['String'];
  startDate?: InputMaybe<Scalars['Float']>;
};

export type SiteFilterInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type SiteInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  contactPerson?: InputMaybe<ContactPersonInput>;
  country: Scalars['String'];
  name: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['String']>;
  userSiteId: Scalars['String'];
};

export type SiteIssuesStatistics = {
  __typename?: 'SiteIssuesStatistics';
  activeAlarmsCount: Scalars['Int'];
  deviceIssuesCount: Scalars['Int'];
  disabledDetectionZoneCount: Scalars['Int'];
  disabledDevicesCount: Scalars['Int'];
  disabledLoopsCount: Scalars['Int'];
  offlineNodesCount: Scalars['Int'];
  pumpUnitIssuesCount: Scalars['Int'];
  systemConnectionIssuesCount: Scalars['Int'];
  totalLoopsCount: Scalars['Int'];
  totalNodeDevicesCount: Scalars['Int'];
  totalPanelsCount: Scalars['Int'];
  totalPumpUnitsCount: Scalars['Int'];
};

export type SiteListOutput = {
  __typename?: 'SiteListOutput';
  queryStats?: Maybe<QueryStatistics>;
  sites: Array<Maybe<Site>>;
};

export type SiteNodesResult = {
  __typename?: 'SiteNodesResult';
  nodesWithDevices?: Maybe<Array<Maybe<NodeWithDevice>>>;
  site: Site;
};

export type SiteStatistics = {
  __typename?: 'SiteStatistics';
  inTestSitesCount: Scalars['Int'];
  needAttentionSitesCount: Scalars['Int'];
  normalSitesCount: Scalars['Int'];
  sitesCount: Scalars['Int'];
};

export enum SiteStatus {
  InTest = 'InTest',
  Maintenance = 'Maintenance',
  NeedAttention = 'NeedAttention',
  Normal = 'Normal'
}

export type SiteStatusDetail = {
  __typename?: 'SiteStatusDetail';
  contactPerson?: Maybe<ContactPerson>;
  id: Scalars['ID'];
  name: Scalars['String'];
  statistics?: Maybe<SiteIssuesStatistics>;
  status: Scalars['String'];
  statusUpdatedAt: Scalars['String'];
};

export enum SiteStatuses {
  InTest = 'InTest',
  NeedAttention = 'NeedAttention',
  Normal = 'Normal'
}

export type SiteStatusesOutput = {
  __typename?: 'SiteStatusesOutput';
  queryStats?: Maybe<QueryStatistics>;
  siteStatus?: Maybe<Array<SiteStatusDetail>>;
};

export type SiteSystemAnalytics = {
  __typename?: 'SiteSystemAnalytics';
  falseActivation?: Maybe<Array<Maybe<FalseActivation>>>;
};

export enum SortBy {
  createdAt = 'createdAt',
  name = 'name'
}

export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SortingParameters = {
  sortByColumn: Scalars['String'];
  sortOrder: SortOrder;
};

export type Stages = {
  __typename?: 'Stages';
  liveUpdates: Scalars['String'];
  mobileConnection: Scalars['String'];
  networkConfig: Scalars['String'];
  panelConfig: Scalars['String'];
};

export type State = {
  __typename?: 'State';
  description: Scalars['String'];
  stages: Stages;
};

export type System = {
  __typename?: 'System';
  buSystemId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  deviceConfigStatus: ConfigStatus;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isConfigUploaded: Scalars['Boolean'];
  liveDataStatus: LiveDataStatus;
  location?: Maybe<Location>;
  name: Scalars['String'];
  networkConfigStatus: ConfigStatus;
  node?: Maybe<Node>;
  port?: Maybe<Scalars['String']>;
  state?: Maybe<SystemState>;
  status?: Maybe<GeneralSystemStatus>;
  systemConnection?: Maybe<SystemConnection>;
  type: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  userPassword?: Maybe<Scalars['String']>;
};

export type SystemConfig = {
  __typename?: 'SystemConfig';
  checksum: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  filePath: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  system?: Maybe<System>;
  user?: Maybe<User>;
};

export type SystemConnection = {
  __typename?: 'SystemConnection';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  ipAddress: Scalars['String'];
  node?: Maybe<Node>;
  port?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  system?: Maybe<System>;
  userName?: Maybe<Scalars['String']>;
  userPassword?: Maybe<Scalars['String']>;
};

export type SystemConnectionInput = {
  ipAddress: Scalars['String'];
  nodeId: Scalars['String'];
  port?: InputMaybe<Scalars['String']>;
  systemId: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
  userPassword?: InputMaybe<Scalars['String']>;
};

export enum SystemState {
  NeverConnected = 'NeverConnected',
  Offline = 'Offline',
  Online = 'Online'
}

export enum SystemType {
  AUTROPRIME = 'AUTROPRIME',
  AUTROSAFE = 'AUTROSAFE',
  EPU = 'EPU',
  LPU = 'LPU',
  WQ = 'WQ'
}

export enum SystemTypes {
  AUTROPRIME = 'AUTROPRIME',
  AUTROSAFE = 'AUTROSAFE',
  EPU = 'EPU',
  LPU = 'LPU',
  WQ = 'WQ'
}

export type SystemsFilter = {
  buSystemId?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type TemplateAssignedTenant = {
  __typename?: 'TemplateAssignedTenant';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TemplateDetails = {
  __typename?: 'TemplateDetails';
  checklists: Array<InspectionCheckList>;
  createdAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InspectionTemplateStatus>;
  type?: Maybe<InspectionType>;
};

export type TemplatesCount = {
  __typename?: 'TemplatesCount';
  assignedTemplatesCount?: Maybe<Scalars['Float']>;
  totalTemplatesCount?: Maybe<Scalars['Float']>;
};

export type TemplatesListOutput = {
  __typename?: 'TemplatesListOutput';
  queryStats?: Maybe<QueryStatistics>;
  templates: Array<Maybe<InspectionTemplateItem>>;
};

export type Tenant = {
  __typename?: 'Tenant';
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  contactPerson?: Maybe<ContactPerson>;
  country: Scalars['String'];
  createdAt: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TenantInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  contactPerson?: InputMaybe<ContactPersonInput>;
  country: Scalars['String'];
  name: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type TenantSitesStatuses = {
  __typename?: 'TenantSitesStatuses';
  siteStatistics?: Maybe<SiteStatistics>;
  sitesStatuses?: Maybe<SiteStatusesOutput>;
  tenant?: Maybe<Tenant>;
};

export type UnAssignedTenantsOutput = {
  __typename?: 'UnAssignedTenantsOutput';
  queryStats?: Maybe<QueryStatistics>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};

export type UnassignUserInput = {
  siteId: Scalars['String'];
  userId: Scalars['String'];
};

export type UnitsDetails = {
  __typename?: 'UnitsDetails';
  activeAlarms?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type UpdateInstallationDeviceInput = {
  detectionZone?: InputMaybe<Scalars['String']>;
  flash?: InputMaybe<Scalars['Boolean']>;
  heat?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  serialNumber?: InputMaybe<Scalars['String']>;
  sounder?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<InstallationDeviceStatus>;
  tag?: InputMaybe<Scalars['String']>;
};

export type UpdateInstallationLoopInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  status: InstallationLoopStatus;
};

export type UpdateNodeInput = {
  id: Scalars['ID'];
  ipAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subnetMask?: InputMaybe<Scalars['String']>;
};

export type UpdateNotificationSettingsInput = {
  areAllNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationSettings?: InputMaybe<NotificationSettingInput>;
};

export type UpdateSystemInput = {
  id: Scalars['String'];
  ipAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  port?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  areAllNotificationsEnabled: Scalars['Boolean'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invitedAt: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  notificationSettings?: Maybe<Array<NotificationSetting>>;
  oktaId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UserInfoWithRole = {
  __typename?: 'UserInfoWithRole';
  role: UserRoles;
  user: User;
};

export type UserPagination = {
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

export type UserProfileInput = {
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  tenantId?: InputMaybe<Scalars['String']>;
  tenantName?: InputMaybe<Scalars['String']>;
};

export enum UserRoles {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SITE_SUPERVISOR = 'SITE_SUPERVISOR',
  TECHNICIAN = 'TECHNICIAN'
}

export enum UserSortingAttributes {
  email = 'email',
  phone = 'phone',
  roleId = 'roleId',
  status = 'status'
}

export enum UserSortingDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
  LOCKED_OUT = 'LOCKED_OUT',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PROVISIONED = 'PROVISIONED',
  STAGED = 'STAGED',
  SUSPENDED = 'SUSPENDED'
}

export type UsersList = {
  __typename?: 'UsersList';
  currentPage: Scalars['Int'];
  numberOfRecords: Scalars['Int'];
  totalPages: Scalars['Int'];
  totalRecords: Scalars['Int'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersSorting = {
  sortBy: UserSortingAttributes;
  sortDirection?: InputMaybe<UserSortingDirection>;
};

export type AssignableUser = {
  __typename?: 'assignableUser';
  assignedSites?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oktaId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type AssignedUser = {
  __typename?: 'assignedUser';
  assignedDate: Scalars['Float'];
  email: Scalars['String'];
  name: Scalars['String'];
  oktaId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  roleId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type DismissAllNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type DismissAllNotificationsMutation = { __typename?: 'Mutation', dismissAllNotifications?: boolean | null };

export type DismissNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DismissNotificationMutation = { __typename?: 'Mutation', dismissNotification?: boolean | null };

export type PushNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type PushNotificationsQuery = { __typename?: 'Query', pushNotifications: Array<{ __typename?: 'PushNotification', id: string, title: string, status: string, type: string, businessUnit: string, clickAction?: string | null, resourceId?: string | null, deepLink?: string | null, createdAt: string, description: string, propertiesMap: Array<{ __typename?: 'PropertiesMap', key: string, value?: string | null } | null> } | null> };

export type ReadAllNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type ReadAllNotificationsMutation = { __typename?: 'Mutation', readAllNotifications?: boolean | null };

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReadNotificationMutation = { __typename?: 'Mutation', readNotification?: boolean | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', permissions: string, user?: { __typename?: 'User', id?: string | null, oktaId: string, emailAddress?: string | null, firstName: string, lastName?: string | null, phoneNumber?: string | null, preferredLanguage?: string | null, status?: UserStatus | null, updatedBy?: string | null, updatedAt?: string | null, createdBy?: string | null, createdAt: string, invitedAt: string, areAllNotificationsEnabled: boolean, role?: string | null, tenant?: { __typename?: 'Tenant', id: string, name: string, address1: string, address2: string, postalCode?: string | null, country: string, city: string, createdAt: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null } | null, notificationSettings?: Array<{ __typename?: 'NotificationSetting', id: string, businessUnit: string, areNotificationsEnabled: boolean, notificationTypeSettings?: Array<{ __typename?: 'NotificationTypeSetting', id: string, type: string, isPushEnabled: boolean, isEmailEnabled: boolean }> | null }> | null } | null } };

export type DeviceRegistrationMutationVariables = Exact<{
  deviceDetails: DeviceRegistration;
}>;


export type DeviceRegistrationMutation = { __typename?: 'Mutation', registerDevice: boolean };

export type UnRegisterDeviceMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type UnRegisterDeviceMutation = { __typename?: 'Mutation', unRegisterDevice: boolean };

export type GetTemplatesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTemplatesCountQuery = { __typename?: 'Query', getTemplatesCount?: { __typename?: 'TemplatesCount', totalTemplatesCount?: number | null, assignedTemplatesCount?: number | null } | null };

export type GetTemplateAssignedTenantsListQueryVariables = Exact<{
  queryParams?: InputMaybe<QueryParameters>;
}>;


export type GetTemplateAssignedTenantsListQuery = { __typename?: 'Query', getTemplateAssignedTenantsList?: { __typename?: 'AssignedTenantsListOutput', assignedTenantsList: Array<{ __typename?: 'AssignedTenantItem', count: number, tenant: { __typename?: 'Tenant', id: string, name: string } } | null>, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number, currentPage: number, totalPages: number, nextPage?: number | null, prevPage?: number | null } | null } | null } | null };

export type UnassignMasterTemplatesMutationVariables = Exact<{
  templateIds: Array<Scalars['String']> | Scalars['String'];
  tenantId: Scalars['String'];
}>;


export type UnassignMasterTemplatesMutation = { __typename?: 'Mutation', unassignMasterTemplates?: boolean | null };

export type GetInspectionMasterTemplatesQueryVariables = Exact<{
  queryParams?: InputMaybe<QueryParameters>;
  tenantId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<InspectionTemplateStatus>;
}>;


export type GetInspectionMasterTemplatesQuery = { __typename?: 'Query', inspectionMasterTemplates?: { __typename?: 'InspectionMasterTemplatesListOutput', inspectionMasterTemplates: Array<{ __typename?: 'InspectionMasterTemplateItem', id?: string | null, name?: string | null, type?: InspectionType | null, status?: InspectionTemplateStatus | null, createdAt?: number | null, archivedAt?: number | null, createdBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, role?: string | null } | null, archivedBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, role?: string | null } | null, assignedTenants?: Array<{ __typename?: 'TemplateAssignedTenant', id?: string | null, name?: string | null } | null> | null } | null>, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number, currentPage: number, totalPages: number, nextPage?: number | null, prevPage?: number | null, filteredRecords?: number | null } | null } | null } | null };

export type ArchiveMasterTemplateMutationVariables = Exact<{
  templateId: Scalars['String'];
  archiveReason: Scalars['String'];
}>;


export type ArchiveMasterTemplateMutation = { __typename?: 'Mutation', archiveMasterTemplate?: boolean | null };

export type AssignMasterTemplateToTenantsMutationVariables = Exact<{
  templateId: Scalars['String'];
  tenantIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignMasterTemplateToTenantsMutation = { __typename?: 'Mutation', assignMasterTemplateToTenants?: boolean | null };

export type GetMasterTemplateBasicDetailsQueryVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type GetMasterTemplateBasicDetailsQuery = { __typename?: 'Query', getMasterTemplateDetails?: { __typename?: 'TemplateDetails', id?: string | null, name?: string | null, type?: InspectionType | null } | null };

export type GetTemplateUnAssignedTenantsListQueryVariables = Exact<{
  templateId: Scalars['ID'];
  queryParams?: InputMaybe<QueryParameters>;
}>;


export type GetTemplateUnAssignedTenantsListQuery = { __typename?: 'Query', templateUnAssignedTenantsList?: { __typename?: 'UnAssignedTenantsOutput', tenants?: Array<{ __typename?: 'Tenant', id: string, name: string, address1: string, address2: string, postalCode?: string | null, country: string, city: string, createdAt: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null } | null> | null, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number, currentPage: number, totalPages: number, nextPage?: number | null, prevPage?: number | null } | null } | null } | null };

export type CreateMasterTemplateMutationVariables = Exact<{
  template: InspectionTemplateInput;
}>;


export type CreateMasterTemplateMutation = { __typename?: 'Mutation', createMasterTemplate?: boolean | null };

export type UpdateMasterTemplateMutationVariables = Exact<{
  templateId: Scalars['String'];
  template: InspectionTemplateInput;
}>;


export type UpdateMasterTemplateMutation = { __typename?: 'Mutation', updateMasterTemplate?: boolean | null };

export type GetCustomTemplateDetailsQueryVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type GetCustomTemplateDetailsQuery = { __typename?: 'Query', getCustomTemplateDetails?: { __typename?: 'TemplateDetails', id?: string | null, name?: string | null, type?: InspectionType | null, status?: InspectionTemplateStatus | null, createdAt?: number | null, checklists: Array<{ __typename?: 'InspectionCheckList', id: string, name: string, title: string, isChecked?: boolean | null, steps: Array<{ __typename?: 'InspectionCheckListStep', id: string, title: string, name: string, mediaFilePath?: string | null, description?: string | null }> }> } | null };

export type GetMasterTemplateDetailsQueryVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type GetMasterTemplateDetailsQuery = { __typename?: 'Query', getMasterTemplateDetails?: { __typename?: 'TemplateDetails', id?: string | null, name?: string | null, type?: InspectionType | null, status?: InspectionTemplateStatus | null, createdAt?: number | null, checklists: Array<{ __typename?: 'InspectionCheckList', id: string, name: string, title: string, isChecked?: boolean | null, steps: Array<{ __typename?: 'InspectionCheckListStep', id: string, title: string, name: string, mediaFilePath?: string | null, description?: string | null }> }> } | null };

export type GetBusinessUnitFromTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessUnitFromTenantQuery = { __typename?: 'Query', getTenantBusinessUnit?: BusinessUnit | null };

export type GetDashboardTenantSitesQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  queryParams?: InputMaybe<QueryParameters>;
}>;


export type GetDashboardTenantSitesQuery = { __typename?: 'Query', tenantSitesStatuses?: { __typename?: 'TenantSitesStatuses', tenant?: { __typename?: 'Tenant', id: string, name: string, address1: string, address2: string, postalCode?: string | null, country: string, city: string, createdAt: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null } | null, siteStatistics?: { __typename?: 'SiteStatistics', sitesCount: number, normalSitesCount: number, needAttentionSitesCount: number, inTestSitesCount: number } | null, sitesStatuses?: { __typename?: 'SiteStatusesOutput', siteStatus?: Array<{ __typename?: 'SiteStatusDetail', id: string, name: string, status: string, statusUpdatedAt: string, statistics?: { __typename?: 'SiteIssuesStatistics', offlineNodesCount: number, systemConnectionIssuesCount: number, activeAlarmsCount: number, pumpUnitIssuesCount: number, deviceIssuesCount: number, disabledDevicesCount: number, disabledLoopsCount: number, disabledDetectionZoneCount: number, totalLoopsCount: number, totalPumpUnitsCount: number, totalNodeDevicesCount: number, totalPanelsCount: number } | null, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null }> | null, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number, currentPage: number, totalPages: number, nextPage?: number | null, prevPage?: number | null } | null } | null } | null } | null };

export type DeleteInspectionMutationVariables = Exact<{
  inspectionId: Scalars['String'];
}>;


export type DeleteInspectionMutation = { __typename?: 'Mutation', deleteInspection?: boolean | null };

export type GetInspectionsByDateQueryVariables = Exact<{
  input: GetInspectionsByDateInput;
}>;


export type GetInspectionsByDateQuery = { __typename?: 'Query', inspectionsByDate?: { __typename?: 'InspectionListOutput', inspections: Array<{ __typename?: 'Inspection', createdAt: number, version?: string | null, completedAt?: number | null, name: string, type?: InspectionType | null, scheduledAt: number, status?: InspectionStatus | null, id: string, createdBy?: { __typename?: 'User', firstName: string, lastName?: string | null } | null, startedBy?: { __typename?: 'User', firstName: string, lastName?: string | null } | null, site?: { __typename?: 'Site', name: string, tenantId: string } | null, device?: { __typename?: 'Device', name: string } | null, template?: { __typename?: 'InspectionTemplateItem', id?: string | null, name?: string | null } | null } | null>, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number } | null } | null } | null };

export type ScheduleInspectionMutationVariables = Exact<{
  inspection: ScheduleInspectionInput;
}>;


export type ScheduleInspectionMutation = { __typename?: 'Mutation', scheduleInspection?: { __typename?: 'InspectionOutput', id?: string | null, type?: InspectionType | null } | null };

export type CreateInspectionTemplateMutationVariables = Exact<{
  template: InspectionTemplateInput;
}>;


export type CreateInspectionTemplateMutation = { __typename?: 'Mutation', createInspectionTemplate?: string | null };

export type GetInspectionTemplatesQueryVariables = Exact<{
  type?: InputMaybe<InspectionType>;
  queryParams?: InputMaybe<QueryParameters>;
}>;


export type GetInspectionTemplatesQuery = { __typename?: 'Query', inspectionTemplates?: { __typename?: 'TemplatesListOutput', templates: Array<{ __typename?: 'InspectionTemplateItem', id?: string | null, name?: string | null, createdAt?: number | null, type?: InspectionType | null, createdBy?: { __typename?: 'User', firstName: string, lastName?: string | null } | null } | null>, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number } | null } | null } | null };

export type UpdateCustomTemplateMutationVariables = Exact<{
  templateId: Scalars['String'];
  template: InspectionTemplateInput;
}>;


export type UpdateCustomTemplateMutation = { __typename?: 'Mutation', updateCustomTemplate?: boolean | null };

export type GetSignedUrlQueryVariables = Exact<{
  input: SignedUrlInput;
}>;


export type GetSignedUrlQuery = { __typename?: 'Query', getSignedUrl?: string | null };

export type DownloadMasterTemplateQueryVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type DownloadMasterTemplateQuery = { __typename?: 'Query', downloadMasterTemplate?: string | null };

export type DownloadReportQueryVariables = Exact<{
  inspectionId: Scalars['String'];
  templateName: Scalars['String'];
}>;


export type DownloadReportQuery = { __typename?: 'Query', downloadReport?: string | null };

export type GetInspectionDetailsQueryVariables = Exact<{
  inspectionId: Scalars['String'];
}>;


export type GetInspectionDetailsQuery = { __typename?: 'Query', inspectionDetails?: { __typename?: 'Inspection', createdAt: number, version?: string | null, completedAt?: number | null, name: string, type?: InspectionType | null, scheduledAt: number, status?: InspectionStatus | null, id: string, createdBy?: { __typename?: 'User', firstName: string, lastName?: string | null } | null, startedBy?: { __typename?: 'User', firstName: string, lastName?: string | null } | null, checklists: Array<{ __typename?: 'InspectionCheckList', name: string, id: string, isChecked?: boolean | null, title: string, steps: Array<{ __typename?: 'InspectionCheckListStep', name: string, id: string, description?: string | null, title: string }> }>, comments?: Array<{ __typename?: 'InspectionComment', createdAt: number, text: string, mediaFilePath?: string | null, createdBy?: { __typename?: 'User', firstName: string, lastName?: string | null, role?: string | null } | null } | null> | null, site?: { __typename?: 'Site', name: string, tenantId: string } | null, device?: { __typename?: 'Device', name: string } | null } | null };

export type GetInspectionsQueryVariables = Exact<{
  input: GetInspectionsInput;
}>;


export type GetInspectionsQuery = { __typename?: 'Query', inspections?: { __typename?: 'InspectionListOutput', inspections: Array<{ __typename?: 'Inspection', createdAt: number, version?: string | null, completedAt?: number | null, name: string, type?: InspectionType | null, scheduledAt: number, status?: InspectionStatus | null, id: string, createdBy?: { __typename?: 'User', firstName: string, lastName?: string | null } | null, startedBy?: { __typename?: 'User', firstName: string, lastName?: string | null } | null, site?: { __typename?: 'Site', name: string, tenantId: string } | null, device?: { __typename?: 'Device', name: string } | null, template?: { __typename?: 'InspectionTemplateItem', id?: string | null, name?: string | null } | null } | null>, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number } | null } | null } | null };

export type ShareInspectionMutationVariables = Exact<{
  inspectionId: Scalars['String'];
  toEmailAddress: Array<Scalars['String']> | Scalars['String'];
}>;


export type ShareInspectionMutation = { __typename?: 'Mutation', shareInspection?: boolean | null };

export type GetTenantBasicDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTenantBasicDetailsQuery = { __typename?: 'Query', tenantDetails?: { __typename?: 'Tenant', id: string, name: string } | null };

export type GetSiteStatusQueryVariables = Exact<{
  siteId: Scalars['String'];
}>;


export type GetSiteStatusQuery = { __typename?: 'Query', siteDetails?: { __typename?: 'Site', id: string, name: string, status?: SiteStatus | null, statusUpdatedAt?: number | null } | null };

export type CreateSiteMutationVariables = Exact<{
  site: SiteInput;
}>;


export type CreateSiteMutation = { __typename?: 'Mutation', createSite: { __typename?: 'Site', id: string, userSiteId: string, name: string, tenantId: string, address1: string, address2: string, country: string, city: string, state?: string | null, postalCode: string, createdAt: number, createdInspectionsCount: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null, tenant?: { __typename?: 'Tenant', id: string, name: string } | null } };

export type GetAllSitesQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>;
  queryParams?: InputMaybe<QueryParameters>;
  businessUnits?: InputMaybe<Array<BusinessUnit> | BusinessUnit>;
}>;


export type GetAllSitesQuery = { __typename?: 'Query', sites?: { __typename?: 'SiteListOutput', sites: Array<{ __typename?: 'Site', id: string, userSiteId: string, name: string, tenantId: string, address1: string, address2: string, country: string, city: string, postalCode: string, createdAt: number, businessUnit?: string | null, status?: SiteStatus | null, createdInspectionsCount: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null, assignedUsers?: Array<{ __typename?: 'assignedUser', email: string, name: string, phoneNumber?: string | null, roleId: string, oktaId: string, assignedDate: number, status?: string | null } | null> | null, assignableUsers?: Array<{ __typename?: 'assignableUser', assignedSites?: number | null, email?: string | null, name?: string | null, roleId?: string | null, status?: string | null, oktaId?: string | null } | null> | null, tenant?: { __typename?: 'Tenant', id: string, name: string, address1: string, address2: string, postalCode?: string | null, country: string, city: string, createdAt: number } | null } | null>, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number } | null } | null } | null };

export type GetNodeStatusQueryVariables = Exact<{
  siteId: Scalars['ID'];
  nodeId: Scalars['ID'];
}>;


export type GetNodeStatusQuery = { __typename?: 'Query', nodeWithSiteIdNodeId: { __typename?: 'NodeWithDevice', node: { __typename?: 'Node', id: string, name: string, externalNetworkStatus: ExternalNetworkStatus } } };

export type AssignUsersMutationVariables = Exact<{
  siteUsers: AssignUsersInput;
}>;


export type AssignUsersMutation = { __typename?: 'Mutation', assignUsers?: Array<string | null> | null };

export type GetSiteAssignableUsersQueryVariables = Exact<{
  siteDetailsId: Scalars['String'];
}>;


export type GetSiteAssignableUsersQuery = { __typename?: 'Query', siteDetails?: { __typename?: 'Site', id: string, name: string, userSiteId: string, tenantId: string, assignableUsers?: Array<{ __typename?: 'assignableUser', assignedSites?: number | null, email?: string | null, name?: string | null, roleId?: string | null, status?: string | null, oktaId?: string | null } | null> | null } | null };

export type GetSiteAssignedUsersQueryVariables = Exact<{
  siteDetailsId: Scalars['String'];
}>;


export type GetSiteAssignedUsersQuery = { __typename?: 'Query', siteDetails?: { __typename?: 'Site', id: string, name: string, userSiteId: string, tenantId: string, assignedUsers?: Array<{ __typename?: 'assignedUser', email: string, name: string, phoneNumber?: string | null, roleId: string, oktaId: string, assignedDate: number, status?: string | null } | null> | null } | null };

export type UnassignUsersMutationVariables = Exact<{
  siteUsers: UnassignUserInput;
}>;


export type UnassignUsersMutation = { __typename?: 'Mutation', unassignUsers?: boolean | null };

export type GetAlarmsHeatMapQueryVariables = Exact<{
  params: SiteDashboardInput;
}>;


export type GetAlarmsHeatMapQuery = { __typename?: 'Query', siteDashboardDetails?: { __typename?: 'SiteDashboardDetails', alarmsHeatMap?: Array<{ __typename?: 'AlarmGraphDetails', alarmsCount?: number | null, date?: string | null } | null> | null } | null };

export type ConnectivityStatusQueryVariables = Exact<{
  params: SiteDashboardInput;
}>;


export type ConnectivityStatusQuery = { __typename?: 'Query', siteDashboardDetails?: { __typename?: 'SiteDashboardDetails', connectivityStatus?: Array<{ __typename?: 'ConnectivityStatusDetails', componentName?: string | null, systemId?: string | null, connectivity?: boolean | null, componentState?: string | null, systemType?: string | null, loopUnits?: number | null } | null> | null } | null };

export type GetEventStatusQueryVariables = Exact<{
  params: SiteDashboardInput;
}>;


export type GetEventStatusQuery = { __typename?: 'Query', siteDashboardDetails?: { __typename?: 'SiteDashboardDetails', eventStatus?: Array<{ __typename?: 'EventStatus', type: string, value: number } | null> | null } | null };

export type GetAlarmsByDateQueryVariables = Exact<{
  params: SiteDashboardInput;
}>;


export type GetAlarmsByDateQuery = { __typename?: 'Query', siteDashboardDetails?: { __typename?: 'SiteDashboardDetails', alarmsByDate?: Array<{ __typename?: 'ActiveAlarmDetails', date?: string | null, events?: any | null } | null> | null } | null };

export type GetGatewaysDataQueryVariables = Exact<{
  params: SiteDashboardInput;
}>;


export type GetGatewaysDataQuery = { __typename?: 'Query', siteDashboardDetails?: { __typename?: 'SiteDashboardDetails', gateways?: { __typename?: 'GatewayDetails', total: string, connected: string, notConnected: string } | null } | null };

export type GetUnitsDataQueryVariables = Exact<{
  params: SiteDashboardInput;
}>;


export type GetUnitsDataQuery = { __typename?: 'Query', siteDashboardDetails?: { __typename?: 'SiteDashboardDetails', units?: { __typename?: 'UnitsDetails', total?: string | null, activeAlarms?: string | null } | null } | null };

export type GetDeviceElementsQueryVariables = Exact<{
  siteId: Scalars['String'];
  systemId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  businessUnit?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
}>;


export type GetDeviceElementsQuery = { __typename?: 'Query', devicesBySiteId: { __typename?: 'DevicesSummary', totalCount: number, devices: Array<{ __typename?: 'Device', id: string, name: string, type: string, parentDevice?: { __typename?: 'Device', id: string, name: string, type: string } | null }> } };

export type GetDevicesQueryVariables = Exact<{
  siteId: Scalars['String'];
  systemId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  businessUnit?: InputMaybe<Scalars['String']>;
}>;


export type GetDevicesQuery = { __typename?: 'Query', devicesBySiteId: { __typename?: 'DevicesSummary', totalCount: number, devices: Array<{ __typename?: 'Device', id: string, name: string, state: DeviceState, model: string, type: string, locationDetails?: string | null, deviceId: string, createdAt: string, serialNumber: string }> } };

export type GetEventDetailsQueryVariables = Exact<{
  eventDetailsId: Scalars['String'];
}>;


export type GetEventDetailsQuery = { __typename?: 'Query', eventDetails?: { __typename?: 'Events', id?: string | null, systemId?: string | null, deviceName?: string | null, deviceId?: string | null, eventTimestamp?: string | null, eventType?: string | null, eventDescription?: string | null, eventName?: string | null, eventValue?: string | null, maintenanceQueue?: string | null, severity?: string | null, status?: string | null, topic?: string | null, inverted?: boolean | null, updatedAt?: string | null, buDeviceType?: string | null, blockName?: string | null } | null };

export type GetEventsHistoryDataQueryVariables = Exact<{
  eventsHistoryDataId: Scalars['String'];
  eventType?: InputMaybe<EventType>;
  pageNo?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetEventsHistoryDataQuery = { __typename?: 'Query', eventsHistoryData?: { __typename?: 'EventHistoryResult', totalCount?: number | null, historyData?: Array<{ __typename?: 'EventsHistory', id: string, eventTimestamp?: string | null, status?: string | null, eventValue?: string | null } | null> | null } | null };

export type GetEventsBySiteQueryVariables = Exact<{
  eventType: EventType;
  siteId: Scalars['String'];
  showActive?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetEventsBySiteQuery = { __typename?: 'Query', eventsBySiteId?: Array<{ __typename?: 'Events', id?: string | null, systemId?: string | null, deviceName?: string | null, deviceId?: string | null, eventTimestamp?: string | null, eventType?: string | null, eventDescription?: string | null, eventName?: string | null, eventValue?: string | null, maintenanceQueue?: string | null, severity?: string | null, status?: string | null, topic?: string | null, inverted?: boolean | null, updatedAt?: string | null } | null> | null };

export type GetSiteDetailsQueryVariables = Exact<{
  siteWithUsersId: Scalars['String'];
}>;


export type GetSiteDetailsQuery = { __typename?: 'Query', siteDetails?: { __typename?: 'Site', id: string, userSiteId: string, name: string, tenantId: string, address1: string, address2: string, country: string, city: string, status?: SiteStatus | null, state?: string | null, postalCode: string, createdAt: number, businessUnit?: string | null, createdInspectionsCount: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, address1: string, address2: string, postalCode?: string | null, country: string, city: string, createdAt: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null } | null } | null };

export type UpdateSiteWithBusinessUnitMutationVariables = Exact<{
  siteId: Scalars['String'];
  businessUnit: BusinessUnit;
}>;


export type UpdateSiteWithBusinessUnitMutation = { __typename?: 'Mutation', updateSiteWithBusinessUnit?: boolean | null };

export type GetFalseActivationSystemAnalyticsQueryVariables = Exact<{
  input: GetSiteSystemAnalyticsInput;
}>;


export type GetFalseActivationSystemAnalyticsQuery = { __typename?: 'Query', siteSystemAnalytics?: { __typename?: 'SiteSystemAnalytics', falseActivation?: Array<{ __typename?: 'FalseActivation', eventDate?: string | null, activationTimeLimit?: number | null, averageStandByPumpingTimeSec?: number | null, standByFrequency?: number | null, standByPumpingStatus?: PumpingStatus | null } | null> | null } | null };

export type GetPumpConditionMonitoringQueryVariables = Exact<{
  input: GetPumpConditionMonitoringInput;
}>;


export type GetPumpConditionMonitoringQuery = { __typename?: 'Query', pumpConditionMonitoring?: { __typename?: 'PumpConditionMonitoring', numberOfPumps: number, thresholdPressure: number, averageTargetPressure: number, pumpMonitoring?: Array<{ __typename?: 'PumpMonitoring', date: string, data: Array<{ __typename?: 'PumpMonitoringData', motor: number, pressure: number, color: string }> }> | null, pressureTrend?: Array<{ __typename?: 'PressureTrend', motor: number, pressure: number, arrow: PressureTrendArrow }> | null } | null };

export type CreateSystemMutationVariables = Exact<{
  siteId: Scalars['String'];
  input: CreateSystemInput;
}>;


export type CreateSystemMutation = { __typename?: 'Mutation', createSystem?: { __typename?: 'System', id: string, name: string, type: string, buSystemId: string, state?: SystemState | null, isArchived: boolean, isConfigUploaded: boolean, createdAt: string, createdBy?: { __typename?: 'User', id?: string | null, emailAddress?: string | null, firstName: string, lastName?: string | null } | null } | null };

export type DeleteSystemMutationVariables = Exact<{
  deleteSystemId: Scalars['String'];
}>;


export type DeleteSystemMutation = { __typename?: 'Mutation', deleteSystem?: boolean | null };

export type DownloadSystemConfigurationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DownloadSystemConfigurationQuery = { __typename?: 'Query', downloadSystemConfiguration: string };

export type RequestLatestSystemConfigurationQueryVariables = Exact<{
  systemId: Scalars['String'];
}>;


export type RequestLatestSystemConfigurationQuery = { __typename?: 'Query', requestLatestSystemConfiguration?: boolean | null };

export type SystemConfigsWithSystemIdQueryVariables = Exact<{
  systemId: Scalars['String'];
}>;


export type SystemConfigsWithSystemIdQuery = { __typename?: 'Query', systemConfigsWithSystemId: Array<{ __typename?: 'SystemConfig', createdAt: string, name: string, createdBy: string, filePath: string, id: string, checksum: string, system?: { __typename?: 'System', buSystemId: string, type: string } | null, user?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null } | null } | null> };

export type GetSystemWithSystemIdQueryVariables = Exact<{
  systemId: Scalars['String'];
}>;


export type GetSystemWithSystemIdQuery = { __typename?: 'Query', systemWithSystemId: { __typename?: 'System', id: string, name: string, type: string, buSystemId: string } };

export type UpdateSystemMutationVariables = Exact<{
  siteId: Scalars['String'];
  input: UpdateSystemInput;
}>;


export type UpdateSystemMutation = { __typename?: 'Mutation', updateSystem?: { __typename?: 'System', id: string } | null };

export type CreateInstallationLoopMutationVariables = Exact<{
  loopName: Scalars['String'];
  systemID: Scalars['String'];
}>;


export type CreateInstallationLoopMutation = { __typename?: 'Mutation', createInstallationLoop: { __typename?: 'InstallationLoopOutput', id: string, name: string } };

export type UpdateInstallationLoopMutationVariables = Exact<{
  params: UpdateInstallationLoopInput;
}>;


export type UpdateInstallationLoopMutation = { __typename?: 'Mutation', updateInstallationLoop: { __typename?: 'InstallationLoopOutput', id: string, name: string } };

export type DeleteInstallationLoopMutationVariables = Exact<{
  loopID: Scalars['String'];
}>;


export type DeleteInstallationLoopMutation = { __typename?: 'Mutation', deleteInstallationLoop: boolean };

export type CreateInstallationDeviceMutationVariables = Exact<{
  params: CreateInstallationDeviceInput;
  loopId: Scalars['String'];
}>;


export type CreateInstallationDeviceMutation = { __typename?: 'Mutation', createInstallationDevice: { __typename?: 'InstallationDeviceOutput', id: string, tag: string } };

export type UpdateInstallationDeviceMutationVariables = Exact<{
  params: UpdateInstallationDeviceInput;
}>;


export type UpdateInstallationDeviceMutation = { __typename?: 'Mutation', updateInstallationDevice: { __typename?: 'InstallationDeviceOutput', id: string, tag: string } };

export type DeleteInstallationDeviceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteInstallationDeviceMutation = { __typename?: 'Mutation', deleteInstallationDevice?: boolean | null };

export type UploadInstallationFileMutationVariables = Exact<{
  fileContent: Scalars['String'];
  systemId: Scalars['String'];
}>;


export type UploadInstallationFileMutation = { __typename?: 'Mutation', populateDBWithInstallationFileContent?: boolean | null };

export type InstallationLoopsQueryVariables = Exact<{
  systemID: Scalars['String'];
}>;


export type InstallationLoopsQuery = { __typename?: 'Query', installationLoops?: Array<{ __typename?: 'InstallationLoop', id: string, name: string, status: InstallationLoopStatus, createdAt: string, commissionedAt: string, devicesCount: number } | null> | null };

export type InstallationDevicesQueryVariables = Exact<{
  loopId: Scalars['String'];
  queryParams?: InputMaybe<QueryParameters>;
}>;


export type InstallationDevicesQuery = { __typename?: 'Query', installationDevices?: { __typename?: 'InstallationDeviceListOutput', installationDevice?: Array<{ __typename?: 'InstallationDevice', id: string, tag: string, detectionZone?: string | null, serialNumber?: string | null, heat?: boolean | null, flash?: boolean | null, sounder?: boolean | null, status: InstallationDeviceStatus, createdAt: string, completedAt: string } | null> | null, queryStats?: { __typename?: 'QueryStatistics', pagination?: { __typename?: 'PaginationOutput', totalRecords: number } | null } | null } | null };

export type GetSignedUrlForTemplateFileQueryVariables = Exact<{
  templateFileName: Scalars['String'];
}>;


export type GetSignedUrlForTemplateFileQuery = { __typename?: 'Query', getSignedUrlForTemplateFile?: string | null };

export type InstallationLoopAsCsvQueryVariables = Exact<{
  loopId: Scalars['String'];
}>;


export type InstallationLoopAsCsvQuery = { __typename?: 'Query', installationLoopAsCsv?: string | null };

export type InstallationDownloadCountQueryVariables = Exact<{
  loopId: Scalars['String'];
}>;


export type InstallationDownloadCountQuery = { __typename?: 'Query', installationDownloadCount?: number | null };

export type InstallationTagNamesQueryVariables = Exact<{
  systemId: Scalars['String'];
}>;


export type InstallationTagNamesQuery = { __typename?: 'Query', installationTagNames?: Array<string | null> | null };

export type AssignSystemToNodeMutationVariables = Exact<{
  systemId: Scalars['String'];
  nodeId: Scalars['String'];
}>;


export type AssignSystemToNodeMutation = { __typename?: 'Mutation', assignSystemToNode?: boolean | null };

export type CreateNodeMutationVariables = Exact<{
  input: CreateNodeInput;
}>;


export type CreateNodeMutation = { __typename?: 'Mutation', createNode?: { __typename?: 'NodeWithDevice', node: { __typename?: 'Node', id: string, name: string, nodeDeviceId: string, createdAt: string, updatedAt?: string | null, externalNetworkStatus: ExternalNetworkStatus, ipAddress: string, subnetMask: string, createdBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, emailAddress?: string | null } | null, updatedBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, emailAddress?: string | null } | null }, nodeDevice: { __typename?: 'NodeDevice', id: string, type: NodeDeviceType, imei?: string | null, iccid?: string | null, imsi?: string | null } } | null };

export type ArchiveSystemMutationVariables = Exact<{
  systemId: Scalars['String'];
}>;


export type ArchiveSystemMutation = { __typename?: 'Mutation', archiveSystem?: boolean | null };

export type ApplySiteNodeConfigurationsMutationVariables = Exact<{
  siteId: Scalars['ID'];
  nodeId: Scalars['ID'];
}>;


export type ApplySiteNodeConfigurationsMutation = { __typename?: 'Mutation', applySiteNodeConfigurations?: boolean | null };

export type UnassignSystemFromNodeMutationVariables = Exact<{
  systemId: Scalars['String'];
  nodeId: Scalars['String'];
}>;


export type UnassignSystemFromNodeMutation = { __typename?: 'Mutation', unassignSystemFromNode?: boolean | null };

export type SystemsWithSiteIdNodeIdQueryVariables = Exact<{
  siteId: Scalars['String'];
  nodeId: Scalars['String'];
}>;


export type SystemsWithSiteIdNodeIdQuery = { __typename?: 'Query', systemsWithSiteIdNodeId: Array<{ __typename?: 'System', id: string, name: string, buSystemId: string, type: string, isArchived: boolean, state?: SystemState | null, networkConfigStatus: ConfigStatus, deviceConfigStatus: ConfigStatus, liveDataStatus: LiveDataStatus, ipAddress?: string | null, port?: string | null, userName?: string | null, userPassword?: string | null, status?: GeneralSystemStatus | null, node?: { __typename?: 'Node', name: string, id: string, externalNetworkStatus: ExternalNetworkStatus } | null, systemConnection?: { __typename?: 'SystemConnection', state?: { __typename?: 'State', stages: { __typename?: 'Stages', mobileConnection: string, networkConfig: string, panelConfig: string, liveUpdates: string } } | null } | null } | null> };

export type GetSystemsBySiteIdQueryVariables = Exact<{
  siteId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<SystemsFilter>;
}>;


export type GetSystemsBySiteIdQuery = { __typename?: 'Query', getSystemsBySiteId: Array<{ __typename?: 'System', id: string, name: string, buSystemId: string, type: string, isArchived: boolean, state?: SystemState | null, networkConfigStatus: ConfigStatus, deviceConfigStatus: ConfigStatus, liveDataStatus: LiveDataStatus, ipAddress?: string | null, port?: string | null, userName?: string | null, userPassword?: string | null, status?: GeneralSystemStatus | null, node?: { __typename?: 'Node', name: string, id: string, externalNetworkStatus: ExternalNetworkStatus, nodeDeviceId: string } | null, systemConnection?: { __typename?: 'SystemConnection', state?: { __typename?: 'State', stages: { __typename?: 'Stages', mobileConnection: string, networkConfig: string, panelConfig: string, liveUpdates: string } } | null } | null } | null> };

export type GetUnconnectedSystemsQueryVariables = Exact<{
  siteId: Scalars['String'];
}>;


export type GetUnconnectedSystemsQuery = { __typename?: 'Query', systemsWithNoConnection: Array<{ __typename?: 'System', id: string, name: string, buSystemId: string, type: string, isArchived: boolean, state?: SystemState | null, networkConfigStatus: ConfigStatus, deviceConfigStatus: ConfigStatus, liveDataStatus: LiveDataStatus, ipAddress?: string | null, port?: string | null, userName?: string | null, userPassword?: string | null, status?: GeneralSystemStatus | null, node?: { __typename?: 'Node', name: string, id: string, externalNetworkStatus: ExternalNetworkStatus } | null, systemConnection?: { __typename?: 'SystemConnection', state?: { __typename?: 'State', stages: { __typename?: 'Stages', mobileConnection: string, networkConfig: string, panelConfig: string, liveUpdates: string } } | null } | null } | null> };

export type UpdateNodeMutationVariables = Exact<{
  input: UpdateNodeInput;
}>;


export type UpdateNodeMutation = { __typename?: 'Mutation', updateNode?: { __typename?: 'NodeWithDevice', node: { __typename?: 'Node', id: string, name: string, nodeDeviceId: string, createdAt: string, updatedAt?: string | null, externalNetworkStatus: ExternalNetworkStatus, ipAddress: string, subnetMask: string, createdBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, emailAddress?: string | null } | null, updatedBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, emailAddress?: string | null } | null }, nodeDevice: { __typename?: 'NodeDevice', id: string, type: NodeDeviceType, imei?: string | null, iccid?: string | null, imsi?: string | null } } | null };

export type GetNodesListQueryVariables = Exact<{
  siteId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetNodesListQuery = { __typename?: 'Query', siteNodes: { __typename?: 'SiteNodesResult', nodesWithDevices?: Array<{ __typename?: 'NodeWithDevice', node: { __typename?: 'Node', id: string, name: string, nodeDeviceId: string, createdAt: string, updatedAt?: string | null, externalNetworkStatus: ExternalNetworkStatus, ipAddress: string, subnetMask: string, createdBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, emailAddress?: string | null } | null, updatedBy?: { __typename?: 'User', id?: string | null, firstName: string, lastName?: string | null, emailAddress?: string | null } | null }, nodeDevice: { __typename?: 'NodeDevice', id: string, type: NodeDeviceType, imei?: string | null, iccid?: string | null, imsi?: string | null, serialNumber: string } } | null> | null } };

export type SystemNameQueryVariables = Exact<{
  systemId: Scalars['String'];
}>;


export type SystemNameQuery = { __typename?: 'Query', systemWithSystemId: { __typename?: 'System', id: string, name: string } };

export type GetUserByOktaIdQueryVariables = Exact<{
  oktaId: Scalars['String'];
}>;


export type GetUserByOktaIdQuery = { __typename?: 'Query', getUserByOktaId?: { __typename?: 'UserInfoWithRole', role: UserRoles, user: { __typename?: 'User', id?: string | null, oktaId: string, emailAddress?: string | null, firstName: string, lastName?: string | null, phoneNumber?: string | null, preferredLanguage?: string | null, status?: UserStatus | null, updatedBy?: string | null, updatedAt?: string | null, createdBy?: string | null, createdAt: string, invitedAt: string, role?: string | null, areAllNotificationsEnabled: boolean, tenant?: { __typename?: 'Tenant', id: string, name: string, address1: string, address2: string, postalCode?: string | null, country: string, city: string, createdAt: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null } | null } } | null };

export type GetUsersQueryVariables = Exact<{
  sorting?: InputMaybe<UsersSorting>;
  pagination?: InputMaybe<UserPagination>;
  tenantId?: InputMaybe<Scalars['String']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers?: { __typename?: 'UsersList', numberOfRecords: number, totalPages: number, currentPage: number, totalRecords: number, users?: Array<{ __typename?: 'User', id?: string | null, oktaId: string, emailAddress?: string | null, firstName: string, lastName?: string | null, phoneNumber?: string | null, preferredLanguage?: string | null, status?: UserStatus | null, updatedBy?: string | null, updatedAt?: string | null, createdBy?: string | null, createdAt: string, invitedAt: string, role?: string | null, tenant?: { __typename?: 'Tenant', id: string, name: string, address1: string, address2: string, postalCode?: string | null, country: string, city: string, createdAt: number, contactPerson?: { __typename?: 'ContactPerson', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null } | null } | null } | null> | null } | null };

export type ResetNotificationSettingsMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetNotificationSettingsMutation = { __typename?: 'Mutation', resetNotificationSettings?: boolean | null };

export type UpdateNotificationSettingsMutationVariables = Exact<{
  input: UpdateNotificationSettingsInput;
}>;


export type UpdateNotificationSettingsMutation = { __typename?: 'Mutation', updateNotificationSettings?: boolean | null };

export type GetDropdownTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDropdownTenantsQuery = { __typename?: 'Query', tenants?: Array<{ __typename?: 'Tenant', id: string, name: string } | null> | null };

export type AddUserMutationVariables = Exact<{
  user?: InputMaybe<UserProfileInput>;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser?: { __typename?: 'User', id?: string | null, oktaId: string, emailAddress?: string | null, firstName: string, lastName?: string | null, phoneNumber?: string | null, preferredLanguage?: string | null, status?: UserStatus | null, updatedBy?: string | null, updatedAt?: string | null, createdBy?: string | null, createdAt: string, invitedAt: string } | null };

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  ActiveAlarmDetails: ResolverTypeWrapper<ActiveAlarmDetails>;
  AlarmGraphDetails: ResolverTypeWrapper<AlarmGraphDetails>;
  AssignUsersInput: AssignUsersInput;
  AssignedTenantItem: ResolverTypeWrapper<AssignedTenantItem>;
  AssignedTenantsListOutput: ResolverTypeWrapper<AssignedTenantsListOutput>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BusinessUnit: BusinessUnit;
  ClientDeviceType: ClientDeviceType;
  CompleteInspectionInput: CompleteInspectionInput;
  CompleteInspectionInputChecklist: CompleteInspectionInputChecklist;
  CompleteInspectionInputComment: CompleteInspectionInputComment;
  ConfigStatus: ConfigStatus;
  ConnectivityStatusDetails: ResolverTypeWrapper<ConnectivityStatusDetails>;
  ContactPerson: ResolverTypeWrapper<ContactPerson>;
  ContactPersonInput: ContactPersonInput;
  CreateInstallationDeviceInput: CreateInstallationDeviceInput;
  CreateNodeInput: CreateNodeInput;
  CreateNodeMessageStatus: CreateNodeMessageStatus;
  CreateNodeMessageType: CreateNodeMessageType;
  CreateNodeWithDevice: ResolverTypeWrapper<CreateNodeWithDevice>;
  CreateSystemInput: CreateSystemInput;
  CurrentUser: ResolverTypeWrapper<CurrentUser>;
  Device: ResolverTypeWrapper<Device>;
  DeviceFilterInput: DeviceFilterInput;
  DeviceProperty: ResolverTypeWrapper<DeviceProperty>;
  DeviceRegistration: DeviceRegistration;
  DeviceState: DeviceState;
  DevicesSummary: ResolverTypeWrapper<DevicesSummary>;
  EventHistoryResult: ResolverTypeWrapper<EventHistoryResult>;
  EventStatus: ResolverTypeWrapper<EventStatus>;
  EventType: EventType;
  Events: ResolverTypeWrapper<Events>;
  EventsHistory: ResolverTypeWrapper<EventsHistory>;
  ExternalNetworkStatus: ExternalNetworkStatus;
  FalseActivation: ResolverTypeWrapper<FalseActivation>;
  FilterOperator: FilterOperator;
  FilteringParameters: FilteringParameters;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GatewayDetails: ResolverTypeWrapper<GatewayDetails>;
  GeneralSystemStatus: GeneralSystemStatus;
  GetInspectionsByDateInput: GetInspectionsByDateInput;
  GetInspectionsInput: GetInspectionsInput;
  GetPumpConditionMonitoringInput: GetPumpConditionMonitoringInput;
  GetSiteSystemAnalyticsInput: GetSiteSystemAnalyticsInput;
  HttpVerbs: HttpVerbs;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Inspection: ResolverTypeWrapper<Inspection>;
  InspectionCheckList: ResolverTypeWrapper<InspectionCheckList>;
  InspectionCheckListStep: ResolverTypeWrapper<InspectionCheckListStep>;
  InspectionComment: ResolverTypeWrapper<InspectionComment>;
  InspectionListOutput: ResolverTypeWrapper<InspectionListOutput>;
  InspectionMasterTemplateItem: ResolverTypeWrapper<InspectionMasterTemplateItem>;
  InspectionMasterTemplatesListOutput: ResolverTypeWrapper<InspectionMasterTemplatesListOutput>;
  InspectionOutput: ResolverTypeWrapper<InspectionOutput>;
  InspectionStatus: InspectionStatus;
  InspectionTemplateCheckListInput: InspectionTemplateCheckListInput;
  InspectionTemplateCheckListStepInput: InspectionTemplateCheckListStepInput;
  InspectionTemplateInput: InspectionTemplateInput;
  InspectionTemplateItem: ResolverTypeWrapper<InspectionTemplateItem>;
  InspectionTemplateStatus: InspectionTemplateStatus;
  InspectionType: InspectionType;
  InstallationDevice: ResolverTypeWrapper<InstallationDevice>;
  InstallationDeviceListOutput: ResolverTypeWrapper<InstallationDeviceListOutput>;
  InstallationDeviceOutput: ResolverTypeWrapper<InstallationDeviceOutput>;
  InstallationDeviceStatus: InstallationDeviceStatus;
  InstallationLoop: ResolverTypeWrapper<InstallationLoop>;
  InstallationLoopOutput: ResolverTypeWrapper<InstallationLoopOutput>;
  InstallationLoopStatus: InstallationLoopStatus;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  LiveDataStatus: LiveDataStatus;
  Location: ResolverTypeWrapper<Location>;
  Mutation: ResolverTypeWrapper<{}>;
  Node: ResolverTypeWrapper<Node>;
  NodeDevice: ResolverTypeWrapper<NodeDevice>;
  NodeDeviceStatus: NodeDeviceStatus;
  NodeDeviceType: NodeDeviceType;
  NodeFilter: NodeFilter;
  NodeState: NodeState;
  NodeWithDevice: ResolverTypeWrapper<NodeWithDevice>;
  NotificationSetting: ResolverTypeWrapper<NotificationSetting>;
  NotificationSettingInput: NotificationSettingInput;
  NotificationTypeSetting: ResolverTypeWrapper<NotificationTypeSetting>;
  NotificationTypeSettingInput: NotificationTypeSettingInput;
  PaginationOutput: ResolverTypeWrapper<PaginationOutput>;
  PaginationParameters: PaginationParameters;
  PressureTrend: ResolverTypeWrapper<PressureTrend>;
  PressureTrendArrow: PressureTrendArrow;
  PropertiesMap: ResolverTypeWrapper<PropertiesMap>;
  PumpConditionMonitoring: ResolverTypeWrapper<PumpConditionMonitoring>;
  PumpMonitoring: ResolverTypeWrapper<PumpMonitoring>;
  PumpMonitoringData: ResolverTypeWrapper<PumpMonitoringData>;
  PumpingStatus: PumpingStatus;
  PushNotification: ResolverTypeWrapper<PushNotification>;
  Query: ResolverTypeWrapper<{}>;
  QueryParameters: QueryParameters;
  QueryStatistics: ResolverTypeWrapper<QueryStatistics>;
  ScheduleInspectionInput: ScheduleInspectionInput;
  SignedUrlInput: SignedUrlInput;
  Site: ResolverTypeWrapper<Site>;
  SiteDashboardDetails: ResolverTypeWrapper<SiteDashboardDetails>;
  SiteDashboardInput: SiteDashboardInput;
  SiteFilterInput: SiteFilterInput;
  SiteInput: SiteInput;
  SiteIssuesStatistics: ResolverTypeWrapper<SiteIssuesStatistics>;
  SiteListOutput: ResolverTypeWrapper<SiteListOutput>;
  SiteNodesResult: ResolverTypeWrapper<SiteNodesResult>;
  SiteStatistics: ResolverTypeWrapper<SiteStatistics>;
  SiteStatus: SiteStatus;
  SiteStatusDetail: ResolverTypeWrapper<SiteStatusDetail>;
  SiteStatuses: SiteStatuses;
  SiteStatusesOutput: ResolverTypeWrapper<SiteStatusesOutput>;
  SiteSystemAnalytics: ResolverTypeWrapper<SiteSystemAnalytics>;
  SortBy: SortBy;
  SortEnum: SortEnum;
  SortOrder: SortOrder;
  SortingParameters: SortingParameters;
  Stages: ResolverTypeWrapper<Stages>;
  State: ResolverTypeWrapper<State>;
  String: ResolverTypeWrapper<Scalars['String']>;
  System: ResolverTypeWrapper<System>;
  SystemConfig: ResolverTypeWrapper<SystemConfig>;
  SystemConnection: ResolverTypeWrapper<SystemConnection>;
  SystemConnectionInput: SystemConnectionInput;
  SystemState: SystemState;
  SystemType: SystemType;
  SystemTypes: SystemTypes;
  SystemsFilter: SystemsFilter;
  TemplateAssignedTenant: ResolverTypeWrapper<TemplateAssignedTenant>;
  TemplateDetails: ResolverTypeWrapper<TemplateDetails>;
  TemplatesCount: ResolverTypeWrapper<TemplatesCount>;
  TemplatesListOutput: ResolverTypeWrapper<TemplatesListOutput>;
  Tenant: ResolverTypeWrapper<Tenant>;
  TenantInput: TenantInput;
  TenantSitesStatuses: ResolverTypeWrapper<TenantSitesStatuses>;
  UnAssignedTenantsOutput: ResolverTypeWrapper<UnAssignedTenantsOutput>;
  UnassignUserInput: UnassignUserInput;
  UnitsDetails: ResolverTypeWrapper<UnitsDetails>;
  UpdateInstallationDeviceInput: UpdateInstallationDeviceInput;
  UpdateInstallationLoopInput: UpdateInstallationLoopInput;
  UpdateNodeInput: UpdateNodeInput;
  UpdateNotificationSettingsInput: UpdateNotificationSettingsInput;
  UpdateSystemInput: UpdateSystemInput;
  User: ResolverTypeWrapper<User>;
  UserInfoWithRole: ResolverTypeWrapper<UserInfoWithRole>;
  UserPagination: UserPagination;
  UserProfileInput: UserProfileInput;
  UserRoles: UserRoles;
  UserSortingAttributes: UserSortingAttributes;
  UserSortingDirection: UserSortingDirection;
  UserStatus: UserStatus;
  UsersList: ResolverTypeWrapper<UsersList>;
  UsersSorting: UsersSorting;
  assignableUser: ResolverTypeWrapper<AssignableUser>;
  assignedUser: ResolverTypeWrapper<AssignedUser>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  ActiveAlarmDetails: ActiveAlarmDetails;
  AlarmGraphDetails: AlarmGraphDetails;
  AssignUsersInput: AssignUsersInput;
  AssignedTenantItem: AssignedTenantItem;
  AssignedTenantsListOutput: AssignedTenantsListOutput;
  Boolean: Scalars['Boolean'];
  CompleteInspectionInput: CompleteInspectionInput;
  CompleteInspectionInputChecklist: CompleteInspectionInputChecklist;
  CompleteInspectionInputComment: CompleteInspectionInputComment;
  ConnectivityStatusDetails: ConnectivityStatusDetails;
  ContactPerson: ContactPerson;
  ContactPersonInput: ContactPersonInput;
  CreateInstallationDeviceInput: CreateInstallationDeviceInput;
  CreateNodeInput: CreateNodeInput;
  CreateNodeWithDevice: CreateNodeWithDevice;
  CreateSystemInput: CreateSystemInput;
  CurrentUser: CurrentUser;
  Device: Device;
  DeviceFilterInput: DeviceFilterInput;
  DeviceProperty: DeviceProperty;
  DeviceRegistration: DeviceRegistration;
  DevicesSummary: DevicesSummary;
  EventHistoryResult: EventHistoryResult;
  EventStatus: EventStatus;
  Events: Events;
  EventsHistory: EventsHistory;
  FalseActivation: FalseActivation;
  FilteringParameters: FilteringParameters;
  Float: Scalars['Float'];
  GatewayDetails: GatewayDetails;
  GetInspectionsByDateInput: GetInspectionsByDateInput;
  GetInspectionsInput: GetInspectionsInput;
  GetPumpConditionMonitoringInput: GetPumpConditionMonitoringInput;
  GetSiteSystemAnalyticsInput: GetSiteSystemAnalyticsInput;
  ID: Scalars['ID'];
  Inspection: Inspection;
  InspectionCheckList: InspectionCheckList;
  InspectionCheckListStep: InspectionCheckListStep;
  InspectionComment: InspectionComment;
  InspectionListOutput: InspectionListOutput;
  InspectionMasterTemplateItem: InspectionMasterTemplateItem;
  InspectionMasterTemplatesListOutput: InspectionMasterTemplatesListOutput;
  InspectionOutput: InspectionOutput;
  InspectionTemplateCheckListInput: InspectionTemplateCheckListInput;
  InspectionTemplateCheckListStepInput: InspectionTemplateCheckListStepInput;
  InspectionTemplateInput: InspectionTemplateInput;
  InspectionTemplateItem: InspectionTemplateItem;
  InstallationDevice: InstallationDevice;
  InstallationDeviceListOutput: InstallationDeviceListOutput;
  InstallationDeviceOutput: InstallationDeviceOutput;
  InstallationLoop: InstallationLoop;
  InstallationLoopOutput: InstallationLoopOutput;
  Int: Scalars['Int'];
  JSON: Scalars['JSON'];
  Location: Location;
  Mutation: {};
  Node: Node;
  NodeDevice: NodeDevice;
  NodeFilter: NodeFilter;
  NodeWithDevice: NodeWithDevice;
  NotificationSetting: NotificationSetting;
  NotificationSettingInput: NotificationSettingInput;
  NotificationTypeSetting: NotificationTypeSetting;
  NotificationTypeSettingInput: NotificationTypeSettingInput;
  PaginationOutput: PaginationOutput;
  PaginationParameters: PaginationParameters;
  PressureTrend: PressureTrend;
  PropertiesMap: PropertiesMap;
  PumpConditionMonitoring: PumpConditionMonitoring;
  PumpMonitoring: PumpMonitoring;
  PumpMonitoringData: PumpMonitoringData;
  PushNotification: PushNotification;
  Query: {};
  QueryParameters: QueryParameters;
  QueryStatistics: QueryStatistics;
  ScheduleInspectionInput: ScheduleInspectionInput;
  SignedUrlInput: SignedUrlInput;
  Site: Site;
  SiteDashboardDetails: SiteDashboardDetails;
  SiteDashboardInput: SiteDashboardInput;
  SiteFilterInput: SiteFilterInput;
  SiteInput: SiteInput;
  SiteIssuesStatistics: SiteIssuesStatistics;
  SiteListOutput: SiteListOutput;
  SiteNodesResult: SiteNodesResult;
  SiteStatistics: SiteStatistics;
  SiteStatusDetail: SiteStatusDetail;
  SiteStatusesOutput: SiteStatusesOutput;
  SiteSystemAnalytics: SiteSystemAnalytics;
  SortingParameters: SortingParameters;
  Stages: Stages;
  State: State;
  String: Scalars['String'];
  System: System;
  SystemConfig: SystemConfig;
  SystemConnection: SystemConnection;
  SystemConnectionInput: SystemConnectionInput;
  SystemsFilter: SystemsFilter;
  TemplateAssignedTenant: TemplateAssignedTenant;
  TemplateDetails: TemplateDetails;
  TemplatesCount: TemplatesCount;
  TemplatesListOutput: TemplatesListOutput;
  Tenant: Tenant;
  TenantInput: TenantInput;
  TenantSitesStatuses: TenantSitesStatuses;
  UnAssignedTenantsOutput: UnAssignedTenantsOutput;
  UnassignUserInput: UnassignUserInput;
  UnitsDetails: UnitsDetails;
  UpdateInstallationDeviceInput: UpdateInstallationDeviceInput;
  UpdateInstallationLoopInput: UpdateInstallationLoopInput;
  UpdateNodeInput: UpdateNodeInput;
  UpdateNotificationSettingsInput: UpdateNotificationSettingsInput;
  UpdateSystemInput: UpdateSystemInput;
  User: User;
  UserInfoWithRole: UserInfoWithRole;
  UserPagination: UserPagination;
  UserProfileInput: UserProfileInput;
  UsersList: UsersList;
  UsersSorting: UsersSorting;
  assignableUser: AssignableUser;
  assignedUser: AssignedUser;
}>;

export type RelationshipDirectiveArgs = {
  direction?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RelationshipDirectiveResolver<Result, Parent, ContextType = any, Args = RelationshipDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActiveAlarmDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActiveAlarmDetails'] = ResolversParentTypes['ActiveAlarmDetails']> = ResolversObject<{
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  events?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlarmGraphDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlarmGraphDetails'] = ResolversParentTypes['AlarmGraphDetails']> = ResolversObject<{
  alarmsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssignedTenantItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignedTenantItem'] = ResolversParentTypes['AssignedTenantItem']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tenant?: Resolver<ResolversTypes['Tenant'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssignedTenantsListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignedTenantsListOutput'] = ResolversParentTypes['AssignedTenantsListOutput']> = ResolversObject<{
  assignedTenantsList?: Resolver<Array<Maybe<ResolversTypes['AssignedTenantItem']>>, ParentType, ContextType>;
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectivityStatusDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectivityStatusDetails'] = ResolversParentTypes['ConnectivityStatusDetails']> = ResolversObject<{
  componentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  componentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  connectivity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  loopUnits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  systemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactPerson'] = ResolversParentTypes['ContactPerson']> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateNodeWithDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateNodeWithDevice'] = ResolversParentTypes['CreateNodeWithDevice']> = ResolversObject<{
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageStatus?: Resolver<Maybe<ResolversTypes['CreateNodeMessageStatus']>, ParentType, ContextType>;
  messageType?: Resolver<ResolversTypes['CreateNodeMessageType'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['NodeWithDevice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CurrentUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentUser'] = ResolversParentTypes['CurrentUser']> = ResolversObject<{
  permissions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isHistorical?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastHeartbeatAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  locationDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentDevice?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType>;
  properties?: Resolver<Array<Maybe<ResolversTypes['DeviceProperty']>>, ParentType, ContextType>;
  serialNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['DeviceState'], ParentType, ContextType>;
  system?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType>;
  systemConfig?: Resolver<Maybe<ResolversTypes['SystemConfig']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DevicePropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeviceProperty'] = ResolversParentTypes['DeviceProperty']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DevicesSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevicesSummary'] = ResolversParentTypes['DevicesSummary']> = ResolversObject<{
  devices?: Resolver<Array<ResolversTypes['Device']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventHistoryResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventHistoryResult'] = ResolversParentTypes['EventHistoryResult']> = ResolversObject<{
  historyData?: Resolver<Maybe<Array<Maybe<ResolversTypes['EventsHistory']>>>, ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventStatus'] = ResolversParentTypes['EventStatus']> = ResolversObject<{
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Events'] = ResolversParentTypes['Events']> = ResolversObject<{
  blockName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buDeviceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  inverted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  maintenanceQueue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  severity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventsHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventsHistory'] = ResolversParentTypes['EventsHistory']> = ResolversObject<{
  blockName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buDeviceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastUserActivity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  severity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FalseActivationResolvers<ContextType = any, ParentType extends ResolversParentTypes['FalseActivation'] = ResolversParentTypes['FalseActivation']> = ResolversObject<{
  activationTimeLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageStandByPumpingTimeSec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  standByFrequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  standByPumpingStatus?: Resolver<Maybe<ResolversTypes['PumpingStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GatewayDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GatewayDetails'] = ResolversParentTypes['GatewayDetails']> = ResolversObject<{
  connected?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notConnected?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Inspection'] = ResolversParentTypes['Inspection']> = ResolversObject<{
  checklists?: Resolver<Array<ResolversTypes['InspectionCheckList']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<Array<Maybe<ResolversTypes['InspectionComment']>>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduledAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['Site']>, ParentType, ContextType>;
  startedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['InspectionStatus']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['InspectionTemplateItem']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['InspectionType']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionCheckListResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionCheckList'] = ResolversParentTypes['InspectionCheckList']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isChecked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  steps?: Resolver<Array<ResolversTypes['InspectionCheckListStep']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionCheckListStepResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionCheckListStep'] = ResolversParentTypes['InspectionCheckListStep']> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mediaFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionComment'] = ResolversParentTypes['InspectionComment']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  mediaFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionListOutput'] = ResolversParentTypes['InspectionListOutput']> = ResolversObject<{
  inspections?: Resolver<Array<Maybe<ResolversTypes['Inspection']>>, ParentType, ContextType>;
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionMasterTemplateItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionMasterTemplateItem'] = ResolversParentTypes['InspectionMasterTemplateItem']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  assignedTenants?: Resolver<Maybe<Array<Maybe<ResolversTypes['TemplateAssignedTenant']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['InspectionTemplateStatus']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['InspectionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionMasterTemplatesListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionMasterTemplatesListOutput'] = ResolversParentTypes['InspectionMasterTemplatesListOutput']> = ResolversObject<{
  inspectionMasterTemplates?: Resolver<Array<Maybe<ResolversTypes['InspectionMasterTemplateItem']>>, ParentType, ContextType>;
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionOutput'] = ResolversParentTypes['InspectionOutput']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['InspectionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InspectionTemplateItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionTemplateItem'] = ResolversParentTypes['InspectionTemplateItem']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['InspectionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstallationDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstallationDevice'] = ResolversParentTypes['InstallationDevice']> = ResolversObject<{
  completedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  detectionZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flash?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  heat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  serialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sounder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InstallationDeviceStatus'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstallationDeviceListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstallationDeviceListOutput'] = ResolversParentTypes['InstallationDeviceListOutput']> = ResolversObject<{
  installationDevice?: Resolver<Maybe<Array<Maybe<ResolversTypes['InstallationDevice']>>>, ParentType, ContextType>;
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstallationDeviceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstallationDeviceOutput'] = ResolversParentTypes['InstallationDeviceOutput']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstallationLoopResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstallationLoop'] = ResolversParentTypes['InstallationLoop']> = ResolversObject<{
  commissionedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  devicesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InstallationLoopStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstallationLoopOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstallationLoopOutput'] = ResolversParentTypes['InstallationLoopOutput']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['Site']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  addUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<MutationAddUserArgs>>;
  applySiteNodeConfigurations?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationApplySiteNodeConfigurationsArgs, 'nodeId' | 'siteId'>>;
  archiveMasterTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationArchiveMasterTemplateArgs, 'archiveReason' | 'templateId'>>;
  archiveSystem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationArchiveSystemArgs, 'systemId'>>;
  assignMasterTemplateToTenants?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAssignMasterTemplateToTenantsArgs, 'templateId' | 'tenantIds'>>;
  assignSystemToNode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAssignSystemToNodeArgs, 'nodeId' | 'systemId'>>;
  assignUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<MutationAssignUsersArgs, 'siteUsers'>>;
  completeInspection?: Resolver<Maybe<ResolversTypes['InspectionOutput']>, ParentType, ContextType, Partial<MutationCompleteInspectionArgs>>;
  createInspectionTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationCreateInspectionTemplateArgs, 'template'>>;
  createInstallationDevice?: Resolver<ResolversTypes['InstallationDeviceOutput'], ParentType, ContextType, RequireFields<MutationCreateInstallationDeviceArgs, 'loopId' | 'params'>>;
  createInstallationLoop?: Resolver<ResolversTypes['InstallationLoopOutput'], ParentType, ContextType, RequireFields<MutationCreateInstallationLoopArgs, 'loopName' | 'systemID'>>;
  createMasterTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateMasterTemplateArgs, 'template'>>;
  createNode?: Resolver<Maybe<ResolversTypes['NodeWithDevice']>, ParentType, ContextType, RequireFields<MutationCreateNodeArgs, 'input'>>;
  createSite?: Resolver<ResolversTypes['Site'], ParentType, ContextType, RequireFields<MutationCreateSiteArgs, 'site'>>;
  createSystem?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType, RequireFields<MutationCreateSystemArgs, 'input' | 'siteId'>>;
  createSystemConnection?: Resolver<ResolversTypes['SystemConnection'], ParentType, ContextType, RequireFields<MutationCreateSystemConnectionArgs, 'systemConnection'>>;
  createTenant?: Resolver<Maybe<ResolversTypes['Tenant']>, ParentType, ContextType, Partial<MutationCreateTenantArgs>>;
  deleteInspection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteInspectionArgs, 'inspectionId'>>;
  deleteInstallationDevice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteInstallationDeviceArgs, 'id'>>;
  deleteInstallationLoop?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteInstallationLoopArgs, 'loopID'>>;
  deleteSystem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSystemArgs, 'id'>>;
  deleteSystemConnection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSystemConnectionArgs, 'nodeId' | 'systemId'>>;
  dismissAllNotifications?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dismissNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDismissNotificationArgs, 'id'>>;
  populateDBWithInstallationFileContent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationPopulateDbWithInstallationFileContentArgs, 'installationFileContent' | 'systemId'>>;
  readAllNotifications?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  readNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationReadNotificationArgs, 'id'>>;
  registerDevice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRegisterDeviceArgs, 'deviceDetails'>>;
  resetNotificationSettings?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  scheduleInspection?: Resolver<Maybe<ResolversTypes['InspectionOutput']>, ParentType, ContextType, RequireFields<MutationScheduleInspectionArgs, 'inspection'>>;
  shareInspection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationShareInspectionArgs, 'inspectionId' | 'toEmailAddress'>>;
  startInspection?: Resolver<Maybe<ResolversTypes['Inspection']>, ParentType, ContextType, RequireFields<MutationStartInspectionArgs, 'inspectionId'>>;
  unRegisterDevice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUnRegisterDeviceArgs, 'token'>>;
  unassignMasterTemplates?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUnassignMasterTemplatesArgs, 'templateIds' | 'tenantId'>>;
  unassignSystemFromNode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUnassignSystemFromNodeArgs, 'nodeId' | 'systemId'>>;
  unassignUsers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUnassignUsersArgs, 'siteUsers'>>;
  updateCustomTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateCustomTemplateArgs, 'template' | 'templateId'>>;
  updateInstallationDevice?: Resolver<ResolversTypes['InstallationDeviceOutput'], ParentType, ContextType, RequireFields<MutationUpdateInstallationDeviceArgs, 'params'>>;
  updateInstallationLoop?: Resolver<ResolversTypes['InstallationLoopOutput'], ParentType, ContextType, RequireFields<MutationUpdateInstallationLoopArgs, 'params'>>;
  updateMasterTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateMasterTemplateArgs, 'template' | 'templateId'>>;
  updateNode?: Resolver<Maybe<ResolversTypes['NodeWithDevice']>, ParentType, ContextType, RequireFields<MutationUpdateNodeArgs, 'input'>>;
  updateNotificationSettings?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateNotificationSettingsArgs, 'input'>>;
  updateSiteWithBusinessUnit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateSiteWithBusinessUnitArgs, 'businessUnit' | 'siteId'>>;
  updateSystem?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType, RequireFields<MutationUpdateSystemArgs, 'input' | 'siteId'>>;
}>;

export type NodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  externalNetworkStatus?: Resolver<ResolversTypes['ExternalNetworkStatus'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ipAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isConfigChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastHeartbeatAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nodeDeviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subnetMask?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NodeDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NodeDevice'] = ResolversParentTypes['NodeDevice']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iccid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imei?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imsi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturedForBU?: Resolver<ResolversTypes['BusinessUnit'], ParentType, ContextType>;
  serialNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['NodeDeviceStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NodeDeviceType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NodeWithDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NodeWithDevice'] = ResolversParentTypes['NodeWithDevice']> = ResolversObject<{
  node?: Resolver<ResolversTypes['Node'], ParentType, ContextType>;
  nodeDevice?: Resolver<ResolversTypes['NodeDevice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationSetting'] = ResolversParentTypes['NotificationSetting']> = ResolversObject<{
  areNotificationsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  businessUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationTypeSettings?: Resolver<Maybe<Array<ResolversTypes['NotificationTypeSetting']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationTypeSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationTypeSetting'] = ResolversParentTypes['NotificationTypeSetting']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isEmailEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPushEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaginationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginationOutput'] = ResolversParentTypes['PaginationOutput']> = ResolversObject<{
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  filteredRecords?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalRecords?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PressureTrendResolvers<ContextType = any, ParentType extends ResolversParentTypes['PressureTrend'] = ResolversParentTypes['PressureTrend']> = ResolversObject<{
  arrow?: Resolver<ResolversTypes['PressureTrendArrow'], ParentType, ContextType>;
  motor?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pressure?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropertiesMapResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertiesMap'] = ResolversParentTypes['PropertiesMap']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PumpConditionMonitoringResolvers<ContextType = any, ParentType extends ResolversParentTypes['PumpConditionMonitoring'] = ResolversParentTypes['PumpConditionMonitoring']> = ResolversObject<{
  averageTargetPressure?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfPumps?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pressureTrend?: Resolver<Maybe<Array<ResolversTypes['PressureTrend']>>, ParentType, ContextType>;
  pumpMonitoring?: Resolver<Maybe<Array<ResolversTypes['PumpMonitoring']>>, ParentType, ContextType>;
  thresholdPressure?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PumpMonitoringResolvers<ContextType = any, ParentType extends ResolversParentTypes['PumpMonitoring'] = ResolversParentTypes['PumpMonitoring']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['PumpMonitoringData']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PumpMonitoringDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PumpMonitoringData'] = ResolversParentTypes['PumpMonitoringData']> = ResolversObject<{
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  motor?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pressure?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PushNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PushNotification'] = ResolversParentTypes['PushNotification']> = ResolversObject<{
  businessUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clickAction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deepLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  propertiesMap?: Resolver<Array<Maybe<ResolversTypes['PropertiesMap']>>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  currentUser?: Resolver<ResolversTypes['CurrentUser'], ParentType, ContextType>;
  devicesBySiteId?: Resolver<ResolversTypes['DevicesSummary'], ParentType, ContextType, RequireFields<QueryDevicesBySiteIdArgs, 'siteId'>>;
  downloadMasterTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryDownloadMasterTemplateArgs, 'templateId'>>;
  downloadReport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryDownloadReportArgs, 'inspectionId' | 'templateName'>>;
  downloadSystemConfiguration?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryDownloadSystemConfigurationArgs, 'id'>>;
  eventDetails?: Resolver<Maybe<ResolversTypes['Events']>, ParentType, ContextType, RequireFields<QueryEventDetailsArgs, 'id'>>;
  eventsBySiteId?: Resolver<Maybe<Array<Maybe<ResolversTypes['Events']>>>, ParentType, ContextType, RequireFields<QueryEventsBySiteIdArgs, 'eventType' | 'siteId'>>;
  eventsHistoryData?: Resolver<Maybe<ResolversTypes['EventHistoryResult']>, ParentType, ContextType, RequireFields<QueryEventsHistoryDataArgs, 'id'>>;
  getCustomTemplateDetails?: Resolver<Maybe<ResolversTypes['TemplateDetails']>, ParentType, ContextType, RequireFields<QueryGetCustomTemplateDetailsArgs, 'templateId'>>;
  getDeviceByDeviceId?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType, RequireFields<QueryGetDeviceByDeviceIdArgs, 'deviceId'>>;
  getInspectionById?: Resolver<Maybe<ResolversTypes['Inspection']>, ParentType, ContextType, RequireFields<QueryGetInspectionByIdArgs, 'inspectionId'>>;
  getLocationsBySiteId?: Resolver<Array<Maybe<ResolversTypes['Location']>>, ParentType, ContextType, RequireFields<QueryGetLocationsBySiteIdArgs, 'siteId'>>;
  getMasterTemplateDetails?: Resolver<Maybe<ResolversTypes['TemplateDetails']>, ParentType, ContextType, RequireFields<QueryGetMasterTemplateDetailsArgs, 'templateId'>>;
  getSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetSignedUrlArgs, 'input'>>;
  getSignedUrlForTemplateFile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetSignedUrlForTemplateFileArgs, 'templateFileName'>>;
  getSignedUrls?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryGetSignedUrlsArgs, 'input'>>;
  getSystemsBySiteId?: Resolver<Array<Maybe<ResolversTypes['System']>>, ParentType, ContextType, RequireFields<QueryGetSystemsBySiteIdArgs, 'siteId'>>;
  getTemplateAssignedTenantsList?: Resolver<Maybe<ResolversTypes['AssignedTenantsListOutput']>, ParentType, ContextType, Partial<QueryGetTemplateAssignedTenantsListArgs>>;
  getTemplatesCount?: Resolver<Maybe<ResolversTypes['TemplatesCount']>, ParentType, ContextType>;
  getTenantBusinessUnit?: Resolver<Maybe<ResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  getUserByOktaId?: Resolver<Maybe<ResolversTypes['UserInfoWithRole']>, ParentType, ContextType, RequireFields<QueryGetUserByOktaIdArgs, 'oktaId'>>;
  getUsers?: Resolver<Maybe<ResolversTypes['UsersList']>, ParentType, ContextType, Partial<QueryGetUsersArgs>>;
  inspectionDetails?: Resolver<Maybe<ResolversTypes['Inspection']>, ParentType, ContextType, RequireFields<QueryInspectionDetailsArgs, 'inspectionId'>>;
  inspectionMasterTemplates?: Resolver<Maybe<ResolversTypes['InspectionMasterTemplatesListOutput']>, ParentType, ContextType, Partial<QueryInspectionMasterTemplatesArgs>>;
  inspectionTemplates?: Resolver<Maybe<ResolversTypes['TemplatesListOutput']>, ParentType, ContextType, Partial<QueryInspectionTemplatesArgs>>;
  inspections?: Resolver<Maybe<ResolversTypes['InspectionListOutput']>, ParentType, ContextType, Partial<QueryInspectionsArgs>>;
  inspectionsByDate?: Resolver<Maybe<ResolversTypes['InspectionListOutput']>, ParentType, ContextType, RequireFields<QueryInspectionsByDateArgs, 'input'>>;
  installationDevice?: Resolver<Maybe<ResolversTypes['InstallationDevice']>, ParentType, ContextType, RequireFields<QueryInstallationDeviceArgs, 'id'>>;
  installationDevices?: Resolver<Maybe<ResolversTypes['InstallationDeviceListOutput']>, ParentType, ContextType, RequireFields<QueryInstallationDevicesArgs, 'loopId'>>;
  installationDownloadCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryInstallationDownloadCountArgs, 'loopId'>>;
  installationLoop?: Resolver<Maybe<ResolversTypes['InstallationLoop']>, ParentType, ContextType, RequireFields<QueryInstallationLoopArgs, 'loopID'>>;
  installationLoopAsCsv?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryInstallationLoopAsCsvArgs, 'loopId'>>;
  installationLoops?: Resolver<Maybe<Array<Maybe<ResolversTypes['InstallationLoop']>>>, ParentType, ContextType, RequireFields<QueryInstallationLoopsArgs, 'systemID'>>;
  installationTagNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryInstallationTagNamesArgs, 'systemId'>>;
  nodeWithSiteIdNodeId?: Resolver<ResolversTypes['NodeWithDevice'], ParentType, ContextType, RequireFields<QueryNodeWithSiteIdNodeIdArgs, 'nodeId' | 'siteId'>>;
  pumpConditionMonitoring?: Resolver<Maybe<ResolversTypes['PumpConditionMonitoring']>, ParentType, ContextType, RequireFields<QueryPumpConditionMonitoringArgs, 'input'>>;
  pushNotifications?: Resolver<Array<Maybe<ResolversTypes['PushNotification']>>, ParentType, ContextType>;
  requestLatestSystemConfiguration?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryRequestLatestSystemConfigurationArgs, 'systemId'>>;
  siteDashboardDetails?: Resolver<Maybe<ResolversTypes['SiteDashboardDetails']>, ParentType, ContextType, RequireFields<QuerySiteDashboardDetailsArgs, 'params'>>;
  siteDetails?: Resolver<Maybe<ResolversTypes['Site']>, ParentType, ContextType, RequireFields<QuerySiteDetailsArgs, 'siteId'>>;
  siteNodes?: Resolver<ResolversTypes['SiteNodesResult'], ParentType, ContextType, RequireFields<QuerySiteNodesArgs, 'siteId'>>;
  siteSystemAnalytics?: Resolver<Maybe<ResolversTypes['SiteSystemAnalytics']>, ParentType, ContextType, RequireFields<QuerySiteSystemAnalyticsArgs, 'input'>>;
  sites?: Resolver<Maybe<ResolversTypes['SiteListOutput']>, ParentType, ContextType, Partial<QuerySitesArgs>>;
  systemConfigsWithSystemId?: Resolver<Array<Maybe<ResolversTypes['SystemConfig']>>, ParentType, ContextType, RequireFields<QuerySystemConfigsWithSystemIdArgs, 'systemId'>>;
  systemWithSystemId?: Resolver<ResolversTypes['System'], ParentType, ContextType, RequireFields<QuerySystemWithSystemIdArgs, 'systemId'>>;
  systemsWithNoConnection?: Resolver<Array<Maybe<ResolversTypes['System']>>, ParentType, ContextType, RequireFields<QuerySystemsWithNoConnectionArgs, 'siteId'>>;
  systemsWithSiteIdNodeId?: Resolver<Array<Maybe<ResolversTypes['System']>>, ParentType, ContextType, RequireFields<QuerySystemsWithSiteIdNodeIdArgs, 'nodeId' | 'siteId'>>;
  templateUnAssignedTenantsList?: Resolver<Maybe<ResolversTypes['UnAssignedTenantsOutput']>, ParentType, ContextType, RequireFields<QueryTemplateUnAssignedTenantsListArgs, 'templateId'>>;
  tenantDetails?: Resolver<Maybe<ResolversTypes['Tenant']>, ParentType, ContextType, RequireFields<QueryTenantDetailsArgs, 'id'>>;
  tenantSitesStatuses?: Resolver<Maybe<ResolversTypes['TenantSitesStatuses']>, ParentType, ContextType, RequireFields<QueryTenantSitesStatusesArgs, 'tenantId'>>;
  tenants?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tenant']>>>, ParentType, ContextType>;
}>;

export type QueryStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryStatistics'] = ResolversParentTypes['QueryStatistics']> = ResolversObject<{
  pagination?: Resolver<Maybe<ResolversTypes['PaginationOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Site'] = ResolversParentTypes['Site']> = ResolversObject<{
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assignableUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['assignableUser']>>>, ParentType, ContextType>;
  assignedUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['assignedUser']>>>, ParentType, ContextType>;
  buSiteIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  businessUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contactPerson?: Resolver<Maybe<ResolversTypes['ContactPerson']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdInspectionsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['SiteStatus']>, ParentType, ContextType>;
  statusUpdatedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['Tenant']>, ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userSiteId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteDashboardDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteDashboardDetails'] = ResolversParentTypes['SiteDashboardDetails']> = ResolversObject<{
  alarmsByDate?: Resolver<Maybe<Array<Maybe<ResolversTypes['ActiveAlarmDetails']>>>, ParentType, ContextType>;
  alarmsHeatMap?: Resolver<Maybe<Array<Maybe<ResolversTypes['AlarmGraphDetails']>>>, ParentType, ContextType>;
  connectivityStatus?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConnectivityStatusDetails']>>>, ParentType, ContextType>;
  eventStatus?: Resolver<Maybe<Array<Maybe<ResolversTypes['EventStatus']>>>, ParentType, ContextType>;
  gateways?: Resolver<Maybe<ResolversTypes['GatewayDetails']>, ParentType, ContextType>;
  units?: Resolver<Maybe<ResolversTypes['UnitsDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteIssuesStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteIssuesStatistics'] = ResolversParentTypes['SiteIssuesStatistics']> = ResolversObject<{
  activeAlarmsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deviceIssuesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  disabledDetectionZoneCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  disabledDevicesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  disabledLoopsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offlineNodesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pumpUnitIssuesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  systemConnectionIssuesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalLoopsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNodeDevicesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPanelsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPumpUnitsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteListOutput'] = ResolversParentTypes['SiteListOutput']> = ResolversObject<{
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  sites?: Resolver<Array<Maybe<ResolversTypes['Site']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteNodesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteNodesResult'] = ResolversParentTypes['SiteNodesResult']> = ResolversObject<{
  nodesWithDevices?: Resolver<Maybe<Array<Maybe<ResolversTypes['NodeWithDevice']>>>, ParentType, ContextType>;
  site?: Resolver<ResolversTypes['Site'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteStatistics'] = ResolversParentTypes['SiteStatistics']> = ResolversObject<{
  inTestSitesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  needAttentionSitesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  normalSitesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sitesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteStatusDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteStatusDetail'] = ResolversParentTypes['SiteStatusDetail']> = ResolversObject<{
  contactPerson?: Resolver<Maybe<ResolversTypes['ContactPerson']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statistics?: Resolver<Maybe<ResolversTypes['SiteIssuesStatistics']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusUpdatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteStatusesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteStatusesOutput'] = ResolversParentTypes['SiteStatusesOutput']> = ResolversObject<{
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  siteStatus?: Resolver<Maybe<Array<ResolversTypes['SiteStatusDetail']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteSystemAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteSystemAnalytics'] = ResolversParentTypes['SiteSystemAnalytics']> = ResolversObject<{
  falseActivation?: Resolver<Maybe<Array<Maybe<ResolversTypes['FalseActivation']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stages'] = ResolversParentTypes['Stages']> = ResolversObject<{
  liveUpdates?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobileConnection?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  networkConfig?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  panelConfig?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StateResolvers<ContextType = any, ParentType extends ResolversParentTypes['State'] = ResolversParentTypes['State']> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stages?: Resolver<ResolversTypes['Stages'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['System'] = ResolversParentTypes['System']> = ResolversObject<{
  buSystemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deviceConfigStatus?: Resolver<ResolversTypes['ConfigStatus'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ipAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isConfigUploaded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  liveDataStatus?: Resolver<ResolversTypes['LiveDataStatus'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  networkConfigStatus?: Resolver<ResolversTypes['ConfigStatus'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['Node']>, ParentType, ContextType>;
  port?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['SystemState']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['GeneralSystemStatus']>, ParentType, ContextType>;
  systemConnection?: Resolver<Maybe<ResolversTypes['SystemConnection']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemConfig'] = ResolversParentTypes['SystemConfig']> = ResolversObject<{
  checksum?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filePath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  system?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemConnection'] = ResolversParentTypes['SystemConnection']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ipAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['Node']>, ParentType, ContextType>;
  port?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  system?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TemplateAssignedTenantResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateAssignedTenant'] = ResolversParentTypes['TemplateAssignedTenant']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TemplateDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateDetails'] = ResolversParentTypes['TemplateDetails']> = ResolversObject<{
  checklists?: Resolver<Array<ResolversTypes['InspectionCheckList']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['InspectionTemplateStatus']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['InspectionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TemplatesCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplatesCount'] = ResolversParentTypes['TemplatesCount']> = ResolversObject<{
  assignedTemplatesCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTemplatesCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TemplatesListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplatesListOutput'] = ResolversParentTypes['TemplatesListOutput']> = ResolversObject<{
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  templates?: Resolver<Array<Maybe<ResolversTypes['InspectionTemplateItem']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TenantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tenant'] = ResolversParentTypes['Tenant']> = ResolversObject<{
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contactPerson?: Resolver<Maybe<ResolversTypes['ContactPerson']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TenantSitesStatusesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantSitesStatuses'] = ResolversParentTypes['TenantSitesStatuses']> = ResolversObject<{
  siteStatistics?: Resolver<Maybe<ResolversTypes['SiteStatistics']>, ParentType, ContextType>;
  sitesStatuses?: Resolver<Maybe<ResolversTypes['SiteStatusesOutput']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['Tenant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnAssignedTenantsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnAssignedTenantsOutput'] = ResolversParentTypes['UnAssignedTenantsOutput']> = ResolversObject<{
  queryStats?: Resolver<Maybe<ResolversTypes['QueryStatistics']>, ParentType, ContextType>;
  tenants?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tenant']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnitsDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnitsDetails'] = ResolversParentTypes['UnitsDetails']> = ResolversObject<{
  activeAlarms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  areAllNotificationsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invitedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notificationSettings?: Resolver<Maybe<Array<ResolversTypes['NotificationSetting']>>, ParentType, ContextType>;
  oktaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['UserStatus']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['Tenant']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserInfoWithRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInfoWithRole'] = ResolversParentTypes['UserInfoWithRole']> = ResolversObject<{
  role?: Resolver<ResolversTypes['UserRoles'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UsersListResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersList'] = ResolversParentTypes['UsersList']> = ResolversObject<{
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfRecords?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalRecords?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssignableUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['assignableUser'] = ResolversParentTypes['assignableUser']> = ResolversObject<{
  assignedSites?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oktaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssignedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['assignedUser'] = ResolversParentTypes['assignedUser']> = ResolversObject<{
  assignedDate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oktaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  ActiveAlarmDetails?: ActiveAlarmDetailsResolvers<ContextType>;
  AlarmGraphDetails?: AlarmGraphDetailsResolvers<ContextType>;
  AssignedTenantItem?: AssignedTenantItemResolvers<ContextType>;
  AssignedTenantsListOutput?: AssignedTenantsListOutputResolvers<ContextType>;
  ConnectivityStatusDetails?: ConnectivityStatusDetailsResolvers<ContextType>;
  ContactPerson?: ContactPersonResolvers<ContextType>;
  CreateNodeWithDevice?: CreateNodeWithDeviceResolvers<ContextType>;
  CurrentUser?: CurrentUserResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  DeviceProperty?: DevicePropertyResolvers<ContextType>;
  DevicesSummary?: DevicesSummaryResolvers<ContextType>;
  EventHistoryResult?: EventHistoryResultResolvers<ContextType>;
  EventStatus?: EventStatusResolvers<ContextType>;
  Events?: EventsResolvers<ContextType>;
  EventsHistory?: EventsHistoryResolvers<ContextType>;
  FalseActivation?: FalseActivationResolvers<ContextType>;
  GatewayDetails?: GatewayDetailsResolvers<ContextType>;
  Inspection?: InspectionResolvers<ContextType>;
  InspectionCheckList?: InspectionCheckListResolvers<ContextType>;
  InspectionCheckListStep?: InspectionCheckListStepResolvers<ContextType>;
  InspectionComment?: InspectionCommentResolvers<ContextType>;
  InspectionListOutput?: InspectionListOutputResolvers<ContextType>;
  InspectionMasterTemplateItem?: InspectionMasterTemplateItemResolvers<ContextType>;
  InspectionMasterTemplatesListOutput?: InspectionMasterTemplatesListOutputResolvers<ContextType>;
  InspectionOutput?: InspectionOutputResolvers<ContextType>;
  InspectionTemplateItem?: InspectionTemplateItemResolvers<ContextType>;
  InstallationDevice?: InstallationDeviceResolvers<ContextType>;
  InstallationDeviceListOutput?: InstallationDeviceListOutputResolvers<ContextType>;
  InstallationDeviceOutput?: InstallationDeviceOutputResolvers<ContextType>;
  InstallationLoop?: InstallationLoopResolvers<ContextType>;
  InstallationLoopOutput?: InstallationLoopOutputResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Location?: LocationResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  NodeDevice?: NodeDeviceResolvers<ContextType>;
  NodeWithDevice?: NodeWithDeviceResolvers<ContextType>;
  NotificationSetting?: NotificationSettingResolvers<ContextType>;
  NotificationTypeSetting?: NotificationTypeSettingResolvers<ContextType>;
  PaginationOutput?: PaginationOutputResolvers<ContextType>;
  PressureTrend?: PressureTrendResolvers<ContextType>;
  PropertiesMap?: PropertiesMapResolvers<ContextType>;
  PumpConditionMonitoring?: PumpConditionMonitoringResolvers<ContextType>;
  PumpMonitoring?: PumpMonitoringResolvers<ContextType>;
  PumpMonitoringData?: PumpMonitoringDataResolvers<ContextType>;
  PushNotification?: PushNotificationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QueryStatistics?: QueryStatisticsResolvers<ContextType>;
  Site?: SiteResolvers<ContextType>;
  SiteDashboardDetails?: SiteDashboardDetailsResolvers<ContextType>;
  SiteIssuesStatistics?: SiteIssuesStatisticsResolvers<ContextType>;
  SiteListOutput?: SiteListOutputResolvers<ContextType>;
  SiteNodesResult?: SiteNodesResultResolvers<ContextType>;
  SiteStatistics?: SiteStatisticsResolvers<ContextType>;
  SiteStatusDetail?: SiteStatusDetailResolvers<ContextType>;
  SiteStatusesOutput?: SiteStatusesOutputResolvers<ContextType>;
  SiteSystemAnalytics?: SiteSystemAnalyticsResolvers<ContextType>;
  Stages?: StagesResolvers<ContextType>;
  State?: StateResolvers<ContextType>;
  System?: SystemResolvers<ContextType>;
  SystemConfig?: SystemConfigResolvers<ContextType>;
  SystemConnection?: SystemConnectionResolvers<ContextType>;
  TemplateAssignedTenant?: TemplateAssignedTenantResolvers<ContextType>;
  TemplateDetails?: TemplateDetailsResolvers<ContextType>;
  TemplatesCount?: TemplatesCountResolvers<ContextType>;
  TemplatesListOutput?: TemplatesListOutputResolvers<ContextType>;
  Tenant?: TenantResolvers<ContextType>;
  TenantSitesStatuses?: TenantSitesStatusesResolvers<ContextType>;
  UnAssignedTenantsOutput?: UnAssignedTenantsOutputResolvers<ContextType>;
  UnitsDetails?: UnitsDetailsResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserInfoWithRole?: UserInfoWithRoleResolvers<ContextType>;
  UsersList?: UsersListResolvers<ContextType>;
  assignableUser?: AssignableUserResolvers<ContextType>;
  assignedUser?: AssignedUserResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  relationship?: RelationshipDirectiveResolver<any, any, ContextType>;
}>;


export const DismissAllNotificationsDocument = gql`
    mutation DismissAllNotifications {
  dismissAllNotifications
}
    `;
export type DismissAllNotificationsMutationFn = Apollo.MutationFunction<DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables>;
export type DismissAllNotificationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables>, 'mutation'>;

    export const DismissAllNotificationsComponent = (props: DismissAllNotificationsComponentProps) => (
      <ApolloReactComponents.Mutation<DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables> mutation={DismissAllNotificationsDocument} {...props} />
    );
    
export type DismissAllNotificationsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables>
    } & TChildProps;
export function withDismissAllNotifications<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DismissAllNotificationsMutation,
  DismissAllNotificationsMutationVariables,
  DismissAllNotificationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables, DismissAllNotificationsProps<TChildProps, TDataName>>(DismissAllNotificationsDocument, {
      alias: 'dismissAllNotifications',
      ...operationOptions
    });
};

/**
 * __useDismissAllNotificationsMutation__
 *
 * To run a mutation, you first call `useDismissAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissAllNotificationsMutation, { data, loading, error }] = useDismissAllNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDismissAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables>(DismissAllNotificationsDocument, options);
      }
export type DismissAllNotificationsMutationHookResult = ReturnType<typeof useDismissAllNotificationsMutation>;
export type DismissAllNotificationsMutationResult = Apollo.MutationResult<DismissAllNotificationsMutation>;
export type DismissAllNotificationsMutationOptions = Apollo.BaseMutationOptions<DismissAllNotificationsMutation, DismissAllNotificationsMutationVariables>;
export const DismissNotificationDocument = gql`
    mutation dismissNotification($id: String!) {
  dismissNotification(id: $id)
}
    `;
export type DismissNotificationMutationFn = Apollo.MutationFunction<DismissNotificationMutation, DismissNotificationMutationVariables>;
export type DismissNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DismissNotificationMutation, DismissNotificationMutationVariables>, 'mutation'>;

    export const DismissNotificationComponent = (props: DismissNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<DismissNotificationMutation, DismissNotificationMutationVariables> mutation={DismissNotificationDocument} {...props} />
    );
    
export type DismissNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DismissNotificationMutation, DismissNotificationMutationVariables>
    } & TChildProps;
export function withDismissNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DismissNotificationMutation,
  DismissNotificationMutationVariables,
  DismissNotificationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DismissNotificationMutation, DismissNotificationMutationVariables, DismissNotificationProps<TChildProps, TDataName>>(DismissNotificationDocument, {
      alias: 'dismissNotification',
      ...operationOptions
    });
};

/**
 * __useDismissNotificationMutation__
 *
 * To run a mutation, you first call `useDismissNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissNotificationMutation, { data, loading, error }] = useDismissNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DismissNotificationMutation, DismissNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissNotificationMutation, DismissNotificationMutationVariables>(DismissNotificationDocument, options);
      }
export type DismissNotificationMutationHookResult = ReturnType<typeof useDismissNotificationMutation>;
export type DismissNotificationMutationResult = Apollo.MutationResult<DismissNotificationMutation>;
export type DismissNotificationMutationOptions = Apollo.BaseMutationOptions<DismissNotificationMutation, DismissNotificationMutationVariables>;
export const PushNotificationsDocument = gql`
    query PushNotifications {
  pushNotifications {
    id
    title
    status
    type
    businessUnit
    propertiesMap {
      key
      value
    }
    clickAction
    resourceId
    deepLink
    createdAt
    description
  }
}
    `;
export type PushNotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PushNotificationsQuery, PushNotificationsQueryVariables>, 'query'>;

    export const PushNotificationsComponent = (props: PushNotificationsComponentProps) => (
      <ApolloReactComponents.Query<PushNotificationsQuery, PushNotificationsQueryVariables> query={PushNotificationsDocument} {...props} />
    );
    
export type PushNotificationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PushNotificationsQuery, PushNotificationsQueryVariables>
    } & TChildProps;
export function withPushNotifications<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PushNotificationsQuery,
  PushNotificationsQueryVariables,
  PushNotificationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PushNotificationsQuery, PushNotificationsQueryVariables, PushNotificationsProps<TChildProps, TDataName>>(PushNotificationsDocument, {
      alias: 'pushNotifications',
      ...operationOptions
    });
};

/**
 * __usePushNotificationsQuery__
 *
 * To run a query within a React component, call `usePushNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePushNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePushNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<PushNotificationsQuery, PushNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PushNotificationsQuery, PushNotificationsQueryVariables>(PushNotificationsDocument, options);
      }
export function usePushNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PushNotificationsQuery, PushNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PushNotificationsQuery, PushNotificationsQueryVariables>(PushNotificationsDocument, options);
        }
export type PushNotificationsQueryHookResult = ReturnType<typeof usePushNotificationsQuery>;
export type PushNotificationsLazyQueryHookResult = ReturnType<typeof usePushNotificationsLazyQuery>;
export type PushNotificationsQueryResult = Apollo.QueryResult<PushNotificationsQuery, PushNotificationsQueryVariables>;
export function refetchPushNotificationsQuery(variables?: PushNotificationsQueryVariables) {
      return { query: PushNotificationsDocument, variables: variables }
    }
export const ReadAllNotificationsDocument = gql`
    mutation ReadAllNotifications {
  readAllNotifications
}
    `;
export type ReadAllNotificationsMutationFn = Apollo.MutationFunction<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;
export type ReadAllNotificationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>, 'mutation'>;

    export const ReadAllNotificationsComponent = (props: ReadAllNotificationsComponentProps) => (
      <ApolloReactComponents.Mutation<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables> mutation={ReadAllNotificationsDocument} {...props} />
    );
    
export type ReadAllNotificationsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>
    } & TChildProps;
export function withReadAllNotifications<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReadAllNotificationsMutation,
  ReadAllNotificationsMutationVariables,
  ReadAllNotificationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables, ReadAllNotificationsProps<TChildProps, TDataName>>(ReadAllNotificationsDocument, {
      alias: 'readAllNotifications',
      ...operationOptions
    });
};

/**
 * __useReadAllNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotificationsMutation, { data, loading, error }] = useReadAllNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>(ReadAllNotificationsDocument, options);
      }
export type ReadAllNotificationsMutationHookResult = ReturnType<typeof useReadAllNotificationsMutation>;
export type ReadAllNotificationsMutationResult = Apollo.MutationResult<ReadAllNotificationsMutation>;
export type ReadAllNotificationsMutationOptions = Apollo.BaseMutationOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation readNotification($id: String!) {
  readNotification(id: $id)
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;
export type ReadNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReadNotificationMutation, ReadNotificationMutationVariables>, 'mutation'>;

    export const ReadNotificationComponent = (props: ReadNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<ReadNotificationMutation, ReadNotificationMutationVariables> mutation={ReadNotificationDocument} {...props} />
    );
    
export type ReadNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>
    } & TChildProps;
export function withReadNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReadNotificationMutation,
  ReadNotificationMutationVariables,
  ReadNotificationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ReadNotificationMutation, ReadNotificationMutationVariables, ReadNotificationProps<TChildProps, TDataName>>(ReadNotificationDocument, {
      alias: 'readNotification',
      ...operationOptions
    });
};

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    user {
      id
      oktaId
      emailAddress
      firstName
      lastName
      phoneNumber
      preferredLanguage
      status
      updatedBy
      updatedAt
      createdBy
      createdAt
      invitedAt
      areAllNotificationsEnabled
      role
      tenant {
        id
        name
        address1
        address2
        postalCode
        country
        city
        contactPerson {
          firstName
          lastName
          phoneNumber
          email
        }
        createdAt
      }
      notificationSettings {
        id
        businessUnit
        areNotificationsEnabled
        notificationTypeSettings {
          id
          type
          isPushEnabled
          isEmailEnabled
        }
      }
    }
    permissions
  }
}
    `;
export type CurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentUserQuery, CurrentUserQueryVariables>, 'query'>;

    export const CurrentUserComponent = (props: CurrentUserComponentProps) => (
      <ApolloReactComponents.Query<CurrentUserQuery, CurrentUserQueryVariables> query={CurrentUserDocument} {...props} />
    );
    
export type CurrentUserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CurrentUserQuery, CurrentUserQueryVariables>
    } & TChildProps;
export function withCurrentUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  CurrentUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CurrentUserQuery, CurrentUserQueryVariables, CurrentUserProps<TChildProps, TDataName>>(CurrentUserDocument, {
      alias: 'currentUser',
      ...operationOptions
    });
};

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export function refetchCurrentUserQuery(variables?: CurrentUserQueryVariables) {
      return { query: CurrentUserDocument, variables: variables }
    }
export const DeviceRegistrationDocument = gql`
    mutation DeviceRegistration($deviceDetails: DeviceRegistration!) {
  registerDevice(deviceDetails: $deviceDetails)
}
    `;
export type DeviceRegistrationMutationFn = Apollo.MutationFunction<DeviceRegistrationMutation, DeviceRegistrationMutationVariables>;
export type DeviceRegistrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeviceRegistrationMutation, DeviceRegistrationMutationVariables>, 'mutation'>;

    export const DeviceRegistrationComponent = (props: DeviceRegistrationComponentProps) => (
      <ApolloReactComponents.Mutation<DeviceRegistrationMutation, DeviceRegistrationMutationVariables> mutation={DeviceRegistrationDocument} {...props} />
    );
    
export type DeviceRegistrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeviceRegistrationMutation, DeviceRegistrationMutationVariables>
    } & TChildProps;
export function withDeviceRegistration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeviceRegistrationMutation,
  DeviceRegistrationMutationVariables,
  DeviceRegistrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeviceRegistrationMutation, DeviceRegistrationMutationVariables, DeviceRegistrationProps<TChildProps, TDataName>>(DeviceRegistrationDocument, {
      alias: 'deviceRegistration',
      ...operationOptions
    });
};

/**
 * __useDeviceRegistrationMutation__
 *
 * To run a mutation, you first call `useDeviceRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceRegistrationMutation, { data, loading, error }] = useDeviceRegistrationMutation({
 *   variables: {
 *      deviceDetails: // value for 'deviceDetails'
 *   },
 * });
 */
export function useDeviceRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<DeviceRegistrationMutation, DeviceRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceRegistrationMutation, DeviceRegistrationMutationVariables>(DeviceRegistrationDocument, options);
      }
export type DeviceRegistrationMutationHookResult = ReturnType<typeof useDeviceRegistrationMutation>;
export type DeviceRegistrationMutationResult = Apollo.MutationResult<DeviceRegistrationMutation>;
export type DeviceRegistrationMutationOptions = Apollo.BaseMutationOptions<DeviceRegistrationMutation, DeviceRegistrationMutationVariables>;
export const UnRegisterDeviceDocument = gql`
    mutation UnRegisterDevice($token: String!) {
  unRegisterDevice(token: $token)
}
    `;
export type UnRegisterDeviceMutationFn = Apollo.MutationFunction<UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables>;
export type UnRegisterDeviceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables>, 'mutation'>;

    export const UnRegisterDeviceComponent = (props: UnRegisterDeviceComponentProps) => (
      <ApolloReactComponents.Mutation<UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables> mutation={UnRegisterDeviceDocument} {...props} />
    );
    
export type UnRegisterDeviceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables>
    } & TChildProps;
export function withUnRegisterDevice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnRegisterDeviceMutation,
  UnRegisterDeviceMutationVariables,
  UnRegisterDeviceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables, UnRegisterDeviceProps<TChildProps, TDataName>>(UnRegisterDeviceDocument, {
      alias: 'unRegisterDevice',
      ...operationOptions
    });
};

/**
 * __useUnRegisterDeviceMutation__
 *
 * To run a mutation, you first call `useUnRegisterDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnRegisterDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unRegisterDeviceMutation, { data, loading, error }] = useUnRegisterDeviceMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUnRegisterDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables>(UnRegisterDeviceDocument, options);
      }
export type UnRegisterDeviceMutationHookResult = ReturnType<typeof useUnRegisterDeviceMutation>;
export type UnRegisterDeviceMutationResult = Apollo.MutationResult<UnRegisterDeviceMutation>;
export type UnRegisterDeviceMutationOptions = Apollo.BaseMutationOptions<UnRegisterDeviceMutation, UnRegisterDeviceMutationVariables>;
export const GetTemplatesCountDocument = gql`
    query GetTemplatesCount {
  getTemplatesCount {
    totalTemplatesCount
    assignedTemplatesCount
  }
}
    `;
export type GetTemplatesCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplatesCountQuery, GetTemplatesCountQueryVariables>, 'query'>;

    export const GetTemplatesCountComponent = (props: GetTemplatesCountComponentProps) => (
      <ApolloReactComponents.Query<GetTemplatesCountQuery, GetTemplatesCountQueryVariables> query={GetTemplatesCountDocument} {...props} />
    );
    
export type GetTemplatesCountProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTemplatesCountQuery, GetTemplatesCountQueryVariables>
    } & TChildProps;
export function withGetTemplatesCount<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplatesCountQuery,
  GetTemplatesCountQueryVariables,
  GetTemplatesCountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplatesCountQuery, GetTemplatesCountQueryVariables, GetTemplatesCountProps<TChildProps, TDataName>>(GetTemplatesCountDocument, {
      alias: 'getTemplatesCount',
      ...operationOptions
    });
};

/**
 * __useGetTemplatesCountQuery__
 *
 * To run a query within a React component, call `useGetTemplatesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplatesCountQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplatesCountQuery, GetTemplatesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplatesCountQuery, GetTemplatesCountQueryVariables>(GetTemplatesCountDocument, options);
      }
export function useGetTemplatesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesCountQuery, GetTemplatesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplatesCountQuery, GetTemplatesCountQueryVariables>(GetTemplatesCountDocument, options);
        }
export type GetTemplatesCountQueryHookResult = ReturnType<typeof useGetTemplatesCountQuery>;
export type GetTemplatesCountLazyQueryHookResult = ReturnType<typeof useGetTemplatesCountLazyQuery>;
export type GetTemplatesCountQueryResult = Apollo.QueryResult<GetTemplatesCountQuery, GetTemplatesCountQueryVariables>;
export function refetchGetTemplatesCountQuery(variables?: GetTemplatesCountQueryVariables) {
      return { query: GetTemplatesCountDocument, variables: variables }
    }
export const GetTemplateAssignedTenantsListDocument = gql`
    query GetTemplateAssignedTenantsList($queryParams: QueryParameters) {
  getTemplateAssignedTenantsList(queryParams: $queryParams) {
    assignedTenantsList {
      count
      tenant {
        id
        name
      }
    }
    queryStats {
      pagination {
        totalRecords
        currentPage
        totalPages
        nextPage
        prevPage
      }
    }
  }
}
    `;
export type GetTemplateAssignedTenantsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables>, 'query'>;

    export const GetTemplateAssignedTenantsListComponent = (props: GetTemplateAssignedTenantsListComponentProps) => (
      <ApolloReactComponents.Query<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables> query={GetTemplateAssignedTenantsListDocument} {...props} />
    );
    
export type GetTemplateAssignedTenantsListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables>
    } & TChildProps;
export function withGetTemplateAssignedTenantsList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplateAssignedTenantsListQuery,
  GetTemplateAssignedTenantsListQueryVariables,
  GetTemplateAssignedTenantsListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables, GetTemplateAssignedTenantsListProps<TChildProps, TDataName>>(GetTemplateAssignedTenantsListDocument, {
      alias: 'getTemplateAssignedTenantsList',
      ...operationOptions
    });
};

/**
 * __useGetTemplateAssignedTenantsListQuery__
 *
 * To run a query within a React component, call `useGetTemplateAssignedTenantsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateAssignedTenantsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateAssignedTenantsListQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGetTemplateAssignedTenantsListQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables>(GetTemplateAssignedTenantsListDocument, options);
      }
export function useGetTemplateAssignedTenantsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables>(GetTemplateAssignedTenantsListDocument, options);
        }
export type GetTemplateAssignedTenantsListQueryHookResult = ReturnType<typeof useGetTemplateAssignedTenantsListQuery>;
export type GetTemplateAssignedTenantsListLazyQueryHookResult = ReturnType<typeof useGetTemplateAssignedTenantsListLazyQuery>;
export type GetTemplateAssignedTenantsListQueryResult = Apollo.QueryResult<GetTemplateAssignedTenantsListQuery, GetTemplateAssignedTenantsListQueryVariables>;
export function refetchGetTemplateAssignedTenantsListQuery(variables?: GetTemplateAssignedTenantsListQueryVariables) {
      return { query: GetTemplateAssignedTenantsListDocument, variables: variables }
    }
export const UnassignMasterTemplatesDocument = gql`
    mutation UnassignMasterTemplates($templateIds: [String!]!, $tenantId: String!) {
  unassignMasterTemplates(templateIds: $templateIds, tenantId: $tenantId)
}
    `;
export type UnassignMasterTemplatesMutationFn = Apollo.MutationFunction<UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables>;
export type UnassignMasterTemplatesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables>, 'mutation'>;

    export const UnassignMasterTemplatesComponent = (props: UnassignMasterTemplatesComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables> mutation={UnassignMasterTemplatesDocument} {...props} />
    );
    
export type UnassignMasterTemplatesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables>
    } & TChildProps;
export function withUnassignMasterTemplates<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignMasterTemplatesMutation,
  UnassignMasterTemplatesMutationVariables,
  UnassignMasterTemplatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables, UnassignMasterTemplatesProps<TChildProps, TDataName>>(UnassignMasterTemplatesDocument, {
      alias: 'unassignMasterTemplates',
      ...operationOptions
    });
};

/**
 * __useUnassignMasterTemplatesMutation__
 *
 * To run a mutation, you first call `useUnassignMasterTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignMasterTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignMasterTemplatesMutation, { data, loading, error }] = useUnassignMasterTemplatesMutation({
 *   variables: {
 *      templateIds: // value for 'templateIds'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useUnassignMasterTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables>(UnassignMasterTemplatesDocument, options);
      }
export type UnassignMasterTemplatesMutationHookResult = ReturnType<typeof useUnassignMasterTemplatesMutation>;
export type UnassignMasterTemplatesMutationResult = Apollo.MutationResult<UnassignMasterTemplatesMutation>;
export type UnassignMasterTemplatesMutationOptions = Apollo.BaseMutationOptions<UnassignMasterTemplatesMutation, UnassignMasterTemplatesMutationVariables>;
export const GetInspectionMasterTemplatesDocument = gql`
    query GetInspectionMasterTemplates($queryParams: QueryParameters, $tenantId: String, $status: InspectionTemplateStatus) {
  inspectionMasterTemplates(
    queryParams: $queryParams
    tenantId: $tenantId
    status: $status
  ) {
    inspectionMasterTemplates {
      id
      name
      type
      status
      createdAt
      createdBy {
        id
        firstName
        lastName
        role
      }
      archivedBy {
        id
        firstName
        lastName
        role
      }
      archivedAt
      assignedTenants {
        id
        name
      }
    }
    queryStats {
      pagination {
        totalRecords
        currentPage
        totalPages
        nextPage
        prevPage
        filteredRecords
      }
    }
  }
}
    `;
export type GetInspectionMasterTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables>, 'query'>;

    export const GetInspectionMasterTemplatesComponent = (props: GetInspectionMasterTemplatesComponentProps) => (
      <ApolloReactComponents.Query<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables> query={GetInspectionMasterTemplatesDocument} {...props} />
    );
    
export type GetInspectionMasterTemplatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables>
    } & TChildProps;
export function withGetInspectionMasterTemplates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspectionMasterTemplatesQuery,
  GetInspectionMasterTemplatesQueryVariables,
  GetInspectionMasterTemplatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables, GetInspectionMasterTemplatesProps<TChildProps, TDataName>>(GetInspectionMasterTemplatesDocument, {
      alias: 'getInspectionMasterTemplates',
      ...operationOptions
    });
};

/**
 * __useGetInspectionMasterTemplatesQuery__
 *
 * To run a query within a React component, call `useGetInspectionMasterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionMasterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionMasterTemplatesQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *      tenantId: // value for 'tenantId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetInspectionMasterTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables>(GetInspectionMasterTemplatesDocument, options);
      }
export function useGetInspectionMasterTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables>(GetInspectionMasterTemplatesDocument, options);
        }
export type GetInspectionMasterTemplatesQueryHookResult = ReturnType<typeof useGetInspectionMasterTemplatesQuery>;
export type GetInspectionMasterTemplatesLazyQueryHookResult = ReturnType<typeof useGetInspectionMasterTemplatesLazyQuery>;
export type GetInspectionMasterTemplatesQueryResult = Apollo.QueryResult<GetInspectionMasterTemplatesQuery, GetInspectionMasterTemplatesQueryVariables>;
export function refetchGetInspectionMasterTemplatesQuery(variables?: GetInspectionMasterTemplatesQueryVariables) {
      return { query: GetInspectionMasterTemplatesDocument, variables: variables }
    }
export const ArchiveMasterTemplateDocument = gql`
    mutation ArchiveMasterTemplate($templateId: String!, $archiveReason: String!) {
  archiveMasterTemplate(templateId: $templateId, archiveReason: $archiveReason)
}
    `;
export type ArchiveMasterTemplateMutationFn = Apollo.MutationFunction<ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables>;
export type ArchiveMasterTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables>, 'mutation'>;

    export const ArchiveMasterTemplateComponent = (props: ArchiveMasterTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables> mutation={ArchiveMasterTemplateDocument} {...props} />
    );
    
export type ArchiveMasterTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables>
    } & TChildProps;
export function withArchiveMasterTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArchiveMasterTemplateMutation,
  ArchiveMasterTemplateMutationVariables,
  ArchiveMasterTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables, ArchiveMasterTemplateProps<TChildProps, TDataName>>(ArchiveMasterTemplateDocument, {
      alias: 'archiveMasterTemplate',
      ...operationOptions
    });
};

/**
 * __useArchiveMasterTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveMasterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMasterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMasterTemplateMutation, { data, loading, error }] = useArchiveMasterTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      archiveReason: // value for 'archiveReason'
 *   },
 * });
 */
export function useArchiveMasterTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables>(ArchiveMasterTemplateDocument, options);
      }
export type ArchiveMasterTemplateMutationHookResult = ReturnType<typeof useArchiveMasterTemplateMutation>;
export type ArchiveMasterTemplateMutationResult = Apollo.MutationResult<ArchiveMasterTemplateMutation>;
export type ArchiveMasterTemplateMutationOptions = Apollo.BaseMutationOptions<ArchiveMasterTemplateMutation, ArchiveMasterTemplateMutationVariables>;
export const AssignMasterTemplateToTenantsDocument = gql`
    mutation AssignMasterTemplateToTenants($templateId: String!, $tenantIds: [String!]!) {
  assignMasterTemplateToTenants(templateId: $templateId, tenantIds: $tenantIds)
}
    `;
export type AssignMasterTemplateToTenantsMutationFn = Apollo.MutationFunction<AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables>;
export type AssignMasterTemplateToTenantsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables>, 'mutation'>;

    export const AssignMasterTemplateToTenantsComponent = (props: AssignMasterTemplateToTenantsComponentProps) => (
      <ApolloReactComponents.Mutation<AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables> mutation={AssignMasterTemplateToTenantsDocument} {...props} />
    );
    
export type AssignMasterTemplateToTenantsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables>
    } & TChildProps;
export function withAssignMasterTemplateToTenants<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignMasterTemplateToTenantsMutation,
  AssignMasterTemplateToTenantsMutationVariables,
  AssignMasterTemplateToTenantsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables, AssignMasterTemplateToTenantsProps<TChildProps, TDataName>>(AssignMasterTemplateToTenantsDocument, {
      alias: 'assignMasterTemplateToTenants',
      ...operationOptions
    });
};

/**
 * __useAssignMasterTemplateToTenantsMutation__
 *
 * To run a mutation, you first call `useAssignMasterTemplateToTenantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignMasterTemplateToTenantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignMasterTemplateToTenantsMutation, { data, loading, error }] = useAssignMasterTemplateToTenantsMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      tenantIds: // value for 'tenantIds'
 *   },
 * });
 */
export function useAssignMasterTemplateToTenantsMutation(baseOptions?: Apollo.MutationHookOptions<AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables>(AssignMasterTemplateToTenantsDocument, options);
      }
export type AssignMasterTemplateToTenantsMutationHookResult = ReturnType<typeof useAssignMasterTemplateToTenantsMutation>;
export type AssignMasterTemplateToTenantsMutationResult = Apollo.MutationResult<AssignMasterTemplateToTenantsMutation>;
export type AssignMasterTemplateToTenantsMutationOptions = Apollo.BaseMutationOptions<AssignMasterTemplateToTenantsMutation, AssignMasterTemplateToTenantsMutationVariables>;
export const GetMasterTemplateBasicDetailsDocument = gql`
    query GetMasterTemplateBasicDetails($templateId: String!) {
  getMasterTemplateDetails(templateId: $templateId) {
    id
    name
    type
  }
}
    `;
export type GetMasterTemplateBasicDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables>, 'query'> & ({ variables: GetMasterTemplateBasicDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMasterTemplateBasicDetailsComponent = (props: GetMasterTemplateBasicDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables> query={GetMasterTemplateBasicDetailsDocument} {...props} />
    );
    
export type GetMasterTemplateBasicDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables>
    } & TChildProps;
export function withGetMasterTemplateBasicDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMasterTemplateBasicDetailsQuery,
  GetMasterTemplateBasicDetailsQueryVariables,
  GetMasterTemplateBasicDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables, GetMasterTemplateBasicDetailsProps<TChildProps, TDataName>>(GetMasterTemplateBasicDetailsDocument, {
      alias: 'getMasterTemplateBasicDetails',
      ...operationOptions
    });
};

/**
 * __useGetMasterTemplateBasicDetailsQuery__
 *
 * To run a query within a React component, call `useGetMasterTemplateBasicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterTemplateBasicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterTemplateBasicDetailsQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetMasterTemplateBasicDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables>(GetMasterTemplateBasicDetailsDocument, options);
      }
export function useGetMasterTemplateBasicDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables>(GetMasterTemplateBasicDetailsDocument, options);
        }
export type GetMasterTemplateBasicDetailsQueryHookResult = ReturnType<typeof useGetMasterTemplateBasicDetailsQuery>;
export type GetMasterTemplateBasicDetailsLazyQueryHookResult = ReturnType<typeof useGetMasterTemplateBasicDetailsLazyQuery>;
export type GetMasterTemplateBasicDetailsQueryResult = Apollo.QueryResult<GetMasterTemplateBasicDetailsQuery, GetMasterTemplateBasicDetailsQueryVariables>;
export function refetchGetMasterTemplateBasicDetailsQuery(variables: GetMasterTemplateBasicDetailsQueryVariables) {
      return { query: GetMasterTemplateBasicDetailsDocument, variables: variables }
    }
export const GetTemplateUnAssignedTenantsListDocument = gql`
    query GetTemplateUnAssignedTenantsList($templateId: ID!, $queryParams: QueryParameters) {
  templateUnAssignedTenantsList(
    templateId: $templateId
    queryParams: $queryParams
  ) {
    tenants {
      id
      name
      address1
      address2
      postalCode
      country
      city
      contactPerson {
        firstName
        lastName
        phoneNumber
        email
      }
      createdAt
    }
    queryStats {
      pagination {
        totalRecords
        currentPage
        totalPages
        nextPage
        prevPage
      }
    }
  }
}
    `;
export type GetTemplateUnAssignedTenantsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables>, 'query'> & ({ variables: GetTemplateUnAssignedTenantsListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTemplateUnAssignedTenantsListComponent = (props: GetTemplateUnAssignedTenantsListComponentProps) => (
      <ApolloReactComponents.Query<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables> query={GetTemplateUnAssignedTenantsListDocument} {...props} />
    );
    
export type GetTemplateUnAssignedTenantsListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables>
    } & TChildProps;
export function withGetTemplateUnAssignedTenantsList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplateUnAssignedTenantsListQuery,
  GetTemplateUnAssignedTenantsListQueryVariables,
  GetTemplateUnAssignedTenantsListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables, GetTemplateUnAssignedTenantsListProps<TChildProps, TDataName>>(GetTemplateUnAssignedTenantsListDocument, {
      alias: 'getTemplateUnAssignedTenantsList',
      ...operationOptions
    });
};

/**
 * __useGetTemplateUnAssignedTenantsListQuery__
 *
 * To run a query within a React component, call `useGetTemplateUnAssignedTenantsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateUnAssignedTenantsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateUnAssignedTenantsListQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGetTemplateUnAssignedTenantsListQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables>(GetTemplateUnAssignedTenantsListDocument, options);
      }
export function useGetTemplateUnAssignedTenantsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables>(GetTemplateUnAssignedTenantsListDocument, options);
        }
export type GetTemplateUnAssignedTenantsListQueryHookResult = ReturnType<typeof useGetTemplateUnAssignedTenantsListQuery>;
export type GetTemplateUnAssignedTenantsListLazyQueryHookResult = ReturnType<typeof useGetTemplateUnAssignedTenantsListLazyQuery>;
export type GetTemplateUnAssignedTenantsListQueryResult = Apollo.QueryResult<GetTemplateUnAssignedTenantsListQuery, GetTemplateUnAssignedTenantsListQueryVariables>;
export function refetchGetTemplateUnAssignedTenantsListQuery(variables: GetTemplateUnAssignedTenantsListQueryVariables) {
      return { query: GetTemplateUnAssignedTenantsListDocument, variables: variables }
    }
export const CreateMasterTemplateDocument = gql`
    mutation CreateMasterTemplate($template: InspectionTemplateInput!) {
  createMasterTemplate(template: $template)
}
    `;
export type CreateMasterTemplateMutationFn = Apollo.MutationFunction<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables>;
export type CreateMasterTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables>, 'mutation'>;

    export const CreateMasterTemplateComponent = (props: CreateMasterTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables> mutation={CreateMasterTemplateDocument} {...props} />
    );
    
export type CreateMasterTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables>
    } & TChildProps;
export function withCreateMasterTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateMasterTemplateMutation,
  CreateMasterTemplateMutationVariables,
  CreateMasterTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables, CreateMasterTemplateProps<TChildProps, TDataName>>(CreateMasterTemplateDocument, {
      alias: 'createMasterTemplate',
      ...operationOptions
    });
};

/**
 * __useCreateMasterTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMasterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMasterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMasterTemplateMutation, { data, loading, error }] = useCreateMasterTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useCreateMasterTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables>(CreateMasterTemplateDocument, options);
      }
export type CreateMasterTemplateMutationHookResult = ReturnType<typeof useCreateMasterTemplateMutation>;
export type CreateMasterTemplateMutationResult = Apollo.MutationResult<CreateMasterTemplateMutation>;
export type CreateMasterTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables>;
export const UpdateMasterTemplateDocument = gql`
    mutation UpdateMasterTemplate($templateId: String!, $template: InspectionTemplateInput!) {
  updateMasterTemplate(templateId: $templateId, template: $template)
}
    `;
export type UpdateMasterTemplateMutationFn = Apollo.MutationFunction<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables>;
export type UpdateMasterTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables>, 'mutation'>;

    export const UpdateMasterTemplateComponent = (props: UpdateMasterTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables> mutation={UpdateMasterTemplateDocument} {...props} />
    );
    
export type UpdateMasterTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables>
    } & TChildProps;
export function withUpdateMasterTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateMasterTemplateMutation,
  UpdateMasterTemplateMutationVariables,
  UpdateMasterTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables, UpdateMasterTemplateProps<TChildProps, TDataName>>(UpdateMasterTemplateDocument, {
      alias: 'updateMasterTemplate',
      ...operationOptions
    });
};

/**
 * __useUpdateMasterTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMasterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterTemplateMutation, { data, loading, error }] = useUpdateMasterTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useUpdateMasterTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables>(UpdateMasterTemplateDocument, options);
      }
export type UpdateMasterTemplateMutationHookResult = ReturnType<typeof useUpdateMasterTemplateMutation>;
export type UpdateMasterTemplateMutationResult = Apollo.MutationResult<UpdateMasterTemplateMutation>;
export type UpdateMasterTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables>;
export const GetCustomTemplateDetailsDocument = gql`
    query GetCustomTemplateDetails($templateId: String!) {
  getCustomTemplateDetails(templateId: $templateId) {
    id
    name
    type
    status
    createdAt
    checklists {
      id
      name
      title
      isChecked
      steps {
        id
        title
        name
        mediaFilePath
        description
      }
    }
  }
}
    `;
export type GetCustomTemplateDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables>, 'query'> & ({ variables: GetCustomTemplateDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCustomTemplateDetailsComponent = (props: GetCustomTemplateDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables> query={GetCustomTemplateDetailsDocument} {...props} />
    );
    
export type GetCustomTemplateDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables>
    } & TChildProps;
export function withGetCustomTemplateDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCustomTemplateDetailsQuery,
  GetCustomTemplateDetailsQueryVariables,
  GetCustomTemplateDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables, GetCustomTemplateDetailsProps<TChildProps, TDataName>>(GetCustomTemplateDetailsDocument, {
      alias: 'getCustomTemplateDetails',
      ...operationOptions
    });
};

/**
 * __useGetCustomTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useGetCustomTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTemplateDetailsQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetCustomTemplateDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables>(GetCustomTemplateDetailsDocument, options);
      }
export function useGetCustomTemplateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables>(GetCustomTemplateDetailsDocument, options);
        }
export type GetCustomTemplateDetailsQueryHookResult = ReturnType<typeof useGetCustomTemplateDetailsQuery>;
export type GetCustomTemplateDetailsLazyQueryHookResult = ReturnType<typeof useGetCustomTemplateDetailsLazyQuery>;
export type GetCustomTemplateDetailsQueryResult = Apollo.QueryResult<GetCustomTemplateDetailsQuery, GetCustomTemplateDetailsQueryVariables>;
export function refetchGetCustomTemplateDetailsQuery(variables: GetCustomTemplateDetailsQueryVariables) {
      return { query: GetCustomTemplateDetailsDocument, variables: variables }
    }
export const GetMasterTemplateDetailsDocument = gql`
    query GetMasterTemplateDetails($templateId: String!) {
  getMasterTemplateDetails(templateId: $templateId) {
    id
    name
    type
    status
    createdAt
    checklists {
      id
      name
      title
      isChecked
      steps {
        id
        title
        name
        mediaFilePath
        description
      }
    }
  }
}
    `;
export type GetMasterTemplateDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables>, 'query'> & ({ variables: GetMasterTemplateDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMasterTemplateDetailsComponent = (props: GetMasterTemplateDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables> query={GetMasterTemplateDetailsDocument} {...props} />
    );
    
export type GetMasterTemplateDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables>
    } & TChildProps;
export function withGetMasterTemplateDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMasterTemplateDetailsQuery,
  GetMasterTemplateDetailsQueryVariables,
  GetMasterTemplateDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables, GetMasterTemplateDetailsProps<TChildProps, TDataName>>(GetMasterTemplateDetailsDocument, {
      alias: 'getMasterTemplateDetails',
      ...operationOptions
    });
};

/**
 * __useGetMasterTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useGetMasterTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterTemplateDetailsQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetMasterTemplateDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables>(GetMasterTemplateDetailsDocument, options);
      }
export function useGetMasterTemplateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables>(GetMasterTemplateDetailsDocument, options);
        }
export type GetMasterTemplateDetailsQueryHookResult = ReturnType<typeof useGetMasterTemplateDetailsQuery>;
export type GetMasterTemplateDetailsLazyQueryHookResult = ReturnType<typeof useGetMasterTemplateDetailsLazyQuery>;
export type GetMasterTemplateDetailsQueryResult = Apollo.QueryResult<GetMasterTemplateDetailsQuery, GetMasterTemplateDetailsQueryVariables>;
export function refetchGetMasterTemplateDetailsQuery(variables: GetMasterTemplateDetailsQueryVariables) {
      return { query: GetMasterTemplateDetailsDocument, variables: variables }
    }
export const GetBusinessUnitFromTenantDocument = gql`
    query GetBusinessUnitFromTenant {
  getTenantBusinessUnit
}
    `;
export type GetBusinessUnitFromTenantComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables>, 'query'>;

    export const GetBusinessUnitFromTenantComponent = (props: GetBusinessUnitFromTenantComponentProps) => (
      <ApolloReactComponents.Query<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables> query={GetBusinessUnitFromTenantDocument} {...props} />
    );
    
export type GetBusinessUnitFromTenantProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables>
    } & TChildProps;
export function withGetBusinessUnitFromTenant<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBusinessUnitFromTenantQuery,
  GetBusinessUnitFromTenantQueryVariables,
  GetBusinessUnitFromTenantProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables, GetBusinessUnitFromTenantProps<TChildProps, TDataName>>(GetBusinessUnitFromTenantDocument, {
      alias: 'getBusinessUnitFromTenant',
      ...operationOptions
    });
};

/**
 * __useGetBusinessUnitFromTenantQuery__
 *
 * To run a query within a React component, call `useGetBusinessUnitFromTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessUnitFromTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessUnitFromTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessUnitFromTenantQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables>(GetBusinessUnitFromTenantDocument, options);
      }
export function useGetBusinessUnitFromTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables>(GetBusinessUnitFromTenantDocument, options);
        }
export type GetBusinessUnitFromTenantQueryHookResult = ReturnType<typeof useGetBusinessUnitFromTenantQuery>;
export type GetBusinessUnitFromTenantLazyQueryHookResult = ReturnType<typeof useGetBusinessUnitFromTenantLazyQuery>;
export type GetBusinessUnitFromTenantQueryResult = Apollo.QueryResult<GetBusinessUnitFromTenantQuery, GetBusinessUnitFromTenantQueryVariables>;
export function refetchGetBusinessUnitFromTenantQuery(variables?: GetBusinessUnitFromTenantQueryVariables) {
      return { query: GetBusinessUnitFromTenantDocument, variables: variables }
    }
export const GetDashboardTenantSitesDocument = gql`
    query GetDashboardTenantSites($tenantId: ID!, $queryParams: QueryParameters) {
  tenantSitesStatuses(tenantId: $tenantId, queryParams: $queryParams) {
    tenant {
      id
      name
      address1
      address2
      postalCode
      country
      city
      contactPerson {
        firstName
        lastName
        phoneNumber
        email
      }
      createdAt
    }
    siteStatistics {
      sitesCount
      normalSitesCount
      needAttentionSitesCount
      inTestSitesCount
    }
    sitesStatuses {
      siteStatus {
        id
        name
        status
        statusUpdatedAt
        statistics {
          offlineNodesCount
          systemConnectionIssuesCount
          activeAlarmsCount
          pumpUnitIssuesCount
          deviceIssuesCount
          disabledDevicesCount
          disabledLoopsCount
          disabledDetectionZoneCount
          totalLoopsCount
          totalPumpUnitsCount
          totalNodeDevicesCount
          totalPanelsCount
        }
        contactPerson {
          firstName
          lastName
          phoneNumber
          email
        }
      }
      queryStats {
        pagination {
          totalRecords
          currentPage
          totalPages
          nextPage
          prevPage
        }
      }
    }
  }
}
    `;
export type GetDashboardTenantSitesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables>, 'query'> & ({ variables: GetDashboardTenantSitesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDashboardTenantSitesComponent = (props: GetDashboardTenantSitesComponentProps) => (
      <ApolloReactComponents.Query<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables> query={GetDashboardTenantSitesDocument} {...props} />
    );
    
export type GetDashboardTenantSitesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables>
    } & TChildProps;
export function withGetDashboardTenantSites<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDashboardTenantSitesQuery,
  GetDashboardTenantSitesQueryVariables,
  GetDashboardTenantSitesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables, GetDashboardTenantSitesProps<TChildProps, TDataName>>(GetDashboardTenantSitesDocument, {
      alias: 'getDashboardTenantSites',
      ...operationOptions
    });
};

/**
 * __useGetDashboardTenantSitesQuery__
 *
 * To run a query within a React component, call `useGetDashboardTenantSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTenantSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTenantSitesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGetDashboardTenantSitesQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables>(GetDashboardTenantSitesDocument, options);
      }
export function useGetDashboardTenantSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables>(GetDashboardTenantSitesDocument, options);
        }
export type GetDashboardTenantSitesQueryHookResult = ReturnType<typeof useGetDashboardTenantSitesQuery>;
export type GetDashboardTenantSitesLazyQueryHookResult = ReturnType<typeof useGetDashboardTenantSitesLazyQuery>;
export type GetDashboardTenantSitesQueryResult = Apollo.QueryResult<GetDashboardTenantSitesQuery, GetDashboardTenantSitesQueryVariables>;
export function refetchGetDashboardTenantSitesQuery(variables: GetDashboardTenantSitesQueryVariables) {
      return { query: GetDashboardTenantSitesDocument, variables: variables }
    }
export const DeleteInspectionDocument = gql`
    mutation DeleteInspection($inspectionId: String!) {
  deleteInspection(inspectionId: $inspectionId)
}
    `;
export type DeleteInspectionMutationFn = Apollo.MutationFunction<DeleteInspectionMutation, DeleteInspectionMutationVariables>;
export type DeleteInspectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteInspectionMutation, DeleteInspectionMutationVariables>, 'mutation'>;

    export const DeleteInspectionComponent = (props: DeleteInspectionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteInspectionMutation, DeleteInspectionMutationVariables> mutation={DeleteInspectionDocument} {...props} />
    );
    
export type DeleteInspectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteInspectionMutation, DeleteInspectionMutationVariables>
    } & TChildProps;
export function withDeleteInspection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteInspectionMutation,
  DeleteInspectionMutationVariables,
  DeleteInspectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteInspectionMutation, DeleteInspectionMutationVariables, DeleteInspectionProps<TChildProps, TDataName>>(DeleteInspectionDocument, {
      alias: 'deleteInspection',
      ...operationOptions
    });
};

/**
 * __useDeleteInspectionMutation__
 *
 * To run a mutation, you first call `useDeleteInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInspectionMutation, { data, loading, error }] = useDeleteInspectionMutation({
 *   variables: {
 *      inspectionId: // value for 'inspectionId'
 *   },
 * });
 */
export function useDeleteInspectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInspectionMutation, DeleteInspectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInspectionMutation, DeleteInspectionMutationVariables>(DeleteInspectionDocument, options);
      }
export type DeleteInspectionMutationHookResult = ReturnType<typeof useDeleteInspectionMutation>;
export type DeleteInspectionMutationResult = Apollo.MutationResult<DeleteInspectionMutation>;
export type DeleteInspectionMutationOptions = Apollo.BaseMutationOptions<DeleteInspectionMutation, DeleteInspectionMutationVariables>;
export const GetInspectionsByDateDocument = gql`
    query GetInspectionsByDate($input: GetInspectionsByDateInput!) {
  inspectionsByDate(input: $input) {
    inspections {
      createdAt
      version
      createdBy {
        firstName
        lastName
      }
      startedBy {
        firstName
        lastName
      }
      completedAt
      name
      type
      site {
        name
        tenantId
      }
      device {
        name
      }
      scheduledAt
      status
      id
      template {
        id
        name
      }
    }
    queryStats {
      pagination {
        totalRecords
      }
    }
  }
}
    `;
export type GetInspectionsByDateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables>, 'query'> & ({ variables: GetInspectionsByDateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInspectionsByDateComponent = (props: GetInspectionsByDateComponentProps) => (
      <ApolloReactComponents.Query<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables> query={GetInspectionsByDateDocument} {...props} />
    );
    
export type GetInspectionsByDateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables>
    } & TChildProps;
export function withGetInspectionsByDate<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspectionsByDateQuery,
  GetInspectionsByDateQueryVariables,
  GetInspectionsByDateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables, GetInspectionsByDateProps<TChildProps, TDataName>>(GetInspectionsByDateDocument, {
      alias: 'getInspectionsByDate',
      ...operationOptions
    });
};

/**
 * __useGetInspectionsByDateQuery__
 *
 * To run a query within a React component, call `useGetInspectionsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionsByDateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInspectionsByDateQuery(baseOptions: Apollo.QueryHookOptions<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables>(GetInspectionsByDateDocument, options);
      }
export function useGetInspectionsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables>(GetInspectionsByDateDocument, options);
        }
export type GetInspectionsByDateQueryHookResult = ReturnType<typeof useGetInspectionsByDateQuery>;
export type GetInspectionsByDateLazyQueryHookResult = ReturnType<typeof useGetInspectionsByDateLazyQuery>;
export type GetInspectionsByDateQueryResult = Apollo.QueryResult<GetInspectionsByDateQuery, GetInspectionsByDateQueryVariables>;
export function refetchGetInspectionsByDateQuery(variables: GetInspectionsByDateQueryVariables) {
      return { query: GetInspectionsByDateDocument, variables: variables }
    }
export const ScheduleInspectionDocument = gql`
    mutation ScheduleInspection($inspection: ScheduleInspectionInput!) {
  scheduleInspection(inspection: $inspection) {
    id
    type
  }
}
    `;
export type ScheduleInspectionMutationFn = Apollo.MutationFunction<ScheduleInspectionMutation, ScheduleInspectionMutationVariables>;
export type ScheduleInspectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ScheduleInspectionMutation, ScheduleInspectionMutationVariables>, 'mutation'>;

    export const ScheduleInspectionComponent = (props: ScheduleInspectionComponentProps) => (
      <ApolloReactComponents.Mutation<ScheduleInspectionMutation, ScheduleInspectionMutationVariables> mutation={ScheduleInspectionDocument} {...props} />
    );
    
export type ScheduleInspectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ScheduleInspectionMutation, ScheduleInspectionMutationVariables>
    } & TChildProps;
export function withScheduleInspection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ScheduleInspectionMutation,
  ScheduleInspectionMutationVariables,
  ScheduleInspectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ScheduleInspectionMutation, ScheduleInspectionMutationVariables, ScheduleInspectionProps<TChildProps, TDataName>>(ScheduleInspectionDocument, {
      alias: 'scheduleInspection',
      ...operationOptions
    });
};

/**
 * __useScheduleInspectionMutation__
 *
 * To run a mutation, you first call `useScheduleInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleInspectionMutation, { data, loading, error }] = useScheduleInspectionMutation({
 *   variables: {
 *      inspection: // value for 'inspection'
 *   },
 * });
 */
export function useScheduleInspectionMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleInspectionMutation, ScheduleInspectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleInspectionMutation, ScheduleInspectionMutationVariables>(ScheduleInspectionDocument, options);
      }
export type ScheduleInspectionMutationHookResult = ReturnType<typeof useScheduleInspectionMutation>;
export type ScheduleInspectionMutationResult = Apollo.MutationResult<ScheduleInspectionMutation>;
export type ScheduleInspectionMutationOptions = Apollo.BaseMutationOptions<ScheduleInspectionMutation, ScheduleInspectionMutationVariables>;
export const CreateInspectionTemplateDocument = gql`
    mutation CreateInspectionTemplate($template: InspectionTemplateInput!) {
  createInspectionTemplate(template: $template)
}
    `;
export type CreateInspectionTemplateMutationFn = Apollo.MutationFunction<CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables>;
export type CreateInspectionTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables>, 'mutation'>;

    export const CreateInspectionTemplateComponent = (props: CreateInspectionTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables> mutation={CreateInspectionTemplateDocument} {...props} />
    );
    
export type CreateInspectionTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables>
    } & TChildProps;
export function withCreateInspectionTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInspectionTemplateMutation,
  CreateInspectionTemplateMutationVariables,
  CreateInspectionTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables, CreateInspectionTemplateProps<TChildProps, TDataName>>(CreateInspectionTemplateDocument, {
      alias: 'createInspectionTemplate',
      ...operationOptions
    });
};

/**
 * __useCreateInspectionTemplateMutation__
 *
 * To run a mutation, you first call `useCreateInspectionTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionTemplateMutation, { data, loading, error }] = useCreateInspectionTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useCreateInspectionTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables>(CreateInspectionTemplateDocument, options);
      }
export type CreateInspectionTemplateMutationHookResult = ReturnType<typeof useCreateInspectionTemplateMutation>;
export type CreateInspectionTemplateMutationResult = Apollo.MutationResult<CreateInspectionTemplateMutation>;
export type CreateInspectionTemplateMutationOptions = Apollo.BaseMutationOptions<CreateInspectionTemplateMutation, CreateInspectionTemplateMutationVariables>;
export const GetInspectionTemplatesDocument = gql`
    query GetInspectionTemplates($type: InspectionType, $queryParams: QueryParameters) {
  inspectionTemplates(type: $type, queryParams: $queryParams) {
    templates {
      id
      name
      createdAt
      createdBy {
        firstName
        lastName
      }
      type
    }
    queryStats {
      pagination {
        totalRecords
      }
    }
  }
}
    `;
export type GetInspectionTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables>, 'query'>;

    export const GetInspectionTemplatesComponent = (props: GetInspectionTemplatesComponentProps) => (
      <ApolloReactComponents.Query<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables> query={GetInspectionTemplatesDocument} {...props} />
    );
    
export type GetInspectionTemplatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables>
    } & TChildProps;
export function withGetInspectionTemplates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspectionTemplatesQuery,
  GetInspectionTemplatesQueryVariables,
  GetInspectionTemplatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables, GetInspectionTemplatesProps<TChildProps, TDataName>>(GetInspectionTemplatesDocument, {
      alias: 'getInspectionTemplates',
      ...operationOptions
    });
};

/**
 * __useGetInspectionTemplatesQuery__
 *
 * To run a query within a React component, call `useGetInspectionTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionTemplatesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGetInspectionTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables>(GetInspectionTemplatesDocument, options);
      }
export function useGetInspectionTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables>(GetInspectionTemplatesDocument, options);
        }
export type GetInspectionTemplatesQueryHookResult = ReturnType<typeof useGetInspectionTemplatesQuery>;
export type GetInspectionTemplatesLazyQueryHookResult = ReturnType<typeof useGetInspectionTemplatesLazyQuery>;
export type GetInspectionTemplatesQueryResult = Apollo.QueryResult<GetInspectionTemplatesQuery, GetInspectionTemplatesQueryVariables>;
export function refetchGetInspectionTemplatesQuery(variables?: GetInspectionTemplatesQueryVariables) {
      return { query: GetInspectionTemplatesDocument, variables: variables }
    }
export const UpdateCustomTemplateDocument = gql`
    mutation UpdateCustomTemplate($templateId: String!, $template: InspectionTemplateInput!) {
  updateCustomTemplate(templateId: $templateId, template: $template)
}
    `;
export type UpdateCustomTemplateMutationFn = Apollo.MutationFunction<UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables>;
export type UpdateCustomTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables>, 'mutation'>;

    export const UpdateCustomTemplateComponent = (props: UpdateCustomTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables> mutation={UpdateCustomTemplateDocument} {...props} />
    );
    
export type UpdateCustomTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables>
    } & TChildProps;
export function withUpdateCustomTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomTemplateMutation,
  UpdateCustomTemplateMutationVariables,
  UpdateCustomTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables, UpdateCustomTemplateProps<TChildProps, TDataName>>(UpdateCustomTemplateDocument, {
      alias: 'updateCustomTemplate',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateCustomTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomTemplateMutation, { data, loading, error }] = useUpdateCustomTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useUpdateCustomTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables>(UpdateCustomTemplateDocument, options);
      }
export type UpdateCustomTemplateMutationHookResult = ReturnType<typeof useUpdateCustomTemplateMutation>;
export type UpdateCustomTemplateMutationResult = Apollo.MutationResult<UpdateCustomTemplateMutation>;
export type UpdateCustomTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateCustomTemplateMutation, UpdateCustomTemplateMutationVariables>;
export const GetSignedUrlDocument = gql`
    query GetSignedUrl($input: SignedUrlInput!) {
  getSignedUrl(input: $input)
}
    `;
export type GetSignedUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>, 'query'> & ({ variables: GetSignedUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSignedUrlComponent = (props: GetSignedUrlComponentProps) => (
      <ApolloReactComponents.Query<GetSignedUrlQuery, GetSignedUrlQueryVariables> query={GetSignedUrlDocument} {...props} />
    );
    
export type GetSignedUrlProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSignedUrlQuery, GetSignedUrlQueryVariables>
    } & TChildProps;
export function withGetSignedUrl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSignedUrlQuery,
  GetSignedUrlQueryVariables,
  GetSignedUrlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSignedUrlQuery, GetSignedUrlQueryVariables, GetSignedUrlProps<TChildProps, TDataName>>(GetSignedUrlDocument, {
      alias: 'getSignedUrl',
      ...operationOptions
    });
};

/**
 * __useGetSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSignedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, options);
      }
export function useGetSignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, options);
        }
export type GetSignedUrlQueryHookResult = ReturnType<typeof useGetSignedUrlQuery>;
export type GetSignedUrlLazyQueryHookResult = ReturnType<typeof useGetSignedUrlLazyQuery>;
export type GetSignedUrlQueryResult = Apollo.QueryResult<GetSignedUrlQuery, GetSignedUrlQueryVariables>;
export function refetchGetSignedUrlQuery(variables: GetSignedUrlQueryVariables) {
      return { query: GetSignedUrlDocument, variables: variables }
    }
export const DownloadMasterTemplateDocument = gql`
    query DownloadMasterTemplate($templateId: String!) {
  downloadMasterTemplate(templateId: $templateId)
}
    `;
export type DownloadMasterTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables>, 'query'> & ({ variables: DownloadMasterTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DownloadMasterTemplateComponent = (props: DownloadMasterTemplateComponentProps) => (
      <ApolloReactComponents.Query<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables> query={DownloadMasterTemplateDocument} {...props} />
    );
    
export type DownloadMasterTemplateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables>
    } & TChildProps;
export function withDownloadMasterTemplate<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadMasterTemplateQuery,
  DownloadMasterTemplateQueryVariables,
  DownloadMasterTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables, DownloadMasterTemplateProps<TChildProps, TDataName>>(DownloadMasterTemplateDocument, {
      alias: 'downloadMasterTemplate',
      ...operationOptions
    });
};

/**
 * __useDownloadMasterTemplateQuery__
 *
 * To run a query within a React component, call `useDownloadMasterTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadMasterTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadMasterTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDownloadMasterTemplateQuery(baseOptions: Apollo.QueryHookOptions<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables>(DownloadMasterTemplateDocument, options);
      }
export function useDownloadMasterTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables>(DownloadMasterTemplateDocument, options);
        }
export type DownloadMasterTemplateQueryHookResult = ReturnType<typeof useDownloadMasterTemplateQuery>;
export type DownloadMasterTemplateLazyQueryHookResult = ReturnType<typeof useDownloadMasterTemplateLazyQuery>;
export type DownloadMasterTemplateQueryResult = Apollo.QueryResult<DownloadMasterTemplateQuery, DownloadMasterTemplateQueryVariables>;
export function refetchDownloadMasterTemplateQuery(variables: DownloadMasterTemplateQueryVariables) {
      return { query: DownloadMasterTemplateDocument, variables: variables }
    }
export const DownloadReportDocument = gql`
    query DownloadReport($inspectionId: String!, $templateName: String!) {
  downloadReport(inspectionId: $inspectionId, templateName: $templateName)
}
    `;
export type DownloadReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DownloadReportQuery, DownloadReportQueryVariables>, 'query'> & ({ variables: DownloadReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DownloadReportComponent = (props: DownloadReportComponentProps) => (
      <ApolloReactComponents.Query<DownloadReportQuery, DownloadReportQueryVariables> query={DownloadReportDocument} {...props} />
    );
    
export type DownloadReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DownloadReportQuery, DownloadReportQueryVariables>
    } & TChildProps;
export function withDownloadReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadReportQuery,
  DownloadReportQueryVariables,
  DownloadReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DownloadReportQuery, DownloadReportQueryVariables, DownloadReportProps<TChildProps, TDataName>>(DownloadReportDocument, {
      alias: 'downloadReport',
      ...operationOptions
    });
};

/**
 * __useDownloadReportQuery__
 *
 * To run a query within a React component, call `useDownloadReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadReportQuery({
 *   variables: {
 *      inspectionId: // value for 'inspectionId'
 *      templateName: // value for 'templateName'
 *   },
 * });
 */
export function useDownloadReportQuery(baseOptions: Apollo.QueryHookOptions<DownloadReportQuery, DownloadReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadReportQuery, DownloadReportQueryVariables>(DownloadReportDocument, options);
      }
export function useDownloadReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadReportQuery, DownloadReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadReportQuery, DownloadReportQueryVariables>(DownloadReportDocument, options);
        }
export type DownloadReportQueryHookResult = ReturnType<typeof useDownloadReportQuery>;
export type DownloadReportLazyQueryHookResult = ReturnType<typeof useDownloadReportLazyQuery>;
export type DownloadReportQueryResult = Apollo.QueryResult<DownloadReportQuery, DownloadReportQueryVariables>;
export function refetchDownloadReportQuery(variables: DownloadReportQueryVariables) {
      return { query: DownloadReportDocument, variables: variables }
    }
export const GetInspectionDetailsDocument = gql`
    query GetInspectionDetails($inspectionId: String!) {
  inspectionDetails(inspectionId: $inspectionId) {
    createdAt
    version
    createdBy {
      firstName
      lastName
    }
    startedBy {
      firstName
      lastName
    }
    completedAt
    checklists {
      name
      id
      isChecked
      steps {
        name
        id
        description
        title
      }
      title
    }
    comments {
      createdAt
      createdBy {
        firstName
        lastName
        role
      }
      text
      mediaFilePath
    }
    name
    type
    site {
      name
      tenantId
    }
    device {
      name
    }
    scheduledAt
    status
    id
  }
}
    `;
export type GetInspectionDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables>, 'query'> & ({ variables: GetInspectionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInspectionDetailsComponent = (props: GetInspectionDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables> query={GetInspectionDetailsDocument} {...props} />
    );
    
export type GetInspectionDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables>
    } & TChildProps;
export function withGetInspectionDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspectionDetailsQuery,
  GetInspectionDetailsQueryVariables,
  GetInspectionDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables, GetInspectionDetailsProps<TChildProps, TDataName>>(GetInspectionDetailsDocument, {
      alias: 'getInspectionDetails',
      ...operationOptions
    });
};

/**
 * __useGetInspectionDetailsQuery__
 *
 * To run a query within a React component, call `useGetInspectionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionDetailsQuery({
 *   variables: {
 *      inspectionId: // value for 'inspectionId'
 *   },
 * });
 */
export function useGetInspectionDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables>(GetInspectionDetailsDocument, options);
      }
export function useGetInspectionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables>(GetInspectionDetailsDocument, options);
        }
export type GetInspectionDetailsQueryHookResult = ReturnType<typeof useGetInspectionDetailsQuery>;
export type GetInspectionDetailsLazyQueryHookResult = ReturnType<typeof useGetInspectionDetailsLazyQuery>;
export type GetInspectionDetailsQueryResult = Apollo.QueryResult<GetInspectionDetailsQuery, GetInspectionDetailsQueryVariables>;
export function refetchGetInspectionDetailsQuery(variables: GetInspectionDetailsQueryVariables) {
      return { query: GetInspectionDetailsDocument, variables: variables }
    }
export const GetInspectionsDocument = gql`
    query GetInspections($input: GetInspectionsInput!) {
  inspections(input: $input) {
    inspections {
      createdAt
      version
      createdBy {
        firstName
        lastName
      }
      startedBy {
        firstName
        lastName
      }
      completedAt
      name
      type
      site {
        name
        tenantId
      }
      device {
        name
      }
      scheduledAt
      status
      id
      template {
        id
        name
      }
    }
    queryStats {
      pagination {
        totalRecords
      }
    }
  }
}
    `;
export type GetInspectionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspectionsQuery, GetInspectionsQueryVariables>, 'query'> & ({ variables: GetInspectionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInspectionsComponent = (props: GetInspectionsComponentProps) => (
      <ApolloReactComponents.Query<GetInspectionsQuery, GetInspectionsQueryVariables> query={GetInspectionsDocument} {...props} />
    );
    
export type GetInspectionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInspectionsQuery, GetInspectionsQueryVariables>
    } & TChildProps;
export function withGetInspections<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspectionsQuery,
  GetInspectionsQueryVariables,
  GetInspectionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspectionsQuery, GetInspectionsQueryVariables, GetInspectionsProps<TChildProps, TDataName>>(GetInspectionsDocument, {
      alias: 'getInspections',
      ...operationOptions
    });
};

/**
 * __useGetInspectionsQuery__
 *
 * To run a query within a React component, call `useGetInspectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInspectionsQuery(baseOptions: Apollo.QueryHookOptions<GetInspectionsQuery, GetInspectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspectionsQuery, GetInspectionsQueryVariables>(GetInspectionsDocument, options);
      }
export function useGetInspectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspectionsQuery, GetInspectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspectionsQuery, GetInspectionsQueryVariables>(GetInspectionsDocument, options);
        }
export type GetInspectionsQueryHookResult = ReturnType<typeof useGetInspectionsQuery>;
export type GetInspectionsLazyQueryHookResult = ReturnType<typeof useGetInspectionsLazyQuery>;
export type GetInspectionsQueryResult = Apollo.QueryResult<GetInspectionsQuery, GetInspectionsQueryVariables>;
export function refetchGetInspectionsQuery(variables: GetInspectionsQueryVariables) {
      return { query: GetInspectionsDocument, variables: variables }
    }
export const ShareInspectionDocument = gql`
    mutation ShareInspection($inspectionId: String!, $toEmailAddress: [String!]!) {
  shareInspection(inspectionId: $inspectionId, toEmailAddress: $toEmailAddress)
}
    `;
export type ShareInspectionMutationFn = Apollo.MutationFunction<ShareInspectionMutation, ShareInspectionMutationVariables>;
export type ShareInspectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ShareInspectionMutation, ShareInspectionMutationVariables>, 'mutation'>;

    export const ShareInspectionComponent = (props: ShareInspectionComponentProps) => (
      <ApolloReactComponents.Mutation<ShareInspectionMutation, ShareInspectionMutationVariables> mutation={ShareInspectionDocument} {...props} />
    );
    
export type ShareInspectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ShareInspectionMutation, ShareInspectionMutationVariables>
    } & TChildProps;
export function withShareInspection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ShareInspectionMutation,
  ShareInspectionMutationVariables,
  ShareInspectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ShareInspectionMutation, ShareInspectionMutationVariables, ShareInspectionProps<TChildProps, TDataName>>(ShareInspectionDocument, {
      alias: 'shareInspection',
      ...operationOptions
    });
};

/**
 * __useShareInspectionMutation__
 *
 * To run a mutation, you first call `useShareInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareInspectionMutation, { data, loading, error }] = useShareInspectionMutation({
 *   variables: {
 *      inspectionId: // value for 'inspectionId'
 *      toEmailAddress: // value for 'toEmailAddress'
 *   },
 * });
 */
export function useShareInspectionMutation(baseOptions?: Apollo.MutationHookOptions<ShareInspectionMutation, ShareInspectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareInspectionMutation, ShareInspectionMutationVariables>(ShareInspectionDocument, options);
      }
export type ShareInspectionMutationHookResult = ReturnType<typeof useShareInspectionMutation>;
export type ShareInspectionMutationResult = Apollo.MutationResult<ShareInspectionMutation>;
export type ShareInspectionMutationOptions = Apollo.BaseMutationOptions<ShareInspectionMutation, ShareInspectionMutationVariables>;
export const GetTenantBasicDetailsDocument = gql`
    query GetTenantBasicDetails($id: String!) {
  tenantDetails(id: $id) {
    id
    name
  }
}
    `;
export type GetTenantBasicDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables>, 'query'> & ({ variables: GetTenantBasicDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTenantBasicDetailsComponent = (props: GetTenantBasicDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables> query={GetTenantBasicDetailsDocument} {...props} />
    );
    
export type GetTenantBasicDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables>
    } & TChildProps;
export function withGetTenantBasicDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTenantBasicDetailsQuery,
  GetTenantBasicDetailsQueryVariables,
  GetTenantBasicDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables, GetTenantBasicDetailsProps<TChildProps, TDataName>>(GetTenantBasicDetailsDocument, {
      alias: 'getTenantBasicDetails',
      ...operationOptions
    });
};

/**
 * __useGetTenantBasicDetailsQuery__
 *
 * To run a query within a React component, call `useGetTenantBasicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantBasicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantBasicDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantBasicDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables>(GetTenantBasicDetailsDocument, options);
      }
export function useGetTenantBasicDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables>(GetTenantBasicDetailsDocument, options);
        }
export type GetTenantBasicDetailsQueryHookResult = ReturnType<typeof useGetTenantBasicDetailsQuery>;
export type GetTenantBasicDetailsLazyQueryHookResult = ReturnType<typeof useGetTenantBasicDetailsLazyQuery>;
export type GetTenantBasicDetailsQueryResult = Apollo.QueryResult<GetTenantBasicDetailsQuery, GetTenantBasicDetailsQueryVariables>;
export function refetchGetTenantBasicDetailsQuery(variables: GetTenantBasicDetailsQueryVariables) {
      return { query: GetTenantBasicDetailsDocument, variables: variables }
    }
export const GetSiteStatusDocument = gql`
    query GetSiteStatus($siteId: String!) {
  siteDetails(siteId: $siteId) {
    id
    name
    status
    statusUpdatedAt
  }
}
    `;
export type GetSiteStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSiteStatusQuery, GetSiteStatusQueryVariables>, 'query'> & ({ variables: GetSiteStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSiteStatusComponent = (props: GetSiteStatusComponentProps) => (
      <ApolloReactComponents.Query<GetSiteStatusQuery, GetSiteStatusQueryVariables> query={GetSiteStatusDocument} {...props} />
    );
    
export type GetSiteStatusProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSiteStatusQuery, GetSiteStatusQueryVariables>
    } & TChildProps;
export function withGetSiteStatus<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSiteStatusQuery,
  GetSiteStatusQueryVariables,
  GetSiteStatusProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSiteStatusQuery, GetSiteStatusQueryVariables, GetSiteStatusProps<TChildProps, TDataName>>(GetSiteStatusDocument, {
      alias: 'getSiteStatus',
      ...operationOptions
    });
};

/**
 * __useGetSiteStatusQuery__
 *
 * To run a query within a React component, call `useGetSiteStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteStatusQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteStatusQuery(baseOptions: Apollo.QueryHookOptions<GetSiteStatusQuery, GetSiteStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteStatusQuery, GetSiteStatusQueryVariables>(GetSiteStatusDocument, options);
      }
export function useGetSiteStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteStatusQuery, GetSiteStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteStatusQuery, GetSiteStatusQueryVariables>(GetSiteStatusDocument, options);
        }
export type GetSiteStatusQueryHookResult = ReturnType<typeof useGetSiteStatusQuery>;
export type GetSiteStatusLazyQueryHookResult = ReturnType<typeof useGetSiteStatusLazyQuery>;
export type GetSiteStatusQueryResult = Apollo.QueryResult<GetSiteStatusQuery, GetSiteStatusQueryVariables>;
export function refetchGetSiteStatusQuery(variables: GetSiteStatusQueryVariables) {
      return { query: GetSiteStatusDocument, variables: variables }
    }
export const CreateSiteDocument = gql`
    mutation CreateSite($site: SiteInput!) {
  createSite(site: $site) {
    id
    userSiteId
    name
    tenantId
    address1
    address2
    country
    city
    state
    postalCode
    createdAt
    contactPerson {
      firstName
      lastName
      phoneNumber
      email
    }
    tenant {
      id
      name
    }
    createdInspectionsCount
  }
}
    `;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;
export type CreateSiteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSiteMutation, CreateSiteMutationVariables>, 'mutation'>;

    export const CreateSiteComponent = (props: CreateSiteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSiteMutation, CreateSiteMutationVariables> mutation={CreateSiteDocument} {...props} />
    );
    
export type CreateSiteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>
    } & TChildProps;
export function withCreateSite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSiteMutation,
  CreateSiteMutationVariables,
  CreateSiteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSiteMutation, CreateSiteMutationVariables, CreateSiteProps<TChildProps, TDataName>>(CreateSiteDocument, {
      alias: 'createSite',
      ...operationOptions
    });
};

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const GetAllSitesDocument = gql`
    query GetAllSites($tenantId: String, $queryParams: QueryParameters, $businessUnits: [BusinessUnit!]) {
  sites(
    tenantId: $tenantId
    queryParams: $queryParams
    businessUnits: $businessUnits
  ) {
    sites {
      id
      userSiteId
      name
      tenantId
      address1
      address2
      country
      city
      postalCode
      createdAt
      contactPerson {
        firstName
        lastName
        phoneNumber
        email
      }
      assignedUsers {
        email
        name
        phoneNumber
        roleId
        oktaId
        assignedDate
        status
      }
      assignableUsers {
        assignedSites
        email
        name
        roleId
        status
        oktaId
      }
      businessUnit
      status
      createdInspectionsCount
      tenant {
        id
        name
        address1
        address2
        postalCode
        country
        city
        createdAt
      }
    }
    queryStats {
      pagination {
        totalRecords
      }
    }
  }
}
    `;
export type GetAllSitesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllSitesQuery, GetAllSitesQueryVariables>, 'query'>;

    export const GetAllSitesComponent = (props: GetAllSitesComponentProps) => (
      <ApolloReactComponents.Query<GetAllSitesQuery, GetAllSitesQueryVariables> query={GetAllSitesDocument} {...props} />
    );
    
export type GetAllSitesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAllSitesQuery, GetAllSitesQueryVariables>
    } & TChildProps;
export function withGetAllSites<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAllSitesQuery,
  GetAllSitesQueryVariables,
  GetAllSitesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAllSitesQuery, GetAllSitesQueryVariables, GetAllSitesProps<TChildProps, TDataName>>(GetAllSitesDocument, {
      alias: 'getAllSites',
      ...operationOptions
    });
};

/**
 * __useGetAllSitesQuery__
 *
 * To run a query within a React component, call `useGetAllSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSitesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      queryParams: // value for 'queryParams'
 *      businessUnits: // value for 'businessUnits'
 *   },
 * });
 */
export function useGetAllSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSitesQuery, GetAllSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSitesQuery, GetAllSitesQueryVariables>(GetAllSitesDocument, options);
      }
export function useGetAllSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSitesQuery, GetAllSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSitesQuery, GetAllSitesQueryVariables>(GetAllSitesDocument, options);
        }
export type GetAllSitesQueryHookResult = ReturnType<typeof useGetAllSitesQuery>;
export type GetAllSitesLazyQueryHookResult = ReturnType<typeof useGetAllSitesLazyQuery>;
export type GetAllSitesQueryResult = Apollo.QueryResult<GetAllSitesQuery, GetAllSitesQueryVariables>;
export function refetchGetAllSitesQuery(variables?: GetAllSitesQueryVariables) {
      return { query: GetAllSitesDocument, variables: variables }
    }
export const GetNodeStatusDocument = gql`
    query GetNodeStatus($siteId: ID!, $nodeId: ID!) {
  nodeWithSiteIdNodeId(siteId: $siteId, nodeId: $nodeId) {
    node {
      id
      name
      externalNetworkStatus
    }
  }
}
    `;
export type GetNodeStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNodeStatusQuery, GetNodeStatusQueryVariables>, 'query'> & ({ variables: GetNodeStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetNodeStatusComponent = (props: GetNodeStatusComponentProps) => (
      <ApolloReactComponents.Query<GetNodeStatusQuery, GetNodeStatusQueryVariables> query={GetNodeStatusDocument} {...props} />
    );
    
export type GetNodeStatusProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetNodeStatusQuery, GetNodeStatusQueryVariables>
    } & TChildProps;
export function withGetNodeStatus<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNodeStatusQuery,
  GetNodeStatusQueryVariables,
  GetNodeStatusProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetNodeStatusQuery, GetNodeStatusQueryVariables, GetNodeStatusProps<TChildProps, TDataName>>(GetNodeStatusDocument, {
      alias: 'getNodeStatus',
      ...operationOptions
    });
};

/**
 * __useGetNodeStatusQuery__
 *
 * To run a query within a React component, call `useGetNodeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeStatusQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetNodeStatusQuery(baseOptions: Apollo.QueryHookOptions<GetNodeStatusQuery, GetNodeStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNodeStatusQuery, GetNodeStatusQueryVariables>(GetNodeStatusDocument, options);
      }
export function useGetNodeStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNodeStatusQuery, GetNodeStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNodeStatusQuery, GetNodeStatusQueryVariables>(GetNodeStatusDocument, options);
        }
export type GetNodeStatusQueryHookResult = ReturnType<typeof useGetNodeStatusQuery>;
export type GetNodeStatusLazyQueryHookResult = ReturnType<typeof useGetNodeStatusLazyQuery>;
export type GetNodeStatusQueryResult = Apollo.QueryResult<GetNodeStatusQuery, GetNodeStatusQueryVariables>;
export function refetchGetNodeStatusQuery(variables: GetNodeStatusQueryVariables) {
      return { query: GetNodeStatusDocument, variables: variables }
    }
export const AssignUsersDocument = gql`
    mutation AssignUsers($siteUsers: AssignUsersInput!) {
  assignUsers(siteUsers: $siteUsers)
}
    `;
export type AssignUsersMutationFn = Apollo.MutationFunction<AssignUsersMutation, AssignUsersMutationVariables>;
export type AssignUsersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignUsersMutation, AssignUsersMutationVariables>, 'mutation'>;

    export const AssignUsersComponent = (props: AssignUsersComponentProps) => (
      <ApolloReactComponents.Mutation<AssignUsersMutation, AssignUsersMutationVariables> mutation={AssignUsersDocument} {...props} />
    );
    
export type AssignUsersProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<AssignUsersMutation, AssignUsersMutationVariables>
    } & TChildProps;
export function withAssignUsers<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignUsersMutation,
  AssignUsersMutationVariables,
  AssignUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignUsersMutation, AssignUsersMutationVariables, AssignUsersProps<TChildProps, TDataName>>(AssignUsersDocument, {
      alias: 'assignUsers',
      ...operationOptions
    });
};

/**
 * __useAssignUsersMutation__
 *
 * To run a mutation, you first call `useAssignUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUsersMutation, { data, loading, error }] = useAssignUsersMutation({
 *   variables: {
 *      siteUsers: // value for 'siteUsers'
 *   },
 * });
 */
export function useAssignUsersMutation(baseOptions?: Apollo.MutationHookOptions<AssignUsersMutation, AssignUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignUsersMutation, AssignUsersMutationVariables>(AssignUsersDocument, options);
      }
export type AssignUsersMutationHookResult = ReturnType<typeof useAssignUsersMutation>;
export type AssignUsersMutationResult = Apollo.MutationResult<AssignUsersMutation>;
export type AssignUsersMutationOptions = Apollo.BaseMutationOptions<AssignUsersMutation, AssignUsersMutationVariables>;
export const GetSiteAssignableUsersDocument = gql`
    query GetSiteAssignableUsers($siteDetailsId: String!) {
  siteDetails(siteId: $siteDetailsId) {
    id
    name
    userSiteId
    tenantId
    assignableUsers {
      assignedSites
      email
      name
      roleId
      status
      oktaId
    }
  }
}
    `;
export type GetSiteAssignableUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables>, 'query'> & ({ variables: GetSiteAssignableUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSiteAssignableUsersComponent = (props: GetSiteAssignableUsersComponentProps) => (
      <ApolloReactComponents.Query<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables> query={GetSiteAssignableUsersDocument} {...props} />
    );
    
export type GetSiteAssignableUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables>
    } & TChildProps;
export function withGetSiteAssignableUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSiteAssignableUsersQuery,
  GetSiteAssignableUsersQueryVariables,
  GetSiteAssignableUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables, GetSiteAssignableUsersProps<TChildProps, TDataName>>(GetSiteAssignableUsersDocument, {
      alias: 'getSiteAssignableUsers',
      ...operationOptions
    });
};

/**
 * __useGetSiteAssignableUsersQuery__
 *
 * To run a query within a React component, call `useGetSiteAssignableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteAssignableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteAssignableUsersQuery({
 *   variables: {
 *      siteDetailsId: // value for 'siteDetailsId'
 *   },
 * });
 */
export function useGetSiteAssignableUsersQuery(baseOptions: Apollo.QueryHookOptions<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables>(GetSiteAssignableUsersDocument, options);
      }
export function useGetSiteAssignableUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables>(GetSiteAssignableUsersDocument, options);
        }
export type GetSiteAssignableUsersQueryHookResult = ReturnType<typeof useGetSiteAssignableUsersQuery>;
export type GetSiteAssignableUsersLazyQueryHookResult = ReturnType<typeof useGetSiteAssignableUsersLazyQuery>;
export type GetSiteAssignableUsersQueryResult = Apollo.QueryResult<GetSiteAssignableUsersQuery, GetSiteAssignableUsersQueryVariables>;
export function refetchGetSiteAssignableUsersQuery(variables: GetSiteAssignableUsersQueryVariables) {
      return { query: GetSiteAssignableUsersDocument, variables: variables }
    }
export const GetSiteAssignedUsersDocument = gql`
    query GetSiteAssignedUsers($siteDetailsId: String!) {
  siteDetails(siteId: $siteDetailsId) {
    id
    name
    userSiteId
    tenantId
    assignedUsers {
      email
      name
      phoneNumber
      roleId
      oktaId
      assignedDate
      status
    }
  }
}
    `;
export type GetSiteAssignedUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables>, 'query'> & ({ variables: GetSiteAssignedUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSiteAssignedUsersComponent = (props: GetSiteAssignedUsersComponentProps) => (
      <ApolloReactComponents.Query<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables> query={GetSiteAssignedUsersDocument} {...props} />
    );
    
export type GetSiteAssignedUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables>
    } & TChildProps;
export function withGetSiteAssignedUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSiteAssignedUsersQuery,
  GetSiteAssignedUsersQueryVariables,
  GetSiteAssignedUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables, GetSiteAssignedUsersProps<TChildProps, TDataName>>(GetSiteAssignedUsersDocument, {
      alias: 'getSiteAssignedUsers',
      ...operationOptions
    });
};

/**
 * __useGetSiteAssignedUsersQuery__
 *
 * To run a query within a React component, call `useGetSiteAssignedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteAssignedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteAssignedUsersQuery({
 *   variables: {
 *      siteDetailsId: // value for 'siteDetailsId'
 *   },
 * });
 */
export function useGetSiteAssignedUsersQuery(baseOptions: Apollo.QueryHookOptions<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables>(GetSiteAssignedUsersDocument, options);
      }
export function useGetSiteAssignedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables>(GetSiteAssignedUsersDocument, options);
        }
export type GetSiteAssignedUsersQueryHookResult = ReturnType<typeof useGetSiteAssignedUsersQuery>;
export type GetSiteAssignedUsersLazyQueryHookResult = ReturnType<typeof useGetSiteAssignedUsersLazyQuery>;
export type GetSiteAssignedUsersQueryResult = Apollo.QueryResult<GetSiteAssignedUsersQuery, GetSiteAssignedUsersQueryVariables>;
export function refetchGetSiteAssignedUsersQuery(variables: GetSiteAssignedUsersQueryVariables) {
      return { query: GetSiteAssignedUsersDocument, variables: variables }
    }
export const UnassignUsersDocument = gql`
    mutation UnassignUsers($siteUsers: UnassignUserInput!) {
  unassignUsers(siteUsers: $siteUsers)
}
    `;
export type UnassignUsersMutationFn = Apollo.MutationFunction<UnassignUsersMutation, UnassignUsersMutationVariables>;
export type UnassignUsersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignUsersMutation, UnassignUsersMutationVariables>, 'mutation'>;

    export const UnassignUsersComponent = (props: UnassignUsersComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignUsersMutation, UnassignUsersMutationVariables> mutation={UnassignUsersDocument} {...props} />
    );
    
export type UnassignUsersProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UnassignUsersMutation, UnassignUsersMutationVariables>
    } & TChildProps;
export function withUnassignUsers<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignUsersMutation,
  UnassignUsersMutationVariables,
  UnassignUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignUsersMutation, UnassignUsersMutationVariables, UnassignUsersProps<TChildProps, TDataName>>(UnassignUsersDocument, {
      alias: 'unassignUsers',
      ...operationOptions
    });
};

/**
 * __useUnassignUsersMutation__
 *
 * To run a mutation, you first call `useUnassignUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignUsersMutation, { data, loading, error }] = useUnassignUsersMutation({
 *   variables: {
 *      siteUsers: // value for 'siteUsers'
 *   },
 * });
 */
export function useUnassignUsersMutation(baseOptions?: Apollo.MutationHookOptions<UnassignUsersMutation, UnassignUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignUsersMutation, UnassignUsersMutationVariables>(UnassignUsersDocument, options);
      }
export type UnassignUsersMutationHookResult = ReturnType<typeof useUnassignUsersMutation>;
export type UnassignUsersMutationResult = Apollo.MutationResult<UnassignUsersMutation>;
export type UnassignUsersMutationOptions = Apollo.BaseMutationOptions<UnassignUsersMutation, UnassignUsersMutationVariables>;
export const GetAlarmsHeatMapDocument = gql`
    query GetAlarmsHeatMap($params: SiteDashboardInput!) {
  siteDashboardDetails(params: $params) {
    alarmsHeatMap {
      alarmsCount
      date
    }
  }
}
    `;
export type GetAlarmsHeatMapComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables>, 'query'> & ({ variables: GetAlarmsHeatMapQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAlarmsHeatMapComponent = (props: GetAlarmsHeatMapComponentProps) => (
      <ApolloReactComponents.Query<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables> query={GetAlarmsHeatMapDocument} {...props} />
    );
    
export type GetAlarmsHeatMapProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables>
    } & TChildProps;
export function withGetAlarmsHeatMap<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAlarmsHeatMapQuery,
  GetAlarmsHeatMapQueryVariables,
  GetAlarmsHeatMapProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables, GetAlarmsHeatMapProps<TChildProps, TDataName>>(GetAlarmsHeatMapDocument, {
      alias: 'getAlarmsHeatMap',
      ...operationOptions
    });
};

/**
 * __useGetAlarmsHeatMapQuery__
 *
 * To run a query within a React component, call `useGetAlarmsHeatMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmsHeatMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmsHeatMapQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetAlarmsHeatMapQuery(baseOptions: Apollo.QueryHookOptions<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables>(GetAlarmsHeatMapDocument, options);
      }
export function useGetAlarmsHeatMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables>(GetAlarmsHeatMapDocument, options);
        }
export type GetAlarmsHeatMapQueryHookResult = ReturnType<typeof useGetAlarmsHeatMapQuery>;
export type GetAlarmsHeatMapLazyQueryHookResult = ReturnType<typeof useGetAlarmsHeatMapLazyQuery>;
export type GetAlarmsHeatMapQueryResult = Apollo.QueryResult<GetAlarmsHeatMapQuery, GetAlarmsHeatMapQueryVariables>;
export function refetchGetAlarmsHeatMapQuery(variables: GetAlarmsHeatMapQueryVariables) {
      return { query: GetAlarmsHeatMapDocument, variables: variables }
    }
export const ConnectivityStatusDocument = gql`
    query ConnectivityStatus($params: SiteDashboardInput!) {
  siteDashboardDetails(params: $params) {
    connectivityStatus {
      componentName
      systemId
      connectivity
      componentState
      systemType
      loopUnits
    }
  }
}
    `;
export type ConnectivityStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConnectivityStatusQuery, ConnectivityStatusQueryVariables>, 'query'> & ({ variables: ConnectivityStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConnectivityStatusComponent = (props: ConnectivityStatusComponentProps) => (
      <ApolloReactComponents.Query<ConnectivityStatusQuery, ConnectivityStatusQueryVariables> query={ConnectivityStatusDocument} {...props} />
    );
    
export type ConnectivityStatusProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ConnectivityStatusQuery, ConnectivityStatusQueryVariables>
    } & TChildProps;
export function withConnectivityStatus<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConnectivityStatusQuery,
  ConnectivityStatusQueryVariables,
  ConnectivityStatusProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ConnectivityStatusQuery, ConnectivityStatusQueryVariables, ConnectivityStatusProps<TChildProps, TDataName>>(ConnectivityStatusDocument, {
      alias: 'connectivityStatus',
      ...operationOptions
    });
};

/**
 * __useConnectivityStatusQuery__
 *
 * To run a query within a React component, call `useConnectivityStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectivityStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectivityStatusQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useConnectivityStatusQuery(baseOptions: Apollo.QueryHookOptions<ConnectivityStatusQuery, ConnectivityStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectivityStatusQuery, ConnectivityStatusQueryVariables>(ConnectivityStatusDocument, options);
      }
export function useConnectivityStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectivityStatusQuery, ConnectivityStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectivityStatusQuery, ConnectivityStatusQueryVariables>(ConnectivityStatusDocument, options);
        }
export type ConnectivityStatusQueryHookResult = ReturnType<typeof useConnectivityStatusQuery>;
export type ConnectivityStatusLazyQueryHookResult = ReturnType<typeof useConnectivityStatusLazyQuery>;
export type ConnectivityStatusQueryResult = Apollo.QueryResult<ConnectivityStatusQuery, ConnectivityStatusQueryVariables>;
export function refetchConnectivityStatusQuery(variables: ConnectivityStatusQueryVariables) {
      return { query: ConnectivityStatusDocument, variables: variables }
    }
export const GetEventStatusDocument = gql`
    query GetEventStatus($params: SiteDashboardInput!) {
  siteDashboardDetails(params: $params) {
    eventStatus {
      type
      value
    }
  }
}
    `;
export type GetEventStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEventStatusQuery, GetEventStatusQueryVariables>, 'query'> & ({ variables: GetEventStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEventStatusComponent = (props: GetEventStatusComponentProps) => (
      <ApolloReactComponents.Query<GetEventStatusQuery, GetEventStatusQueryVariables> query={GetEventStatusDocument} {...props} />
    );
    
export type GetEventStatusProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetEventStatusQuery, GetEventStatusQueryVariables>
    } & TChildProps;
export function withGetEventStatus<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEventStatusQuery,
  GetEventStatusQueryVariables,
  GetEventStatusProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetEventStatusQuery, GetEventStatusQueryVariables, GetEventStatusProps<TChildProps, TDataName>>(GetEventStatusDocument, {
      alias: 'getEventStatus',
      ...operationOptions
    });
};

/**
 * __useGetEventStatusQuery__
 *
 * To run a query within a React component, call `useGetEventStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventStatusQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetEventStatusQuery(baseOptions: Apollo.QueryHookOptions<GetEventStatusQuery, GetEventStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventStatusQuery, GetEventStatusQueryVariables>(GetEventStatusDocument, options);
      }
export function useGetEventStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventStatusQuery, GetEventStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventStatusQuery, GetEventStatusQueryVariables>(GetEventStatusDocument, options);
        }
export type GetEventStatusQueryHookResult = ReturnType<typeof useGetEventStatusQuery>;
export type GetEventStatusLazyQueryHookResult = ReturnType<typeof useGetEventStatusLazyQuery>;
export type GetEventStatusQueryResult = Apollo.QueryResult<GetEventStatusQuery, GetEventStatusQueryVariables>;
export function refetchGetEventStatusQuery(variables: GetEventStatusQueryVariables) {
      return { query: GetEventStatusDocument, variables: variables }
    }
export const GetAlarmsByDateDocument = gql`
    query GetAlarmsByDate($params: SiteDashboardInput!) {
  siteDashboardDetails(params: $params) {
    alarmsByDate {
      date
      events
    }
  }
}
    `;
export type GetAlarmsByDateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables>, 'query'> & ({ variables: GetAlarmsByDateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAlarmsByDateComponent = (props: GetAlarmsByDateComponentProps) => (
      <ApolloReactComponents.Query<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables> query={GetAlarmsByDateDocument} {...props} />
    );
    
export type GetAlarmsByDateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables>
    } & TChildProps;
export function withGetAlarmsByDate<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAlarmsByDateQuery,
  GetAlarmsByDateQueryVariables,
  GetAlarmsByDateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables, GetAlarmsByDateProps<TChildProps, TDataName>>(GetAlarmsByDateDocument, {
      alias: 'getAlarmsByDate',
      ...operationOptions
    });
};

/**
 * __useGetAlarmsByDateQuery__
 *
 * To run a query within a React component, call `useGetAlarmsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmsByDateQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetAlarmsByDateQuery(baseOptions: Apollo.QueryHookOptions<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables>(GetAlarmsByDateDocument, options);
      }
export function useGetAlarmsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables>(GetAlarmsByDateDocument, options);
        }
export type GetAlarmsByDateQueryHookResult = ReturnType<typeof useGetAlarmsByDateQuery>;
export type GetAlarmsByDateLazyQueryHookResult = ReturnType<typeof useGetAlarmsByDateLazyQuery>;
export type GetAlarmsByDateQueryResult = Apollo.QueryResult<GetAlarmsByDateQuery, GetAlarmsByDateQueryVariables>;
export function refetchGetAlarmsByDateQuery(variables: GetAlarmsByDateQueryVariables) {
      return { query: GetAlarmsByDateDocument, variables: variables }
    }
export const GetGatewaysDataDocument = gql`
    query GetGatewaysData($params: SiteDashboardInput!) {
  siteDashboardDetails(params: $params) {
    gateways {
      total
      connected
      notConnected
    }
  }
}
    `;
export type GetGatewaysDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetGatewaysDataQuery, GetGatewaysDataQueryVariables>, 'query'> & ({ variables: GetGatewaysDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetGatewaysDataComponent = (props: GetGatewaysDataComponentProps) => (
      <ApolloReactComponents.Query<GetGatewaysDataQuery, GetGatewaysDataQueryVariables> query={GetGatewaysDataDocument} {...props} />
    );
    
export type GetGatewaysDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetGatewaysDataQuery, GetGatewaysDataQueryVariables>
    } & TChildProps;
export function withGetGatewaysData<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetGatewaysDataQuery,
  GetGatewaysDataQueryVariables,
  GetGatewaysDataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetGatewaysDataQuery, GetGatewaysDataQueryVariables, GetGatewaysDataProps<TChildProps, TDataName>>(GetGatewaysDataDocument, {
      alias: 'getGatewaysData',
      ...operationOptions
    });
};

/**
 * __useGetGatewaysDataQuery__
 *
 * To run a query within a React component, call `useGetGatewaysDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGatewaysDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGatewaysDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGatewaysDataQuery(baseOptions: Apollo.QueryHookOptions<GetGatewaysDataQuery, GetGatewaysDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGatewaysDataQuery, GetGatewaysDataQueryVariables>(GetGatewaysDataDocument, options);
      }
export function useGetGatewaysDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGatewaysDataQuery, GetGatewaysDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGatewaysDataQuery, GetGatewaysDataQueryVariables>(GetGatewaysDataDocument, options);
        }
export type GetGatewaysDataQueryHookResult = ReturnType<typeof useGetGatewaysDataQuery>;
export type GetGatewaysDataLazyQueryHookResult = ReturnType<typeof useGetGatewaysDataLazyQuery>;
export type GetGatewaysDataQueryResult = Apollo.QueryResult<GetGatewaysDataQuery, GetGatewaysDataQueryVariables>;
export function refetchGetGatewaysDataQuery(variables: GetGatewaysDataQueryVariables) {
      return { query: GetGatewaysDataDocument, variables: variables }
    }
export const GetUnitsDataDocument = gql`
    query GetUnitsData($params: SiteDashboardInput!) {
  siteDashboardDetails(params: $params) {
    units {
      total
      activeAlarms
    }
  }
}
    `;
export type GetUnitsDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnitsDataQuery, GetUnitsDataQueryVariables>, 'query'> & ({ variables: GetUnitsDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnitsDataComponent = (props: GetUnitsDataComponentProps) => (
      <ApolloReactComponents.Query<GetUnitsDataQuery, GetUnitsDataQueryVariables> query={GetUnitsDataDocument} {...props} />
    );
    
export type GetUnitsDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUnitsDataQuery, GetUnitsDataQueryVariables>
    } & TChildProps;
export function withGetUnitsData<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnitsDataQuery,
  GetUnitsDataQueryVariables,
  GetUnitsDataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnitsDataQuery, GetUnitsDataQueryVariables, GetUnitsDataProps<TChildProps, TDataName>>(GetUnitsDataDocument, {
      alias: 'getUnitsData',
      ...operationOptions
    });
};

/**
 * __useGetUnitsDataQuery__
 *
 * To run a query within a React component, call `useGetUnitsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetUnitsDataQuery(baseOptions: Apollo.QueryHookOptions<GetUnitsDataQuery, GetUnitsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsDataQuery, GetUnitsDataQueryVariables>(GetUnitsDataDocument, options);
      }
export function useGetUnitsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsDataQuery, GetUnitsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsDataQuery, GetUnitsDataQueryVariables>(GetUnitsDataDocument, options);
        }
export type GetUnitsDataQueryHookResult = ReturnType<typeof useGetUnitsDataQuery>;
export type GetUnitsDataLazyQueryHookResult = ReturnType<typeof useGetUnitsDataLazyQuery>;
export type GetUnitsDataQueryResult = Apollo.QueryResult<GetUnitsDataQuery, GetUnitsDataQueryVariables>;
export function refetchGetUnitsDataQuery(variables: GetUnitsDataQueryVariables) {
      return { query: GetUnitsDataDocument, variables: variables }
    }
export const GetDeviceElementsDocument = gql`
    query GetDeviceElements($siteId: String!, $systemId: String, $deviceId: String, $limit: Int, $businessUnit: String, $sortBy: String) {
  devicesBySiteId(
    siteId: $siteId
    systemId: $systemId
    deviceId: $deviceId
    limit: $limit
    businessUnit: $businessUnit
    sortBy: $sortBy
  ) {
    devices {
      id
      name
      type
      parentDevice {
        id
        name
        type
      }
    }
    totalCount
  }
}
    `;
export type GetDeviceElementsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDeviceElementsQuery, GetDeviceElementsQueryVariables>, 'query'> & ({ variables: GetDeviceElementsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDeviceElementsComponent = (props: GetDeviceElementsComponentProps) => (
      <ApolloReactComponents.Query<GetDeviceElementsQuery, GetDeviceElementsQueryVariables> query={GetDeviceElementsDocument} {...props} />
    );
    
export type GetDeviceElementsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDeviceElementsQuery, GetDeviceElementsQueryVariables>
    } & TChildProps;
export function withGetDeviceElements<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDeviceElementsQuery,
  GetDeviceElementsQueryVariables,
  GetDeviceElementsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDeviceElementsQuery, GetDeviceElementsQueryVariables, GetDeviceElementsProps<TChildProps, TDataName>>(GetDeviceElementsDocument, {
      alias: 'getDeviceElements',
      ...operationOptions
    });
};

/**
 * __useGetDeviceElementsQuery__
 *
 * To run a query within a React component, call `useGetDeviceElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceElementsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      systemId: // value for 'systemId'
 *      deviceId: // value for 'deviceId'
 *      limit: // value for 'limit'
 *      businessUnit: // value for 'businessUnit'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetDeviceElementsQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceElementsQuery, GetDeviceElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceElementsQuery, GetDeviceElementsQueryVariables>(GetDeviceElementsDocument, options);
      }
export function useGetDeviceElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceElementsQuery, GetDeviceElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceElementsQuery, GetDeviceElementsQueryVariables>(GetDeviceElementsDocument, options);
        }
export type GetDeviceElementsQueryHookResult = ReturnType<typeof useGetDeviceElementsQuery>;
export type GetDeviceElementsLazyQueryHookResult = ReturnType<typeof useGetDeviceElementsLazyQuery>;
export type GetDeviceElementsQueryResult = Apollo.QueryResult<GetDeviceElementsQuery, GetDeviceElementsQueryVariables>;
export function refetchGetDeviceElementsQuery(variables: GetDeviceElementsQueryVariables) {
      return { query: GetDeviceElementsDocument, variables: variables }
    }
export const GetDevicesDocument = gql`
    query GetDevices($siteId: String!, $systemId: String, $deviceId: String, $limit: Int, $businessUnit: String) {
  devicesBySiteId(
    siteId: $siteId
    systemId: $systemId
    deviceId: $deviceId
    limit: $limit
    businessUnit: $businessUnit
  ) {
    devices {
      id
      name
      state
      model
      type
      locationDetails
      deviceId
      createdAt
      serialNumber
    }
    totalCount
  }
}
    `;
export type GetDevicesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDevicesQuery, GetDevicesQueryVariables>, 'query'> & ({ variables: GetDevicesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDevicesComponent = (props: GetDevicesComponentProps) => (
      <ApolloReactComponents.Query<GetDevicesQuery, GetDevicesQueryVariables> query={GetDevicesDocument} {...props} />
    );
    
export type GetDevicesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDevicesQuery, GetDevicesQueryVariables>
    } & TChildProps;
export function withGetDevices<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDevicesQuery,
  GetDevicesQueryVariables,
  GetDevicesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDevicesQuery, GetDevicesQueryVariables, GetDevicesProps<TChildProps, TDataName>>(GetDevicesDocument, {
      alias: 'getDevices',
      ...operationOptions
    });
};

/**
 * __useGetDevicesQuery__
 *
 * To run a query within a React component, call `useGetDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      systemId: // value for 'systemId'
 *      deviceId: // value for 'deviceId'
 *      limit: // value for 'limit'
 *      businessUnit: // value for 'businessUnit'
 *   },
 * });
 */
export function useGetDevicesQuery(baseOptions: Apollo.QueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
      }
export function useGetDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
        }
export type GetDevicesQueryHookResult = ReturnType<typeof useGetDevicesQuery>;
export type GetDevicesLazyQueryHookResult = ReturnType<typeof useGetDevicesLazyQuery>;
export type GetDevicesQueryResult = Apollo.QueryResult<GetDevicesQuery, GetDevicesQueryVariables>;
export function refetchGetDevicesQuery(variables: GetDevicesQueryVariables) {
      return { query: GetDevicesDocument, variables: variables }
    }
export const GetEventDetailsDocument = gql`
    query GetEventDetails($eventDetailsId: String!) {
  eventDetails(id: $eventDetailsId) {
    id
    systemId
    deviceName
    deviceId
    eventTimestamp
    eventType
    eventDescription
    eventName
    eventValue
    maintenanceQueue
    severity
    status
    topic
    inverted
    updatedAt
    buDeviceType
    blockName
  }
}
    `;
export type GetEventDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEventDetailsQuery, GetEventDetailsQueryVariables>, 'query'> & ({ variables: GetEventDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEventDetailsComponent = (props: GetEventDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetEventDetailsQuery, GetEventDetailsQueryVariables> query={GetEventDetailsDocument} {...props} />
    );
    
export type GetEventDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetEventDetailsQuery, GetEventDetailsQueryVariables>
    } & TChildProps;
export function withGetEventDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEventDetailsQuery,
  GetEventDetailsQueryVariables,
  GetEventDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetEventDetailsQuery, GetEventDetailsQueryVariables, GetEventDetailsProps<TChildProps, TDataName>>(GetEventDetailsDocument, {
      alias: 'getEventDetails',
      ...operationOptions
    });
};

/**
 * __useGetEventDetailsQuery__
 *
 * To run a query within a React component, call `useGetEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDetailsQuery({
 *   variables: {
 *      eventDetailsId: // value for 'eventDetailsId'
 *   },
 * });
 */
export function useGetEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEventDetailsQuery, GetEventDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventDetailsQuery, GetEventDetailsQueryVariables>(GetEventDetailsDocument, options);
      }
export function useGetEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventDetailsQuery, GetEventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventDetailsQuery, GetEventDetailsQueryVariables>(GetEventDetailsDocument, options);
        }
export type GetEventDetailsQueryHookResult = ReturnType<typeof useGetEventDetailsQuery>;
export type GetEventDetailsLazyQueryHookResult = ReturnType<typeof useGetEventDetailsLazyQuery>;
export type GetEventDetailsQueryResult = Apollo.QueryResult<GetEventDetailsQuery, GetEventDetailsQueryVariables>;
export function refetchGetEventDetailsQuery(variables: GetEventDetailsQueryVariables) {
      return { query: GetEventDetailsDocument, variables: variables }
    }
export const GetEventsHistoryDataDocument = gql`
    query GetEventsHistoryData($eventsHistoryDataId: String!, $eventType: EventType, $pageNo: Int, $limit: Int) {
  eventsHistoryData(
    id: $eventsHistoryDataId
    eventType: $eventType
    pageNo: $pageNo
    limit: $limit
  ) {
    totalCount
    historyData {
      id
      eventTimestamp
      status
      eventValue
    }
  }
}
    `;
export type GetEventsHistoryDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables>, 'query'> & ({ variables: GetEventsHistoryDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEventsHistoryDataComponent = (props: GetEventsHistoryDataComponentProps) => (
      <ApolloReactComponents.Query<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables> query={GetEventsHistoryDataDocument} {...props} />
    );
    
export type GetEventsHistoryDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables>
    } & TChildProps;
export function withGetEventsHistoryData<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEventsHistoryDataQuery,
  GetEventsHistoryDataQueryVariables,
  GetEventsHistoryDataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables, GetEventsHistoryDataProps<TChildProps, TDataName>>(GetEventsHistoryDataDocument, {
      alias: 'getEventsHistoryData',
      ...operationOptions
    });
};

/**
 * __useGetEventsHistoryDataQuery__
 *
 * To run a query within a React component, call `useGetEventsHistoryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsHistoryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsHistoryDataQuery({
 *   variables: {
 *      eventsHistoryDataId: // value for 'eventsHistoryDataId'
 *      eventType: // value for 'eventType'
 *      pageNo: // value for 'pageNo'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEventsHistoryDataQuery(baseOptions: Apollo.QueryHookOptions<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables>(GetEventsHistoryDataDocument, options);
      }
export function useGetEventsHistoryDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables>(GetEventsHistoryDataDocument, options);
        }
export type GetEventsHistoryDataQueryHookResult = ReturnType<typeof useGetEventsHistoryDataQuery>;
export type GetEventsHistoryDataLazyQueryHookResult = ReturnType<typeof useGetEventsHistoryDataLazyQuery>;
export type GetEventsHistoryDataQueryResult = Apollo.QueryResult<GetEventsHistoryDataQuery, GetEventsHistoryDataQueryVariables>;
export function refetchGetEventsHistoryDataQuery(variables: GetEventsHistoryDataQueryVariables) {
      return { query: GetEventsHistoryDataDocument, variables: variables }
    }
export const GetEventsBySiteDocument = gql`
    query GetEventsBySite($eventType: EventType!, $siteId: String!, $showActive: Boolean) {
  eventsBySiteId(eventType: $eventType, siteId: $siteId, showActive: $showActive) {
    id
    systemId
    deviceName
    deviceId
    eventTimestamp
    eventType
    eventDescription
    eventName
    eventValue
    maintenanceQueue
    severity
    status
    topic
    inverted
    updatedAt
  }
}
    `;
export type GetEventsBySiteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEventsBySiteQuery, GetEventsBySiteQueryVariables>, 'query'> & ({ variables: GetEventsBySiteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEventsBySiteComponent = (props: GetEventsBySiteComponentProps) => (
      <ApolloReactComponents.Query<GetEventsBySiteQuery, GetEventsBySiteQueryVariables> query={GetEventsBySiteDocument} {...props} />
    );
    
export type GetEventsBySiteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetEventsBySiteQuery, GetEventsBySiteQueryVariables>
    } & TChildProps;
export function withGetEventsBySite<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEventsBySiteQuery,
  GetEventsBySiteQueryVariables,
  GetEventsBySiteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetEventsBySiteQuery, GetEventsBySiteQueryVariables, GetEventsBySiteProps<TChildProps, TDataName>>(GetEventsBySiteDocument, {
      alias: 'getEventsBySite',
      ...operationOptions
    });
};

/**
 * __useGetEventsBySiteQuery__
 *
 * To run a query within a React component, call `useGetEventsBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsBySiteQuery({
 *   variables: {
 *      eventType: // value for 'eventType'
 *      siteId: // value for 'siteId'
 *      showActive: // value for 'showActive'
 *   },
 * });
 */
export function useGetEventsBySiteQuery(baseOptions: Apollo.QueryHookOptions<GetEventsBySiteQuery, GetEventsBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsBySiteQuery, GetEventsBySiteQueryVariables>(GetEventsBySiteDocument, options);
      }
export function useGetEventsBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsBySiteQuery, GetEventsBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsBySiteQuery, GetEventsBySiteQueryVariables>(GetEventsBySiteDocument, options);
        }
export type GetEventsBySiteQueryHookResult = ReturnType<typeof useGetEventsBySiteQuery>;
export type GetEventsBySiteLazyQueryHookResult = ReturnType<typeof useGetEventsBySiteLazyQuery>;
export type GetEventsBySiteQueryResult = Apollo.QueryResult<GetEventsBySiteQuery, GetEventsBySiteQueryVariables>;
export function refetchGetEventsBySiteQuery(variables: GetEventsBySiteQueryVariables) {
      return { query: GetEventsBySiteDocument, variables: variables }
    }
export const GetSiteDetailsDocument = gql`
    query GetSiteDetails($siteWithUsersId: String!) {
  siteDetails(siteId: $siteWithUsersId) {
    id
    userSiteId
    name
    tenantId
    address1
    address2
    country
    city
    status
    state
    postalCode
    createdAt
    businessUnit
    createdInspectionsCount
    contactPerson {
      firstName
      lastName
      phoneNumber
      email
    }
    tenant {
      id
      name
      address1
      address2
      postalCode
      country
      city
      contactPerson {
        firstName
        lastName
        phoneNumber
        email
      }
      createdAt
    }
  }
}
    `;
export type GetSiteDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>, 'query'> & ({ variables: GetSiteDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSiteDetailsComponent = (props: GetSiteDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetSiteDetailsQuery, GetSiteDetailsQueryVariables> query={GetSiteDetailsDocument} {...props} />
    );
    
export type GetSiteDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>
    } & TChildProps;
export function withGetSiteDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSiteDetailsQuery,
  GetSiteDetailsQueryVariables,
  GetSiteDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSiteDetailsQuery, GetSiteDetailsQueryVariables, GetSiteDetailsProps<TChildProps, TDataName>>(GetSiteDetailsDocument, {
      alias: 'getSiteDetails',
      ...operationOptions
    });
};

/**
 * __useGetSiteDetailsQuery__
 *
 * To run a query within a React component, call `useGetSiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteDetailsQuery({
 *   variables: {
 *      siteWithUsersId: // value for 'siteWithUsersId'
 *   },
 * });
 */
export function useGetSiteDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>(GetSiteDetailsDocument, options);
      }
export function useGetSiteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>(GetSiteDetailsDocument, options);
        }
export type GetSiteDetailsQueryHookResult = ReturnType<typeof useGetSiteDetailsQuery>;
export type GetSiteDetailsLazyQueryHookResult = ReturnType<typeof useGetSiteDetailsLazyQuery>;
export type GetSiteDetailsQueryResult = Apollo.QueryResult<GetSiteDetailsQuery, GetSiteDetailsQueryVariables>;
export function refetchGetSiteDetailsQuery(variables: GetSiteDetailsQueryVariables) {
      return { query: GetSiteDetailsDocument, variables: variables }
    }
export const UpdateSiteWithBusinessUnitDocument = gql`
    mutation UpdateSiteWithBusinessUnit($siteId: String!, $businessUnit: BusinessUnit!) {
  updateSiteWithBusinessUnit(siteId: $siteId, businessUnit: $businessUnit)
}
    `;
export type UpdateSiteWithBusinessUnitMutationFn = Apollo.MutationFunction<UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables>;
export type UpdateSiteWithBusinessUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables>, 'mutation'>;

    export const UpdateSiteWithBusinessUnitComponent = (props: UpdateSiteWithBusinessUnitComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables> mutation={UpdateSiteWithBusinessUnitDocument} {...props} />
    );
    
export type UpdateSiteWithBusinessUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables>
    } & TChildProps;
export function withUpdateSiteWithBusinessUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSiteWithBusinessUnitMutation,
  UpdateSiteWithBusinessUnitMutationVariables,
  UpdateSiteWithBusinessUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables, UpdateSiteWithBusinessUnitProps<TChildProps, TDataName>>(UpdateSiteWithBusinessUnitDocument, {
      alias: 'updateSiteWithBusinessUnit',
      ...operationOptions
    });
};

/**
 * __useUpdateSiteWithBusinessUnitMutation__
 *
 * To run a mutation, you first call `useUpdateSiteWithBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteWithBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteWithBusinessUnitMutation, { data, loading, error }] = useUpdateSiteWithBusinessUnitMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      businessUnit: // value for 'businessUnit'
 *   },
 * });
 */
export function useUpdateSiteWithBusinessUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables>(UpdateSiteWithBusinessUnitDocument, options);
      }
export type UpdateSiteWithBusinessUnitMutationHookResult = ReturnType<typeof useUpdateSiteWithBusinessUnitMutation>;
export type UpdateSiteWithBusinessUnitMutationResult = Apollo.MutationResult<UpdateSiteWithBusinessUnitMutation>;
export type UpdateSiteWithBusinessUnitMutationOptions = Apollo.BaseMutationOptions<UpdateSiteWithBusinessUnitMutation, UpdateSiteWithBusinessUnitMutationVariables>;
export const GetFalseActivationSystemAnalyticsDocument = gql`
    query GetFalseActivationSystemAnalytics($input: GetSiteSystemAnalyticsInput!) {
  siteSystemAnalytics(input: $input) {
    falseActivation {
      eventDate
      activationTimeLimit
      averageStandByPumpingTimeSec
      standByFrequency
      standByPumpingStatus
    }
  }
}
    `;
export type GetFalseActivationSystemAnalyticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables>, 'query'> & ({ variables: GetFalseActivationSystemAnalyticsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetFalseActivationSystemAnalyticsComponent = (props: GetFalseActivationSystemAnalyticsComponentProps) => (
      <ApolloReactComponents.Query<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables> query={GetFalseActivationSystemAnalyticsDocument} {...props} />
    );
    
export type GetFalseActivationSystemAnalyticsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables>
    } & TChildProps;
export function withGetFalseActivationSystemAnalytics<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetFalseActivationSystemAnalyticsQuery,
  GetFalseActivationSystemAnalyticsQueryVariables,
  GetFalseActivationSystemAnalyticsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables, GetFalseActivationSystemAnalyticsProps<TChildProps, TDataName>>(GetFalseActivationSystemAnalyticsDocument, {
      alias: 'getFalseActivationSystemAnalytics',
      ...operationOptions
    });
};

/**
 * __useGetFalseActivationSystemAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetFalseActivationSystemAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFalseActivationSystemAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFalseActivationSystemAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFalseActivationSystemAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables>(GetFalseActivationSystemAnalyticsDocument, options);
      }
export function useGetFalseActivationSystemAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables>(GetFalseActivationSystemAnalyticsDocument, options);
        }
export type GetFalseActivationSystemAnalyticsQueryHookResult = ReturnType<typeof useGetFalseActivationSystemAnalyticsQuery>;
export type GetFalseActivationSystemAnalyticsLazyQueryHookResult = ReturnType<typeof useGetFalseActivationSystemAnalyticsLazyQuery>;
export type GetFalseActivationSystemAnalyticsQueryResult = Apollo.QueryResult<GetFalseActivationSystemAnalyticsQuery, GetFalseActivationSystemAnalyticsQueryVariables>;
export function refetchGetFalseActivationSystemAnalyticsQuery(variables: GetFalseActivationSystemAnalyticsQueryVariables) {
      return { query: GetFalseActivationSystemAnalyticsDocument, variables: variables }
    }
export const GetPumpConditionMonitoringDocument = gql`
    query GetPumpConditionMonitoring($input: GetPumpConditionMonitoringInput!) {
  pumpConditionMonitoring(input: $input) {
    numberOfPumps
    thresholdPressure
    averageTargetPressure
    pumpMonitoring {
      date
      data {
        motor
        pressure
        color
      }
    }
    pressureTrend {
      motor
      pressure
      arrow
    }
  }
}
    `;
export type GetPumpConditionMonitoringComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables>, 'query'> & ({ variables: GetPumpConditionMonitoringQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPumpConditionMonitoringComponent = (props: GetPumpConditionMonitoringComponentProps) => (
      <ApolloReactComponents.Query<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables> query={GetPumpConditionMonitoringDocument} {...props} />
    );
    
export type GetPumpConditionMonitoringProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables>
    } & TChildProps;
export function withGetPumpConditionMonitoring<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPumpConditionMonitoringQuery,
  GetPumpConditionMonitoringQueryVariables,
  GetPumpConditionMonitoringProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables, GetPumpConditionMonitoringProps<TChildProps, TDataName>>(GetPumpConditionMonitoringDocument, {
      alias: 'getPumpConditionMonitoring',
      ...operationOptions
    });
};

/**
 * __useGetPumpConditionMonitoringQuery__
 *
 * To run a query within a React component, call `useGetPumpConditionMonitoringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPumpConditionMonitoringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPumpConditionMonitoringQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPumpConditionMonitoringQuery(baseOptions: Apollo.QueryHookOptions<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables>(GetPumpConditionMonitoringDocument, options);
      }
export function useGetPumpConditionMonitoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables>(GetPumpConditionMonitoringDocument, options);
        }
export type GetPumpConditionMonitoringQueryHookResult = ReturnType<typeof useGetPumpConditionMonitoringQuery>;
export type GetPumpConditionMonitoringLazyQueryHookResult = ReturnType<typeof useGetPumpConditionMonitoringLazyQuery>;
export type GetPumpConditionMonitoringQueryResult = Apollo.QueryResult<GetPumpConditionMonitoringQuery, GetPumpConditionMonitoringQueryVariables>;
export function refetchGetPumpConditionMonitoringQuery(variables: GetPumpConditionMonitoringQueryVariables) {
      return { query: GetPumpConditionMonitoringDocument, variables: variables }
    }
export const CreateSystemDocument = gql`
    mutation CreateSystem($siteId: String!, $input: CreateSystemInput!) {
  createSystem(siteId: $siteId, input: $input) {
    id
    name
    type
    buSystemId
    state
    isArchived
    isConfigUploaded
    createdAt
    createdBy {
      id
      emailAddress
      firstName
      lastName
    }
  }
}
    `;
export type CreateSystemMutationFn = Apollo.MutationFunction<CreateSystemMutation, CreateSystemMutationVariables>;
export type CreateSystemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSystemMutation, CreateSystemMutationVariables>, 'mutation'>;

    export const CreateSystemComponent = (props: CreateSystemComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSystemMutation, CreateSystemMutationVariables> mutation={CreateSystemDocument} {...props} />
    );
    
export type CreateSystemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateSystemMutation, CreateSystemMutationVariables>
    } & TChildProps;
export function withCreateSystem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSystemMutation,
  CreateSystemMutationVariables,
  CreateSystemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSystemMutation, CreateSystemMutationVariables, CreateSystemProps<TChildProps, TDataName>>(CreateSystemDocument, {
      alias: 'createSystem',
      ...operationOptions
    });
};

/**
 * __useCreateSystemMutation__
 *
 * To run a mutation, you first call `useCreateSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemMutation, { data, loading, error }] = useCreateSystemMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSystemMutation(baseOptions?: Apollo.MutationHookOptions<CreateSystemMutation, CreateSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSystemMutation, CreateSystemMutationVariables>(CreateSystemDocument, options);
      }
export type CreateSystemMutationHookResult = ReturnType<typeof useCreateSystemMutation>;
export type CreateSystemMutationResult = Apollo.MutationResult<CreateSystemMutation>;
export type CreateSystemMutationOptions = Apollo.BaseMutationOptions<CreateSystemMutation, CreateSystemMutationVariables>;
export const DeleteSystemDocument = gql`
    mutation DeleteSystem($deleteSystemId: String!) {
  deleteSystem(id: $deleteSystemId)
}
    `;
export type DeleteSystemMutationFn = Apollo.MutationFunction<DeleteSystemMutation, DeleteSystemMutationVariables>;
export type DeleteSystemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSystemMutation, DeleteSystemMutationVariables>, 'mutation'>;

    export const DeleteSystemComponent = (props: DeleteSystemComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSystemMutation, DeleteSystemMutationVariables> mutation={DeleteSystemDocument} {...props} />
    );
    
export type DeleteSystemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteSystemMutation, DeleteSystemMutationVariables>
    } & TChildProps;
export function withDeleteSystem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteSystemMutation,
  DeleteSystemMutationVariables,
  DeleteSystemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteSystemMutation, DeleteSystemMutationVariables, DeleteSystemProps<TChildProps, TDataName>>(DeleteSystemDocument, {
      alias: 'deleteSystem',
      ...operationOptions
    });
};

/**
 * __useDeleteSystemMutation__
 *
 * To run a mutation, you first call `useDeleteSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemMutation, { data, loading, error }] = useDeleteSystemMutation({
 *   variables: {
 *      deleteSystemId: // value for 'deleteSystemId'
 *   },
 * });
 */
export function useDeleteSystemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSystemMutation, DeleteSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSystemMutation, DeleteSystemMutationVariables>(DeleteSystemDocument, options);
      }
export type DeleteSystemMutationHookResult = ReturnType<typeof useDeleteSystemMutation>;
export type DeleteSystemMutationResult = Apollo.MutationResult<DeleteSystemMutation>;
export type DeleteSystemMutationOptions = Apollo.BaseMutationOptions<DeleteSystemMutation, DeleteSystemMutationVariables>;
export const DownloadSystemConfigurationDocument = gql`
    query DownloadSystemConfiguration($id: ID!) {
  downloadSystemConfiguration(id: $id)
}
    `;
export type DownloadSystemConfigurationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables>, 'query'> & ({ variables: DownloadSystemConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DownloadSystemConfigurationComponent = (props: DownloadSystemConfigurationComponentProps) => (
      <ApolloReactComponents.Query<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables> query={DownloadSystemConfigurationDocument} {...props} />
    );
    
export type DownloadSystemConfigurationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables>
    } & TChildProps;
export function withDownloadSystemConfiguration<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadSystemConfigurationQuery,
  DownloadSystemConfigurationQueryVariables,
  DownloadSystemConfigurationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables, DownloadSystemConfigurationProps<TChildProps, TDataName>>(DownloadSystemConfigurationDocument, {
      alias: 'downloadSystemConfiguration',
      ...operationOptions
    });
};

/**
 * __useDownloadSystemConfigurationQuery__
 *
 * To run a query within a React component, call `useDownloadSystemConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadSystemConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadSystemConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadSystemConfigurationQuery(baseOptions: Apollo.QueryHookOptions<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables>(DownloadSystemConfigurationDocument, options);
      }
export function useDownloadSystemConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables>(DownloadSystemConfigurationDocument, options);
        }
export type DownloadSystemConfigurationQueryHookResult = ReturnType<typeof useDownloadSystemConfigurationQuery>;
export type DownloadSystemConfigurationLazyQueryHookResult = ReturnType<typeof useDownloadSystemConfigurationLazyQuery>;
export type DownloadSystemConfigurationQueryResult = Apollo.QueryResult<DownloadSystemConfigurationQuery, DownloadSystemConfigurationQueryVariables>;
export function refetchDownloadSystemConfigurationQuery(variables: DownloadSystemConfigurationQueryVariables) {
      return { query: DownloadSystemConfigurationDocument, variables: variables }
    }
export const RequestLatestSystemConfigurationDocument = gql`
    query RequestLatestSystemConfiguration($systemId: String!) {
  requestLatestSystemConfiguration(systemId: $systemId)
}
    `;
export type RequestLatestSystemConfigurationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables>, 'query'> & ({ variables: RequestLatestSystemConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RequestLatestSystemConfigurationComponent = (props: RequestLatestSystemConfigurationComponentProps) => (
      <ApolloReactComponents.Query<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables> query={RequestLatestSystemConfigurationDocument} {...props} />
    );
    
export type RequestLatestSystemConfigurationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables>
    } & TChildProps;
export function withRequestLatestSystemConfiguration<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RequestLatestSystemConfigurationQuery,
  RequestLatestSystemConfigurationQueryVariables,
  RequestLatestSystemConfigurationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables, RequestLatestSystemConfigurationProps<TChildProps, TDataName>>(RequestLatestSystemConfigurationDocument, {
      alias: 'requestLatestSystemConfiguration',
      ...operationOptions
    });
};

/**
 * __useRequestLatestSystemConfigurationQuery__
 *
 * To run a query within a React component, call `useRequestLatestSystemConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestLatestSystemConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestLatestSystemConfigurationQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useRequestLatestSystemConfigurationQuery(baseOptions: Apollo.QueryHookOptions<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables>(RequestLatestSystemConfigurationDocument, options);
      }
export function useRequestLatestSystemConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables>(RequestLatestSystemConfigurationDocument, options);
        }
export type RequestLatestSystemConfigurationQueryHookResult = ReturnType<typeof useRequestLatestSystemConfigurationQuery>;
export type RequestLatestSystemConfigurationLazyQueryHookResult = ReturnType<typeof useRequestLatestSystemConfigurationLazyQuery>;
export type RequestLatestSystemConfigurationQueryResult = Apollo.QueryResult<RequestLatestSystemConfigurationQuery, RequestLatestSystemConfigurationQueryVariables>;
export function refetchRequestLatestSystemConfigurationQuery(variables: RequestLatestSystemConfigurationQueryVariables) {
      return { query: RequestLatestSystemConfigurationDocument, variables: variables }
    }
export const SystemConfigsWithSystemIdDocument = gql`
    query SystemConfigsWithSystemId($systemId: String!) {
  systemConfigsWithSystemId(systemId: $systemId) {
    createdAt
    name
    createdBy
    filePath
    id
    checksum
    system {
      buSystemId
      type
    }
    user {
      id
      firstName
      lastName
    }
  }
}
    `;
export type SystemConfigsWithSystemIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables>, 'query'> & ({ variables: SystemConfigsWithSystemIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SystemConfigsWithSystemIdComponent = (props: SystemConfigsWithSystemIdComponentProps) => (
      <ApolloReactComponents.Query<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables> query={SystemConfigsWithSystemIdDocument} {...props} />
    );
    
export type SystemConfigsWithSystemIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables>
    } & TChildProps;
export function withSystemConfigsWithSystemId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SystemConfigsWithSystemIdQuery,
  SystemConfigsWithSystemIdQueryVariables,
  SystemConfigsWithSystemIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables, SystemConfigsWithSystemIdProps<TChildProps, TDataName>>(SystemConfigsWithSystemIdDocument, {
      alias: 'systemConfigsWithSystemId',
      ...operationOptions
    });
};

/**
 * __useSystemConfigsWithSystemIdQuery__
 *
 * To run a query within a React component, call `useSystemConfigsWithSystemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemConfigsWithSystemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemConfigsWithSystemIdQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useSystemConfigsWithSystemIdQuery(baseOptions: Apollo.QueryHookOptions<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables>(SystemConfigsWithSystemIdDocument, options);
      }
export function useSystemConfigsWithSystemIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables>(SystemConfigsWithSystemIdDocument, options);
        }
export type SystemConfigsWithSystemIdQueryHookResult = ReturnType<typeof useSystemConfigsWithSystemIdQuery>;
export type SystemConfigsWithSystemIdLazyQueryHookResult = ReturnType<typeof useSystemConfigsWithSystemIdLazyQuery>;
export type SystemConfigsWithSystemIdQueryResult = Apollo.QueryResult<SystemConfigsWithSystemIdQuery, SystemConfigsWithSystemIdQueryVariables>;
export function refetchSystemConfigsWithSystemIdQuery(variables: SystemConfigsWithSystemIdQueryVariables) {
      return { query: SystemConfigsWithSystemIdDocument, variables: variables }
    }
export const GetSystemWithSystemIdDocument = gql`
    query GetSystemWithSystemId($systemId: String!) {
  systemWithSystemId(systemId: $systemId) {
    id
    name
    type
    buSystemId
  }
}
    `;
export type GetSystemWithSystemIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables>, 'query'> & ({ variables: GetSystemWithSystemIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSystemWithSystemIdComponent = (props: GetSystemWithSystemIdComponentProps) => (
      <ApolloReactComponents.Query<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables> query={GetSystemWithSystemIdDocument} {...props} />
    );
    
export type GetSystemWithSystemIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables>
    } & TChildProps;
export function withGetSystemWithSystemId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSystemWithSystemIdQuery,
  GetSystemWithSystemIdQueryVariables,
  GetSystemWithSystemIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables, GetSystemWithSystemIdProps<TChildProps, TDataName>>(GetSystemWithSystemIdDocument, {
      alias: 'getSystemWithSystemId',
      ...operationOptions
    });
};

/**
 * __useGetSystemWithSystemIdQuery__
 *
 * To run a query within a React component, call `useGetSystemWithSystemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemWithSystemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemWithSystemIdQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useGetSystemWithSystemIdQuery(baseOptions: Apollo.QueryHookOptions<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables>(GetSystemWithSystemIdDocument, options);
      }
export function useGetSystemWithSystemIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables>(GetSystemWithSystemIdDocument, options);
        }
export type GetSystemWithSystemIdQueryHookResult = ReturnType<typeof useGetSystemWithSystemIdQuery>;
export type GetSystemWithSystemIdLazyQueryHookResult = ReturnType<typeof useGetSystemWithSystemIdLazyQuery>;
export type GetSystemWithSystemIdQueryResult = Apollo.QueryResult<GetSystemWithSystemIdQuery, GetSystemWithSystemIdQueryVariables>;
export function refetchGetSystemWithSystemIdQuery(variables: GetSystemWithSystemIdQueryVariables) {
      return { query: GetSystemWithSystemIdDocument, variables: variables }
    }
export const UpdateSystemDocument = gql`
    mutation UpdateSystem($siteId: String!, $input: UpdateSystemInput!) {
  updateSystem(siteId: $siteId, input: $input) {
    id
  }
}
    `;
export type UpdateSystemMutationFn = Apollo.MutationFunction<UpdateSystemMutation, UpdateSystemMutationVariables>;
export type UpdateSystemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSystemMutation, UpdateSystemMutationVariables>, 'mutation'>;

    export const UpdateSystemComponent = (props: UpdateSystemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSystemMutation, UpdateSystemMutationVariables> mutation={UpdateSystemDocument} {...props} />
    );
    
export type UpdateSystemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateSystemMutation, UpdateSystemMutationVariables>
    } & TChildProps;
export function withUpdateSystem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSystemMutation,
  UpdateSystemMutationVariables,
  UpdateSystemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSystemMutation, UpdateSystemMutationVariables, UpdateSystemProps<TChildProps, TDataName>>(UpdateSystemDocument, {
      alias: 'updateSystem',
      ...operationOptions
    });
};

/**
 * __useUpdateSystemMutation__
 *
 * To run a mutation, you first call `useUpdateSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemMutation, { data, loading, error }] = useUpdateSystemMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSystemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSystemMutation, UpdateSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSystemMutation, UpdateSystemMutationVariables>(UpdateSystemDocument, options);
      }
export type UpdateSystemMutationHookResult = ReturnType<typeof useUpdateSystemMutation>;
export type UpdateSystemMutationResult = Apollo.MutationResult<UpdateSystemMutation>;
export type UpdateSystemMutationOptions = Apollo.BaseMutationOptions<UpdateSystemMutation, UpdateSystemMutationVariables>;
export const CreateInstallationLoopDocument = gql`
    mutation CreateInstallationLoop($loopName: String!, $systemID: String!) {
  createInstallationLoop(loopName: $loopName, systemID: $systemID) {
    id
    name
  }
}
    `;
export type CreateInstallationLoopMutationFn = Apollo.MutationFunction<CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables>;
export type CreateInstallationLoopComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables>, 'mutation'>;

    export const CreateInstallationLoopComponent = (props: CreateInstallationLoopComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables> mutation={CreateInstallationLoopDocument} {...props} />
    );
    
export type CreateInstallationLoopProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables>
    } & TChildProps;
export function withCreateInstallationLoop<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInstallationLoopMutation,
  CreateInstallationLoopMutationVariables,
  CreateInstallationLoopProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables, CreateInstallationLoopProps<TChildProps, TDataName>>(CreateInstallationLoopDocument, {
      alias: 'createInstallationLoop',
      ...operationOptions
    });
};

/**
 * __useCreateInstallationLoopMutation__
 *
 * To run a mutation, you first call `useCreateInstallationLoopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstallationLoopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstallationLoopMutation, { data, loading, error }] = useCreateInstallationLoopMutation({
 *   variables: {
 *      loopName: // value for 'loopName'
 *      systemID: // value for 'systemID'
 *   },
 * });
 */
export function useCreateInstallationLoopMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables>(CreateInstallationLoopDocument, options);
      }
export type CreateInstallationLoopMutationHookResult = ReturnType<typeof useCreateInstallationLoopMutation>;
export type CreateInstallationLoopMutationResult = Apollo.MutationResult<CreateInstallationLoopMutation>;
export type CreateInstallationLoopMutationOptions = Apollo.BaseMutationOptions<CreateInstallationLoopMutation, CreateInstallationLoopMutationVariables>;
export const UpdateInstallationLoopDocument = gql`
    mutation UpdateInstallationLoop($params: UpdateInstallationLoopInput!) {
  updateInstallationLoop(params: $params) {
    id
    name
  }
}
    `;
export type UpdateInstallationLoopMutationFn = Apollo.MutationFunction<UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables>;
export type UpdateInstallationLoopComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables>, 'mutation'>;

    export const UpdateInstallationLoopComponent = (props: UpdateInstallationLoopComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables> mutation={UpdateInstallationLoopDocument} {...props} />
    );
    
export type UpdateInstallationLoopProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables>
    } & TChildProps;
export function withUpdateInstallationLoop<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInstallationLoopMutation,
  UpdateInstallationLoopMutationVariables,
  UpdateInstallationLoopProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables, UpdateInstallationLoopProps<TChildProps, TDataName>>(UpdateInstallationLoopDocument, {
      alias: 'updateInstallationLoop',
      ...operationOptions
    });
};

/**
 * __useUpdateInstallationLoopMutation__
 *
 * To run a mutation, you first call `useUpdateInstallationLoopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstallationLoopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstallationLoopMutation, { data, loading, error }] = useUpdateInstallationLoopMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateInstallationLoopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables>(UpdateInstallationLoopDocument, options);
      }
export type UpdateInstallationLoopMutationHookResult = ReturnType<typeof useUpdateInstallationLoopMutation>;
export type UpdateInstallationLoopMutationResult = Apollo.MutationResult<UpdateInstallationLoopMutation>;
export type UpdateInstallationLoopMutationOptions = Apollo.BaseMutationOptions<UpdateInstallationLoopMutation, UpdateInstallationLoopMutationVariables>;
export const DeleteInstallationLoopDocument = gql`
    mutation DeleteInstallationLoop($loopID: String!) {
  deleteInstallationLoop(loopID: $loopID)
}
    `;
export type DeleteInstallationLoopMutationFn = Apollo.MutationFunction<DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables>;
export type DeleteInstallationLoopComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables>, 'mutation'>;

    export const DeleteInstallationLoopComponent = (props: DeleteInstallationLoopComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables> mutation={DeleteInstallationLoopDocument} {...props} />
    );
    
export type DeleteInstallationLoopProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables>
    } & TChildProps;
export function withDeleteInstallationLoop<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteInstallationLoopMutation,
  DeleteInstallationLoopMutationVariables,
  DeleteInstallationLoopProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables, DeleteInstallationLoopProps<TChildProps, TDataName>>(DeleteInstallationLoopDocument, {
      alias: 'deleteInstallationLoop',
      ...operationOptions
    });
};

/**
 * __useDeleteInstallationLoopMutation__
 *
 * To run a mutation, you first call `useDeleteInstallationLoopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstallationLoopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstallationLoopMutation, { data, loading, error }] = useDeleteInstallationLoopMutation({
 *   variables: {
 *      loopID: // value for 'loopID'
 *   },
 * });
 */
export function useDeleteInstallationLoopMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables>(DeleteInstallationLoopDocument, options);
      }
export type DeleteInstallationLoopMutationHookResult = ReturnType<typeof useDeleteInstallationLoopMutation>;
export type DeleteInstallationLoopMutationResult = Apollo.MutationResult<DeleteInstallationLoopMutation>;
export type DeleteInstallationLoopMutationOptions = Apollo.BaseMutationOptions<DeleteInstallationLoopMutation, DeleteInstallationLoopMutationVariables>;
export const CreateInstallationDeviceDocument = gql`
    mutation CreateInstallationDevice($params: CreateInstallationDeviceInput!, $loopId: String!) {
  createInstallationDevice(params: $params, loopId: $loopId) {
    id
    tag
  }
}
    `;
export type CreateInstallationDeviceMutationFn = Apollo.MutationFunction<CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables>;
export type CreateInstallationDeviceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables>, 'mutation'>;

    export const CreateInstallationDeviceComponent = (props: CreateInstallationDeviceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables> mutation={CreateInstallationDeviceDocument} {...props} />
    );
    
export type CreateInstallationDeviceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables>
    } & TChildProps;
export function withCreateInstallationDevice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInstallationDeviceMutation,
  CreateInstallationDeviceMutationVariables,
  CreateInstallationDeviceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables, CreateInstallationDeviceProps<TChildProps, TDataName>>(CreateInstallationDeviceDocument, {
      alias: 'createInstallationDevice',
      ...operationOptions
    });
};

/**
 * __useCreateInstallationDeviceMutation__
 *
 * To run a mutation, you first call `useCreateInstallationDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstallationDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstallationDeviceMutation, { data, loading, error }] = useCreateInstallationDeviceMutation({
 *   variables: {
 *      params: // value for 'params'
 *      loopId: // value for 'loopId'
 *   },
 * });
 */
export function useCreateInstallationDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables>(CreateInstallationDeviceDocument, options);
      }
export type CreateInstallationDeviceMutationHookResult = ReturnType<typeof useCreateInstallationDeviceMutation>;
export type CreateInstallationDeviceMutationResult = Apollo.MutationResult<CreateInstallationDeviceMutation>;
export type CreateInstallationDeviceMutationOptions = Apollo.BaseMutationOptions<CreateInstallationDeviceMutation, CreateInstallationDeviceMutationVariables>;
export const UpdateInstallationDeviceDocument = gql`
    mutation UpdateInstallationDevice($params: UpdateInstallationDeviceInput!) {
  updateInstallationDevice(params: $params) {
    id
    tag
  }
}
    `;
export type UpdateInstallationDeviceMutationFn = Apollo.MutationFunction<UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables>;
export type UpdateInstallationDeviceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables>, 'mutation'>;

    export const UpdateInstallationDeviceComponent = (props: UpdateInstallationDeviceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables> mutation={UpdateInstallationDeviceDocument} {...props} />
    );
    
export type UpdateInstallationDeviceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables>
    } & TChildProps;
export function withUpdateInstallationDevice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInstallationDeviceMutation,
  UpdateInstallationDeviceMutationVariables,
  UpdateInstallationDeviceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables, UpdateInstallationDeviceProps<TChildProps, TDataName>>(UpdateInstallationDeviceDocument, {
      alias: 'updateInstallationDevice',
      ...operationOptions
    });
};

/**
 * __useUpdateInstallationDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateInstallationDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstallationDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstallationDeviceMutation, { data, loading, error }] = useUpdateInstallationDeviceMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateInstallationDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables>(UpdateInstallationDeviceDocument, options);
      }
export type UpdateInstallationDeviceMutationHookResult = ReturnType<typeof useUpdateInstallationDeviceMutation>;
export type UpdateInstallationDeviceMutationResult = Apollo.MutationResult<UpdateInstallationDeviceMutation>;
export type UpdateInstallationDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateInstallationDeviceMutation, UpdateInstallationDeviceMutationVariables>;
export const DeleteInstallationDeviceDocument = gql`
    mutation DeleteInstallationDevice($id: ID!) {
  deleteInstallationDevice(id: $id)
}
    `;
export type DeleteInstallationDeviceMutationFn = Apollo.MutationFunction<DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables>;
export type DeleteInstallationDeviceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables>, 'mutation'>;

    export const DeleteInstallationDeviceComponent = (props: DeleteInstallationDeviceComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables> mutation={DeleteInstallationDeviceDocument} {...props} />
    );
    
export type DeleteInstallationDeviceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables>
    } & TChildProps;
export function withDeleteInstallationDevice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteInstallationDeviceMutation,
  DeleteInstallationDeviceMutationVariables,
  DeleteInstallationDeviceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables, DeleteInstallationDeviceProps<TChildProps, TDataName>>(DeleteInstallationDeviceDocument, {
      alias: 'deleteInstallationDevice',
      ...operationOptions
    });
};

/**
 * __useDeleteInstallationDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteInstallationDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstallationDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstallationDeviceMutation, { data, loading, error }] = useDeleteInstallationDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInstallationDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables>(DeleteInstallationDeviceDocument, options);
      }
export type DeleteInstallationDeviceMutationHookResult = ReturnType<typeof useDeleteInstallationDeviceMutation>;
export type DeleteInstallationDeviceMutationResult = Apollo.MutationResult<DeleteInstallationDeviceMutation>;
export type DeleteInstallationDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteInstallationDeviceMutation, DeleteInstallationDeviceMutationVariables>;
export const UploadInstallationFileDocument = gql`
    mutation UploadInstallationFile($fileContent: String!, $systemId: String!) {
  populateDBWithInstallationFileContent(
    installationFileContent: $fileContent
    systemId: $systemId
  )
}
    `;
export type UploadInstallationFileMutationFn = Apollo.MutationFunction<UploadInstallationFileMutation, UploadInstallationFileMutationVariables>;
export type UploadInstallationFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadInstallationFileMutation, UploadInstallationFileMutationVariables>, 'mutation'>;

    export const UploadInstallationFileComponent = (props: UploadInstallationFileComponentProps) => (
      <ApolloReactComponents.Mutation<UploadInstallationFileMutation, UploadInstallationFileMutationVariables> mutation={UploadInstallationFileDocument} {...props} />
    );
    
export type UploadInstallationFileProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UploadInstallationFileMutation, UploadInstallationFileMutationVariables>
    } & TChildProps;
export function withUploadInstallationFile<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadInstallationFileMutation,
  UploadInstallationFileMutationVariables,
  UploadInstallationFileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UploadInstallationFileMutation, UploadInstallationFileMutationVariables, UploadInstallationFileProps<TChildProps, TDataName>>(UploadInstallationFileDocument, {
      alias: 'uploadInstallationFile',
      ...operationOptions
    });
};

/**
 * __useUploadInstallationFileMutation__
 *
 * To run a mutation, you first call `useUploadInstallationFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInstallationFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInstallationFileMutation, { data, loading, error }] = useUploadInstallationFileMutation({
 *   variables: {
 *      fileContent: // value for 'fileContent'
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useUploadInstallationFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadInstallationFileMutation, UploadInstallationFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadInstallationFileMutation, UploadInstallationFileMutationVariables>(UploadInstallationFileDocument, options);
      }
export type UploadInstallationFileMutationHookResult = ReturnType<typeof useUploadInstallationFileMutation>;
export type UploadInstallationFileMutationResult = Apollo.MutationResult<UploadInstallationFileMutation>;
export type UploadInstallationFileMutationOptions = Apollo.BaseMutationOptions<UploadInstallationFileMutation, UploadInstallationFileMutationVariables>;
export const InstallationLoopsDocument = gql`
    query InstallationLoops($systemID: String!) {
  installationLoops(systemID: $systemID) {
    id
    name
    status
    createdAt
    commissionedAt
    devicesCount
  }
}
    `;
export type InstallationLoopsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InstallationLoopsQuery, InstallationLoopsQueryVariables>, 'query'> & ({ variables: InstallationLoopsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InstallationLoopsComponent = (props: InstallationLoopsComponentProps) => (
      <ApolloReactComponents.Query<InstallationLoopsQuery, InstallationLoopsQueryVariables> query={InstallationLoopsDocument} {...props} />
    );
    
export type InstallationLoopsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InstallationLoopsQuery, InstallationLoopsQueryVariables>
    } & TChildProps;
export function withInstallationLoops<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InstallationLoopsQuery,
  InstallationLoopsQueryVariables,
  InstallationLoopsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InstallationLoopsQuery, InstallationLoopsQueryVariables, InstallationLoopsProps<TChildProps, TDataName>>(InstallationLoopsDocument, {
      alias: 'installationLoops',
      ...operationOptions
    });
};

/**
 * __useInstallationLoopsQuery__
 *
 * To run a query within a React component, call `useInstallationLoopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationLoopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationLoopsQuery({
 *   variables: {
 *      systemID: // value for 'systemID'
 *   },
 * });
 */
export function useInstallationLoopsQuery(baseOptions: Apollo.QueryHookOptions<InstallationLoopsQuery, InstallationLoopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallationLoopsQuery, InstallationLoopsQueryVariables>(InstallationLoopsDocument, options);
      }
export function useInstallationLoopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallationLoopsQuery, InstallationLoopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallationLoopsQuery, InstallationLoopsQueryVariables>(InstallationLoopsDocument, options);
        }
export type InstallationLoopsQueryHookResult = ReturnType<typeof useInstallationLoopsQuery>;
export type InstallationLoopsLazyQueryHookResult = ReturnType<typeof useInstallationLoopsLazyQuery>;
export type InstallationLoopsQueryResult = Apollo.QueryResult<InstallationLoopsQuery, InstallationLoopsQueryVariables>;
export function refetchInstallationLoopsQuery(variables: InstallationLoopsQueryVariables) {
      return { query: InstallationLoopsDocument, variables: variables }
    }
export const InstallationDevicesDocument = gql`
    query InstallationDevices($loopId: String!, $queryParams: QueryParameters) {
  installationDevices(loopId: $loopId, queryParams: $queryParams) {
    installationDevice {
      id
      tag
      detectionZone
      serialNumber
      heat
      flash
      sounder
      status
      createdAt
      completedAt
    }
    queryStats {
      pagination {
        totalRecords
      }
    }
  }
}
    `;
export type InstallationDevicesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InstallationDevicesQuery, InstallationDevicesQueryVariables>, 'query'> & ({ variables: InstallationDevicesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InstallationDevicesComponent = (props: InstallationDevicesComponentProps) => (
      <ApolloReactComponents.Query<InstallationDevicesQuery, InstallationDevicesQueryVariables> query={InstallationDevicesDocument} {...props} />
    );
    
export type InstallationDevicesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InstallationDevicesQuery, InstallationDevicesQueryVariables>
    } & TChildProps;
export function withInstallationDevices<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InstallationDevicesQuery,
  InstallationDevicesQueryVariables,
  InstallationDevicesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InstallationDevicesQuery, InstallationDevicesQueryVariables, InstallationDevicesProps<TChildProps, TDataName>>(InstallationDevicesDocument, {
      alias: 'installationDevices',
      ...operationOptions
    });
};

/**
 * __useInstallationDevicesQuery__
 *
 * To run a query within a React component, call `useInstallationDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationDevicesQuery({
 *   variables: {
 *      loopId: // value for 'loopId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useInstallationDevicesQuery(baseOptions: Apollo.QueryHookOptions<InstallationDevicesQuery, InstallationDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallationDevicesQuery, InstallationDevicesQueryVariables>(InstallationDevicesDocument, options);
      }
export function useInstallationDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallationDevicesQuery, InstallationDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallationDevicesQuery, InstallationDevicesQueryVariables>(InstallationDevicesDocument, options);
        }
export type InstallationDevicesQueryHookResult = ReturnType<typeof useInstallationDevicesQuery>;
export type InstallationDevicesLazyQueryHookResult = ReturnType<typeof useInstallationDevicesLazyQuery>;
export type InstallationDevicesQueryResult = Apollo.QueryResult<InstallationDevicesQuery, InstallationDevicesQueryVariables>;
export function refetchInstallationDevicesQuery(variables: InstallationDevicesQueryVariables) {
      return { query: InstallationDevicesDocument, variables: variables }
    }
export const GetSignedUrlForTemplateFileDocument = gql`
    query GetSignedUrlForTemplateFile($templateFileName: String!) {
  getSignedUrlForTemplateFile(templateFileName: $templateFileName)
}
    `;
export type GetSignedUrlForTemplateFileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables>, 'query'> & ({ variables: GetSignedUrlForTemplateFileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSignedUrlForTemplateFileComponent = (props: GetSignedUrlForTemplateFileComponentProps) => (
      <ApolloReactComponents.Query<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables> query={GetSignedUrlForTemplateFileDocument} {...props} />
    );
    
export type GetSignedUrlForTemplateFileProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables>
    } & TChildProps;
export function withGetSignedUrlForTemplateFile<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSignedUrlForTemplateFileQuery,
  GetSignedUrlForTemplateFileQueryVariables,
  GetSignedUrlForTemplateFileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables, GetSignedUrlForTemplateFileProps<TChildProps, TDataName>>(GetSignedUrlForTemplateFileDocument, {
      alias: 'getSignedUrlForTemplateFile',
      ...operationOptions
    });
};

/**
 * __useGetSignedUrlForTemplateFileQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlForTemplateFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlForTemplateFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlForTemplateFileQuery({
 *   variables: {
 *      templateFileName: // value for 'templateFileName'
 *   },
 * });
 */
export function useGetSignedUrlForTemplateFileQuery(baseOptions: Apollo.QueryHookOptions<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables>(GetSignedUrlForTemplateFileDocument, options);
      }
export function useGetSignedUrlForTemplateFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables>(GetSignedUrlForTemplateFileDocument, options);
        }
export type GetSignedUrlForTemplateFileQueryHookResult = ReturnType<typeof useGetSignedUrlForTemplateFileQuery>;
export type GetSignedUrlForTemplateFileLazyQueryHookResult = ReturnType<typeof useGetSignedUrlForTemplateFileLazyQuery>;
export type GetSignedUrlForTemplateFileQueryResult = Apollo.QueryResult<GetSignedUrlForTemplateFileQuery, GetSignedUrlForTemplateFileQueryVariables>;
export function refetchGetSignedUrlForTemplateFileQuery(variables: GetSignedUrlForTemplateFileQueryVariables) {
      return { query: GetSignedUrlForTemplateFileDocument, variables: variables }
    }
export const InstallationLoopAsCsvDocument = gql`
    query InstallationLoopAsCsv($loopId: String!) {
  installationLoopAsCsv(loopId: $loopId)
}
    `;
export type InstallationLoopAsCsvComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables>, 'query'> & ({ variables: InstallationLoopAsCsvQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InstallationLoopAsCsvComponent = (props: InstallationLoopAsCsvComponentProps) => (
      <ApolloReactComponents.Query<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables> query={InstallationLoopAsCsvDocument} {...props} />
    );
    
export type InstallationLoopAsCsvProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables>
    } & TChildProps;
export function withInstallationLoopAsCsv<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InstallationLoopAsCsvQuery,
  InstallationLoopAsCsvQueryVariables,
  InstallationLoopAsCsvProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables, InstallationLoopAsCsvProps<TChildProps, TDataName>>(InstallationLoopAsCsvDocument, {
      alias: 'installationLoopAsCsv',
      ...operationOptions
    });
};

/**
 * __useInstallationLoopAsCsvQuery__
 *
 * To run a query within a React component, call `useInstallationLoopAsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationLoopAsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationLoopAsCsvQuery({
 *   variables: {
 *      loopId: // value for 'loopId'
 *   },
 * });
 */
export function useInstallationLoopAsCsvQuery(baseOptions: Apollo.QueryHookOptions<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables>(InstallationLoopAsCsvDocument, options);
      }
export function useInstallationLoopAsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables>(InstallationLoopAsCsvDocument, options);
        }
export type InstallationLoopAsCsvQueryHookResult = ReturnType<typeof useInstallationLoopAsCsvQuery>;
export type InstallationLoopAsCsvLazyQueryHookResult = ReturnType<typeof useInstallationLoopAsCsvLazyQuery>;
export type InstallationLoopAsCsvQueryResult = Apollo.QueryResult<InstallationLoopAsCsvQuery, InstallationLoopAsCsvQueryVariables>;
export function refetchInstallationLoopAsCsvQuery(variables: InstallationLoopAsCsvQueryVariables) {
      return { query: InstallationLoopAsCsvDocument, variables: variables }
    }
export const InstallationDownloadCountDocument = gql`
    query InstallationDownloadCount($loopId: String!) {
  installationDownloadCount(loopId: $loopId)
}
    `;
export type InstallationDownloadCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables>, 'query'> & ({ variables: InstallationDownloadCountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InstallationDownloadCountComponent = (props: InstallationDownloadCountComponentProps) => (
      <ApolloReactComponents.Query<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables> query={InstallationDownloadCountDocument} {...props} />
    );
    
export type InstallationDownloadCountProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables>
    } & TChildProps;
export function withInstallationDownloadCount<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InstallationDownloadCountQuery,
  InstallationDownloadCountQueryVariables,
  InstallationDownloadCountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables, InstallationDownloadCountProps<TChildProps, TDataName>>(InstallationDownloadCountDocument, {
      alias: 'installationDownloadCount',
      ...operationOptions
    });
};

/**
 * __useInstallationDownloadCountQuery__
 *
 * To run a query within a React component, call `useInstallationDownloadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationDownloadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationDownloadCountQuery({
 *   variables: {
 *      loopId: // value for 'loopId'
 *   },
 * });
 */
export function useInstallationDownloadCountQuery(baseOptions: Apollo.QueryHookOptions<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables>(InstallationDownloadCountDocument, options);
      }
export function useInstallationDownloadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables>(InstallationDownloadCountDocument, options);
        }
export type InstallationDownloadCountQueryHookResult = ReturnType<typeof useInstallationDownloadCountQuery>;
export type InstallationDownloadCountLazyQueryHookResult = ReturnType<typeof useInstallationDownloadCountLazyQuery>;
export type InstallationDownloadCountQueryResult = Apollo.QueryResult<InstallationDownloadCountQuery, InstallationDownloadCountQueryVariables>;
export function refetchInstallationDownloadCountQuery(variables: InstallationDownloadCountQueryVariables) {
      return { query: InstallationDownloadCountDocument, variables: variables }
    }
export const InstallationTagNamesDocument = gql`
    query InstallationTagNames($systemId: String!) {
  installationTagNames(systemId: $systemId)
}
    `;
export type InstallationTagNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InstallationTagNamesQuery, InstallationTagNamesQueryVariables>, 'query'> & ({ variables: InstallationTagNamesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InstallationTagNamesComponent = (props: InstallationTagNamesComponentProps) => (
      <ApolloReactComponents.Query<InstallationTagNamesQuery, InstallationTagNamesQueryVariables> query={InstallationTagNamesDocument} {...props} />
    );
    
export type InstallationTagNamesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InstallationTagNamesQuery, InstallationTagNamesQueryVariables>
    } & TChildProps;
export function withInstallationTagNames<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InstallationTagNamesQuery,
  InstallationTagNamesQueryVariables,
  InstallationTagNamesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InstallationTagNamesQuery, InstallationTagNamesQueryVariables, InstallationTagNamesProps<TChildProps, TDataName>>(InstallationTagNamesDocument, {
      alias: 'installationTagNames',
      ...operationOptions
    });
};

/**
 * __useInstallationTagNamesQuery__
 *
 * To run a query within a React component, call `useInstallationTagNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationTagNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationTagNamesQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useInstallationTagNamesQuery(baseOptions: Apollo.QueryHookOptions<InstallationTagNamesQuery, InstallationTagNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallationTagNamesQuery, InstallationTagNamesQueryVariables>(InstallationTagNamesDocument, options);
      }
export function useInstallationTagNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallationTagNamesQuery, InstallationTagNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallationTagNamesQuery, InstallationTagNamesQueryVariables>(InstallationTagNamesDocument, options);
        }
export type InstallationTagNamesQueryHookResult = ReturnType<typeof useInstallationTagNamesQuery>;
export type InstallationTagNamesLazyQueryHookResult = ReturnType<typeof useInstallationTagNamesLazyQuery>;
export type InstallationTagNamesQueryResult = Apollo.QueryResult<InstallationTagNamesQuery, InstallationTagNamesQueryVariables>;
export function refetchInstallationTagNamesQuery(variables: InstallationTagNamesQueryVariables) {
      return { query: InstallationTagNamesDocument, variables: variables }
    }
export const AssignSystemToNodeDocument = gql`
    mutation AssignSystemToNode($systemId: String!, $nodeId: String!) {
  assignSystemToNode(systemId: $systemId, nodeId: $nodeId)
}
    `;
export type AssignSystemToNodeMutationFn = Apollo.MutationFunction<AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables>;
export type AssignSystemToNodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables>, 'mutation'>;

    export const AssignSystemToNodeComponent = (props: AssignSystemToNodeComponentProps) => (
      <ApolloReactComponents.Mutation<AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables> mutation={AssignSystemToNodeDocument} {...props} />
    );
    
export type AssignSystemToNodeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables>
    } & TChildProps;
export function withAssignSystemToNode<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignSystemToNodeMutation,
  AssignSystemToNodeMutationVariables,
  AssignSystemToNodeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables, AssignSystemToNodeProps<TChildProps, TDataName>>(AssignSystemToNodeDocument, {
      alias: 'assignSystemToNode',
      ...operationOptions
    });
};

/**
 * __useAssignSystemToNodeMutation__
 *
 * To run a mutation, you first call `useAssignSystemToNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSystemToNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSystemToNodeMutation, { data, loading, error }] = useAssignSystemToNodeMutation({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useAssignSystemToNodeMutation(baseOptions?: Apollo.MutationHookOptions<AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables>(AssignSystemToNodeDocument, options);
      }
export type AssignSystemToNodeMutationHookResult = ReturnType<typeof useAssignSystemToNodeMutation>;
export type AssignSystemToNodeMutationResult = Apollo.MutationResult<AssignSystemToNodeMutation>;
export type AssignSystemToNodeMutationOptions = Apollo.BaseMutationOptions<AssignSystemToNodeMutation, AssignSystemToNodeMutationVariables>;
export const CreateNodeDocument = gql`
    mutation CreateNode($input: CreateNodeInput!) {
  createNode(input: $input) {
    node {
      id
      name
      nodeDeviceId
      createdAt
      createdBy {
        id
        firstName
        lastName
        emailAddress
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        emailAddress
      }
      externalNetworkStatus
      ipAddress
      subnetMask
    }
    nodeDevice {
      id
      type
      imei
      iccid
      imsi
    }
  }
}
    `;
export type CreateNodeMutationFn = Apollo.MutationFunction<CreateNodeMutation, CreateNodeMutationVariables>;
export type CreateNodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNodeMutation, CreateNodeMutationVariables>, 'mutation'>;

    export const CreateNodeComponent = (props: CreateNodeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNodeMutation, CreateNodeMutationVariables> mutation={CreateNodeDocument} {...props} />
    );
    
export type CreateNodeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateNodeMutation, CreateNodeMutationVariables>
    } & TChildProps;
export function withCreateNode<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNodeMutation,
  CreateNodeMutationVariables,
  CreateNodeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNodeMutation, CreateNodeMutationVariables, CreateNodeProps<TChildProps, TDataName>>(CreateNodeDocument, {
      alias: 'createNode',
      ...operationOptions
    });
};

/**
 * __useCreateNodeMutation__
 *
 * To run a mutation, you first call `useCreateNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodeMutation, { data, loading, error }] = useCreateNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateNodeMutation, CreateNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNodeMutation, CreateNodeMutationVariables>(CreateNodeDocument, options);
      }
export type CreateNodeMutationHookResult = ReturnType<typeof useCreateNodeMutation>;
export type CreateNodeMutationResult = Apollo.MutationResult<CreateNodeMutation>;
export type CreateNodeMutationOptions = Apollo.BaseMutationOptions<CreateNodeMutation, CreateNodeMutationVariables>;
export const ArchiveSystemDocument = gql`
    mutation ArchiveSystem($systemId: String!) {
  archiveSystem(systemId: $systemId)
}
    `;
export type ArchiveSystemMutationFn = Apollo.MutationFunction<ArchiveSystemMutation, ArchiveSystemMutationVariables>;
export type ArchiveSystemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveSystemMutation, ArchiveSystemMutationVariables>, 'mutation'>;

    export const ArchiveSystemComponent = (props: ArchiveSystemComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveSystemMutation, ArchiveSystemMutationVariables> mutation={ArchiveSystemDocument} {...props} />
    );
    
export type ArchiveSystemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ArchiveSystemMutation, ArchiveSystemMutationVariables>
    } & TChildProps;
export function withArchiveSystem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArchiveSystemMutation,
  ArchiveSystemMutationVariables,
  ArchiveSystemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ArchiveSystemMutation, ArchiveSystemMutationVariables, ArchiveSystemProps<TChildProps, TDataName>>(ArchiveSystemDocument, {
      alias: 'archiveSystem',
      ...operationOptions
    });
};

/**
 * __useArchiveSystemMutation__
 *
 * To run a mutation, you first call `useArchiveSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSystemMutation, { data, loading, error }] = useArchiveSystemMutation({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useArchiveSystemMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveSystemMutation, ArchiveSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveSystemMutation, ArchiveSystemMutationVariables>(ArchiveSystemDocument, options);
      }
export type ArchiveSystemMutationHookResult = ReturnType<typeof useArchiveSystemMutation>;
export type ArchiveSystemMutationResult = Apollo.MutationResult<ArchiveSystemMutation>;
export type ArchiveSystemMutationOptions = Apollo.BaseMutationOptions<ArchiveSystemMutation, ArchiveSystemMutationVariables>;
export const ApplySiteNodeConfigurationsDocument = gql`
    mutation ApplySiteNodeConfigurations($siteId: ID!, $nodeId: ID!) {
  applySiteNodeConfigurations(siteId: $siteId, nodeId: $nodeId)
}
    `;
export type ApplySiteNodeConfigurationsMutationFn = Apollo.MutationFunction<ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables>;
export type ApplySiteNodeConfigurationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables>, 'mutation'>;

    export const ApplySiteNodeConfigurationsComponent = (props: ApplySiteNodeConfigurationsComponentProps) => (
      <ApolloReactComponents.Mutation<ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables> mutation={ApplySiteNodeConfigurationsDocument} {...props} />
    );
    
export type ApplySiteNodeConfigurationsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables>
    } & TChildProps;
export function withApplySiteNodeConfigurations<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApplySiteNodeConfigurationsMutation,
  ApplySiteNodeConfigurationsMutationVariables,
  ApplySiteNodeConfigurationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables, ApplySiteNodeConfigurationsProps<TChildProps, TDataName>>(ApplySiteNodeConfigurationsDocument, {
      alias: 'applySiteNodeConfigurations',
      ...operationOptions
    });
};

/**
 * __useApplySiteNodeConfigurationsMutation__
 *
 * To run a mutation, you first call `useApplySiteNodeConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplySiteNodeConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applySiteNodeConfigurationsMutation, { data, loading, error }] = useApplySiteNodeConfigurationsMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useApplySiteNodeConfigurationsMutation(baseOptions?: Apollo.MutationHookOptions<ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables>(ApplySiteNodeConfigurationsDocument, options);
      }
export type ApplySiteNodeConfigurationsMutationHookResult = ReturnType<typeof useApplySiteNodeConfigurationsMutation>;
export type ApplySiteNodeConfigurationsMutationResult = Apollo.MutationResult<ApplySiteNodeConfigurationsMutation>;
export type ApplySiteNodeConfigurationsMutationOptions = Apollo.BaseMutationOptions<ApplySiteNodeConfigurationsMutation, ApplySiteNodeConfigurationsMutationVariables>;
export const UnassignSystemFromNodeDocument = gql`
    mutation UnassignSystemFromNode($systemId: String!, $nodeId: String!) {
  unassignSystemFromNode(systemId: $systemId, nodeId: $nodeId)
}
    `;
export type UnassignSystemFromNodeMutationFn = Apollo.MutationFunction<UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables>;
export type UnassignSystemFromNodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables>, 'mutation'>;

    export const UnassignSystemFromNodeComponent = (props: UnassignSystemFromNodeComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables> mutation={UnassignSystemFromNodeDocument} {...props} />
    );
    
export type UnassignSystemFromNodeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables>
    } & TChildProps;
export function withUnassignSystemFromNode<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignSystemFromNodeMutation,
  UnassignSystemFromNodeMutationVariables,
  UnassignSystemFromNodeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables, UnassignSystemFromNodeProps<TChildProps, TDataName>>(UnassignSystemFromNodeDocument, {
      alias: 'unassignSystemFromNode',
      ...operationOptions
    });
};

/**
 * __useUnassignSystemFromNodeMutation__
 *
 * To run a mutation, you first call `useUnassignSystemFromNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSystemFromNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSystemFromNodeMutation, { data, loading, error }] = useUnassignSystemFromNodeMutation({
 *   variables: {
 *      systemId: // value for 'systemId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useUnassignSystemFromNodeMutation(baseOptions?: Apollo.MutationHookOptions<UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables>(UnassignSystemFromNodeDocument, options);
      }
export type UnassignSystemFromNodeMutationHookResult = ReturnType<typeof useUnassignSystemFromNodeMutation>;
export type UnassignSystemFromNodeMutationResult = Apollo.MutationResult<UnassignSystemFromNodeMutation>;
export type UnassignSystemFromNodeMutationOptions = Apollo.BaseMutationOptions<UnassignSystemFromNodeMutation, UnassignSystemFromNodeMutationVariables>;
export const SystemsWithSiteIdNodeIdDocument = gql`
    query SystemsWithSiteIdNodeId($siteId: String!, $nodeId: String!) {
  systemsWithSiteIdNodeId(siteId: $siteId, nodeId: $nodeId) {
    id
    name
    buSystemId
    type
    isArchived
    state
    networkConfigStatus
    deviceConfigStatus
    node {
      name
      id
      externalNetworkStatus
    }
    liveDataStatus
    ipAddress
    port
    userName
    userPassword
    systemConnection {
      state {
        stages {
          mobileConnection
          networkConfig
          panelConfig
          liveUpdates
        }
      }
    }
    status
  }
}
    `;
export type SystemsWithSiteIdNodeIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables>, 'query'> & ({ variables: SystemsWithSiteIdNodeIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SystemsWithSiteIdNodeIdComponent = (props: SystemsWithSiteIdNodeIdComponentProps) => (
      <ApolloReactComponents.Query<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables> query={SystemsWithSiteIdNodeIdDocument} {...props} />
    );
    
export type SystemsWithSiteIdNodeIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables>
    } & TChildProps;
export function withSystemsWithSiteIdNodeId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SystemsWithSiteIdNodeIdQuery,
  SystemsWithSiteIdNodeIdQueryVariables,
  SystemsWithSiteIdNodeIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables, SystemsWithSiteIdNodeIdProps<TChildProps, TDataName>>(SystemsWithSiteIdNodeIdDocument, {
      alias: 'systemsWithSiteIdNodeId',
      ...operationOptions
    });
};

/**
 * __useSystemsWithSiteIdNodeIdQuery__
 *
 * To run a query within a React component, call `useSystemsWithSiteIdNodeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemsWithSiteIdNodeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemsWithSiteIdNodeIdQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useSystemsWithSiteIdNodeIdQuery(baseOptions: Apollo.QueryHookOptions<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables>(SystemsWithSiteIdNodeIdDocument, options);
      }
export function useSystemsWithSiteIdNodeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables>(SystemsWithSiteIdNodeIdDocument, options);
        }
export type SystemsWithSiteIdNodeIdQueryHookResult = ReturnType<typeof useSystemsWithSiteIdNodeIdQuery>;
export type SystemsWithSiteIdNodeIdLazyQueryHookResult = ReturnType<typeof useSystemsWithSiteIdNodeIdLazyQuery>;
export type SystemsWithSiteIdNodeIdQueryResult = Apollo.QueryResult<SystemsWithSiteIdNodeIdQuery, SystemsWithSiteIdNodeIdQueryVariables>;
export function refetchSystemsWithSiteIdNodeIdQuery(variables: SystemsWithSiteIdNodeIdQueryVariables) {
      return { query: SystemsWithSiteIdNodeIdDocument, variables: variables }
    }
export const GetSystemsBySiteIdDocument = gql`
    query GetSystemsBySiteId($siteId: String!, $limit: Int, $filter: SystemsFilter) {
  getSystemsBySiteId(siteId: $siteId, limit: $limit, filter: $filter) {
    id
    name
    buSystemId
    type
    isArchived
    state
    networkConfigStatus
    deviceConfigStatus
    node {
      name
      id
      externalNetworkStatus
      nodeDeviceId
    }
    liveDataStatus
    ipAddress
    port
    userName
    userPassword
    systemConnection {
      state {
        stages {
          mobileConnection
          networkConfig
          panelConfig
          liveUpdates
        }
      }
    }
    status
  }
}
    `;
export type GetSystemsBySiteIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables>, 'query'> & ({ variables: GetSystemsBySiteIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSystemsBySiteIdComponent = (props: GetSystemsBySiteIdComponentProps) => (
      <ApolloReactComponents.Query<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables> query={GetSystemsBySiteIdDocument} {...props} />
    );
    
export type GetSystemsBySiteIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables>
    } & TChildProps;
export function withGetSystemsBySiteId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSystemsBySiteIdQuery,
  GetSystemsBySiteIdQueryVariables,
  GetSystemsBySiteIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables, GetSystemsBySiteIdProps<TChildProps, TDataName>>(GetSystemsBySiteIdDocument, {
      alias: 'getSystemsBySiteId',
      ...operationOptions
    });
};

/**
 * __useGetSystemsBySiteIdQuery__
 *
 * To run a query within a React component, call `useGetSystemsBySiteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemsBySiteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemsBySiteIdQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSystemsBySiteIdQuery(baseOptions: Apollo.QueryHookOptions<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables>(GetSystemsBySiteIdDocument, options);
      }
export function useGetSystemsBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables>(GetSystemsBySiteIdDocument, options);
        }
export type GetSystemsBySiteIdQueryHookResult = ReturnType<typeof useGetSystemsBySiteIdQuery>;
export type GetSystemsBySiteIdLazyQueryHookResult = ReturnType<typeof useGetSystemsBySiteIdLazyQuery>;
export type GetSystemsBySiteIdQueryResult = Apollo.QueryResult<GetSystemsBySiteIdQuery, GetSystemsBySiteIdQueryVariables>;
export function refetchGetSystemsBySiteIdQuery(variables: GetSystemsBySiteIdQueryVariables) {
      return { query: GetSystemsBySiteIdDocument, variables: variables }
    }
export const GetUnconnectedSystemsDocument = gql`
    query GetUnconnectedSystems($siteId: String!) {
  systemsWithNoConnection(siteId: $siteId) {
    id
    name
    buSystemId
    type
    isArchived
    state
    networkConfigStatus
    deviceConfigStatus
    node {
      name
      id
      externalNetworkStatus
    }
    liveDataStatus
    ipAddress
    port
    userName
    userPassword
    systemConnection {
      state {
        stages {
          mobileConnection
          networkConfig
          panelConfig
          liveUpdates
        }
      }
    }
    status
  }
}
    `;
export type GetUnconnectedSystemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables>, 'query'> & ({ variables: GetUnconnectedSystemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnconnectedSystemsComponent = (props: GetUnconnectedSystemsComponentProps) => (
      <ApolloReactComponents.Query<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables> query={GetUnconnectedSystemsDocument} {...props} />
    );
    
export type GetUnconnectedSystemsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables>
    } & TChildProps;
export function withGetUnconnectedSystems<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnconnectedSystemsQuery,
  GetUnconnectedSystemsQueryVariables,
  GetUnconnectedSystemsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables, GetUnconnectedSystemsProps<TChildProps, TDataName>>(GetUnconnectedSystemsDocument, {
      alias: 'getUnconnectedSystems',
      ...operationOptions
    });
};

/**
 * __useGetUnconnectedSystemsQuery__
 *
 * To run a query within a React component, call `useGetUnconnectedSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnconnectedSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnconnectedSystemsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetUnconnectedSystemsQuery(baseOptions: Apollo.QueryHookOptions<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables>(GetUnconnectedSystemsDocument, options);
      }
export function useGetUnconnectedSystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables>(GetUnconnectedSystemsDocument, options);
        }
export type GetUnconnectedSystemsQueryHookResult = ReturnType<typeof useGetUnconnectedSystemsQuery>;
export type GetUnconnectedSystemsLazyQueryHookResult = ReturnType<typeof useGetUnconnectedSystemsLazyQuery>;
export type GetUnconnectedSystemsQueryResult = Apollo.QueryResult<GetUnconnectedSystemsQuery, GetUnconnectedSystemsQueryVariables>;
export function refetchGetUnconnectedSystemsQuery(variables: GetUnconnectedSystemsQueryVariables) {
      return { query: GetUnconnectedSystemsDocument, variables: variables }
    }
export const UpdateNodeDocument = gql`
    mutation UpdateNode($input: UpdateNodeInput!) {
  updateNode(input: $input) {
    node {
      id
      name
      nodeDeviceId
      createdAt
      createdBy {
        id
        firstName
        lastName
        emailAddress
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        emailAddress
      }
      externalNetworkStatus
      ipAddress
      subnetMask
    }
    nodeDevice {
      id
      type
      imei
      iccid
      imsi
    }
  }
}
    `;
export type UpdateNodeMutationFn = Apollo.MutationFunction<UpdateNodeMutation, UpdateNodeMutationVariables>;
export type UpdateNodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateNodeMutation, UpdateNodeMutationVariables>, 'mutation'>;

    export const UpdateNodeComponent = (props: UpdateNodeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateNodeMutation, UpdateNodeMutationVariables> mutation={UpdateNodeDocument} {...props} />
    );
    
export type UpdateNodeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateNodeMutation, UpdateNodeMutationVariables>
    } & TChildProps;
export function withUpdateNode<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateNodeMutation,
  UpdateNodeMutationVariables,
  UpdateNodeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateNodeMutation, UpdateNodeMutationVariables, UpdateNodeProps<TChildProps, TDataName>>(UpdateNodeDocument, {
      alias: 'updateNode',
      ...operationOptions
    });
};

/**
 * __useUpdateNodeMutation__
 *
 * To run a mutation, you first call `useUpdateNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNodeMutation, { data, loading, error }] = useUpdateNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNodeMutation, UpdateNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNodeMutation, UpdateNodeMutationVariables>(UpdateNodeDocument, options);
      }
export type UpdateNodeMutationHookResult = ReturnType<typeof useUpdateNodeMutation>;
export type UpdateNodeMutationResult = Apollo.MutationResult<UpdateNodeMutation>;
export type UpdateNodeMutationOptions = Apollo.BaseMutationOptions<UpdateNodeMutation, UpdateNodeMutationVariables>;
export const GetNodesListDocument = gql`
    query GetNodesList($siteId: ID!, $limit: Int) {
  siteNodes(siteId: $siteId, limit: $limit) {
    nodesWithDevices {
      node {
        id
        name
        nodeDeviceId
        createdAt
        createdBy {
          id
          firstName
          lastName
          emailAddress
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          emailAddress
        }
        externalNetworkStatus
        ipAddress
        subnetMask
      }
      nodeDevice {
        id
        type
        imei
        iccid
        imsi
        serialNumber
      }
    }
  }
}
    `;
export type GetNodesListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNodesListQuery, GetNodesListQueryVariables>, 'query'> & ({ variables: GetNodesListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetNodesListComponent = (props: GetNodesListComponentProps) => (
      <ApolloReactComponents.Query<GetNodesListQuery, GetNodesListQueryVariables> query={GetNodesListDocument} {...props} />
    );
    
export type GetNodesListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetNodesListQuery, GetNodesListQueryVariables>
    } & TChildProps;
export function withGetNodesList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNodesListQuery,
  GetNodesListQueryVariables,
  GetNodesListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetNodesListQuery, GetNodesListQueryVariables, GetNodesListProps<TChildProps, TDataName>>(GetNodesListDocument, {
      alias: 'getNodesList',
      ...operationOptions
    });
};

/**
 * __useGetNodesListQuery__
 *
 * To run a query within a React component, call `useGetNodesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodesListQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetNodesListQuery(baseOptions: Apollo.QueryHookOptions<GetNodesListQuery, GetNodesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNodesListQuery, GetNodesListQueryVariables>(GetNodesListDocument, options);
      }
export function useGetNodesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNodesListQuery, GetNodesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNodesListQuery, GetNodesListQueryVariables>(GetNodesListDocument, options);
        }
export type GetNodesListQueryHookResult = ReturnType<typeof useGetNodesListQuery>;
export type GetNodesListLazyQueryHookResult = ReturnType<typeof useGetNodesListLazyQuery>;
export type GetNodesListQueryResult = Apollo.QueryResult<GetNodesListQuery, GetNodesListQueryVariables>;
export function refetchGetNodesListQuery(variables: GetNodesListQueryVariables) {
      return { query: GetNodesListDocument, variables: variables }
    }
export const SystemNameDocument = gql`
    query SystemName($systemId: String!) {
  systemWithSystemId(systemId: $systemId) {
    id
    name
  }
}
    `;
export type SystemNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SystemNameQuery, SystemNameQueryVariables>, 'query'> & ({ variables: SystemNameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SystemNameComponent = (props: SystemNameComponentProps) => (
      <ApolloReactComponents.Query<SystemNameQuery, SystemNameQueryVariables> query={SystemNameDocument} {...props} />
    );
    
export type SystemNameProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SystemNameQuery, SystemNameQueryVariables>
    } & TChildProps;
export function withSystemName<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SystemNameQuery,
  SystemNameQueryVariables,
  SystemNameProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SystemNameQuery, SystemNameQueryVariables, SystemNameProps<TChildProps, TDataName>>(SystemNameDocument, {
      alias: 'systemName',
      ...operationOptions
    });
};

/**
 * __useSystemNameQuery__
 *
 * To run a query within a React component, call `useSystemNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemNameQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useSystemNameQuery(baseOptions: Apollo.QueryHookOptions<SystemNameQuery, SystemNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemNameQuery, SystemNameQueryVariables>(SystemNameDocument, options);
      }
export function useSystemNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemNameQuery, SystemNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemNameQuery, SystemNameQueryVariables>(SystemNameDocument, options);
        }
export type SystemNameQueryHookResult = ReturnType<typeof useSystemNameQuery>;
export type SystemNameLazyQueryHookResult = ReturnType<typeof useSystemNameLazyQuery>;
export type SystemNameQueryResult = Apollo.QueryResult<SystemNameQuery, SystemNameQueryVariables>;
export function refetchSystemNameQuery(variables: SystemNameQueryVariables) {
      return { query: SystemNameDocument, variables: variables }
    }
export const GetUserByOktaIdDocument = gql`
    query GetUserByOktaId($oktaId: String!) {
  getUserByOktaId(oktaId: $oktaId) {
    user {
      id
      oktaId
      emailAddress
      firstName
      lastName
      phoneNumber
      preferredLanguage
      status
      updatedBy
      updatedAt
      createdBy
      createdAt
      invitedAt
      role
      areAllNotificationsEnabled
      tenant {
        id
        name
        address1
        address2
        postalCode
        country
        city
        contactPerson {
          firstName
          lastName
          phoneNumber
          email
        }
        createdAt
      }
    }
    role
  }
}
    `;
export type GetUserByOktaIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>, 'query'> & ({ variables: GetUserByOktaIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserByOktaIdComponent = (props: GetUserByOktaIdComponentProps) => (
      <ApolloReactComponents.Query<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables> query={GetUserByOktaIdDocument} {...props} />
    );
    
export type GetUserByOktaIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>
    } & TChildProps;
export function withGetUserByOktaId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserByOktaIdQuery,
  GetUserByOktaIdQueryVariables,
  GetUserByOktaIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables, GetUserByOktaIdProps<TChildProps, TDataName>>(GetUserByOktaIdDocument, {
      alias: 'getUserByOktaId',
      ...operationOptions
    });
};

/**
 * __useGetUserByOktaIdQuery__
 *
 * To run a query within a React component, call `useGetUserByOktaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByOktaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByOktaIdQuery({
 *   variables: {
 *      oktaId: // value for 'oktaId'
 *   },
 * });
 */
export function useGetUserByOktaIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>(GetUserByOktaIdDocument, options);
      }
export function useGetUserByOktaIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>(GetUserByOktaIdDocument, options);
        }
export type GetUserByOktaIdQueryHookResult = ReturnType<typeof useGetUserByOktaIdQuery>;
export type GetUserByOktaIdLazyQueryHookResult = ReturnType<typeof useGetUserByOktaIdLazyQuery>;
export type GetUserByOktaIdQueryResult = Apollo.QueryResult<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>;
export function refetchGetUserByOktaIdQuery(variables: GetUserByOktaIdQueryVariables) {
      return { query: GetUserByOktaIdDocument, variables: variables }
    }
export const GetUsersDocument = gql`
    query GetUsers($sorting: UsersSorting, $pagination: UserPagination, $tenantId: String) {
  getUsers(sorting: $sorting, pagination: $pagination, tenantId: $tenantId) {
    users {
      id
      oktaId
      emailAddress
      firstName
      lastName
      phoneNumber
      preferredLanguage
      status
      updatedBy
      updatedAt
      createdBy
      createdAt
      invitedAt
      role
      tenant {
        id
        name
        address1
        address2
        postalCode
        country
        city
        contactPerson {
          firstName
          lastName
          phoneNumber
          email
        }
        createdAt
      }
    }
    numberOfRecords
    totalPages
    currentPage
    totalRecords
  }
}
    `;
export type GetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersQuery, GetUsersQueryVariables>, 'query'>;

    export const GetUsersComponent = (props: GetUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUsersQuery, GetUsersQueryVariables> query={GetUsersDocument} {...props} />
    );
    
export type GetUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUsersQuery, GetUsersQueryVariables>
    } & TChildProps;
export function withGetUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUsersQuery,
  GetUsersQueryVariables,
  GetUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUsersQuery, GetUsersQueryVariables, GetUsersProps<TChildProps, TDataName>>(GetUsersDocument, {
      alias: 'getUsers',
      ...operationOptions
    });
};

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *      pagination: // value for 'pagination'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const ResetNotificationSettingsDocument = gql`
    mutation resetNotificationSettings {
  resetNotificationSettings
}
    `;
export type ResetNotificationSettingsMutationFn = Apollo.MutationFunction<ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables>;
export type ResetNotificationSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables>, 'mutation'>;

    export const ResetNotificationSettingsComponent = (props: ResetNotificationSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables> mutation={ResetNotificationSettingsDocument} {...props} />
    );
    
export type ResetNotificationSettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables>
    } & TChildProps;
export function withResetNotificationSettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetNotificationSettingsMutation,
  ResetNotificationSettingsMutationVariables,
  ResetNotificationSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables, ResetNotificationSettingsProps<TChildProps, TDataName>>(ResetNotificationSettingsDocument, {
      alias: 'resetNotificationSettings',
      ...operationOptions
    });
};

/**
 * __useResetNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useResetNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetNotificationSettingsMutation, { data, loading, error }] = useResetNotificationSettingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables>(ResetNotificationSettingsDocument, options);
      }
export type ResetNotificationSettingsMutationHookResult = ReturnType<typeof useResetNotificationSettingsMutation>;
export type ResetNotificationSettingsMutationResult = Apollo.MutationResult<ResetNotificationSettingsMutation>;
export type ResetNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<ResetNotificationSettingsMutation, ResetNotificationSettingsMutationVariables>;
export const UpdateNotificationSettingsDocument = gql`
    mutation updateNotificationSettings($input: UpdateNotificationSettingsInput!) {
  updateNotificationSettings(input: $input)
}
    `;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;
export type UpdateNotificationSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>, 'mutation'>;

    export const UpdateNotificationSettingsComponent = (props: UpdateNotificationSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables> mutation={UpdateNotificationSettingsDocument} {...props} />
    );
    
export type UpdateNotificationSettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>
    } & TChildProps;
export function withUpdateNotificationSettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables,
  UpdateNotificationSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables, UpdateNotificationSettingsProps<TChildProps, TDataName>>(UpdateNotificationSettingsDocument, {
      alias: 'updateNotificationSettings',
      ...operationOptions
    });
};

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UpdateNotificationSettingsDocument, options);
      }
export type UpdateNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;
export const GetDropdownTenantsDocument = gql`
    query GetDropdownTenants {
  tenants {
    id
    name
  }
}
    `;
export type GetDropdownTenantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables>, 'query'>;

    export const GetDropdownTenantsComponent = (props: GetDropdownTenantsComponentProps) => (
      <ApolloReactComponents.Query<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables> query={GetDropdownTenantsDocument} {...props} />
    );
    
export type GetDropdownTenantsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables>
    } & TChildProps;
export function withGetDropdownTenants<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDropdownTenantsQuery,
  GetDropdownTenantsQueryVariables,
  GetDropdownTenantsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables, GetDropdownTenantsProps<TChildProps, TDataName>>(GetDropdownTenantsDocument, {
      alias: 'getDropdownTenants',
      ...operationOptions
    });
};

/**
 * __useGetDropdownTenantsQuery__
 *
 * To run a query within a React component, call `useGetDropdownTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDropdownTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDropdownTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDropdownTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables>(GetDropdownTenantsDocument, options);
      }
export function useGetDropdownTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables>(GetDropdownTenantsDocument, options);
        }
export type GetDropdownTenantsQueryHookResult = ReturnType<typeof useGetDropdownTenantsQuery>;
export type GetDropdownTenantsLazyQueryHookResult = ReturnType<typeof useGetDropdownTenantsLazyQuery>;
export type GetDropdownTenantsQueryResult = Apollo.QueryResult<GetDropdownTenantsQuery, GetDropdownTenantsQueryVariables>;
export function refetchGetDropdownTenantsQuery(variables?: GetDropdownTenantsQueryVariables) {
      return { query: GetDropdownTenantsDocument, variables: variables }
    }
export const AddUserDocument = gql`
    mutation AddUser($user: UserProfileInput) {
  addUser(user: $user) {
    id
    oktaId
    emailAddress
    firstName
    lastName
    phoneNumber
    preferredLanguage
    status
    updatedBy
    updatedAt
    createdBy
    createdAt
    invitedAt
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;
export type AddUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddUserMutation, AddUserMutationVariables>, 'mutation'>;

    export const AddUserComponent = (props: AddUserComponentProps) => (
      <ApolloReactComponents.Mutation<AddUserMutation, AddUserMutationVariables> mutation={AddUserDocument} {...props} />
    );
    
export type AddUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>
    } & TChildProps;
export function withAddUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddUserMutation,
  AddUserMutationVariables,
  AddUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddUserMutation, AddUserMutationVariables, AddUserProps<TChildProps, TDataName>>(AddUserDocument, {
      alias: 'addUser',
      ...operationOptions
    });
};

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const namedOperations = {
  Query: {
    PushNotifications: 'PushNotifications',
    CurrentUser: 'CurrentUser',
    GetTemplatesCount: 'GetTemplatesCount',
    GetTemplateAssignedTenantsList: 'GetTemplateAssignedTenantsList',
    GetInspectionMasterTemplates: 'GetInspectionMasterTemplates',
    GetMasterTemplateBasicDetails: 'GetMasterTemplateBasicDetails',
    GetTemplateUnAssignedTenantsList: 'GetTemplateUnAssignedTenantsList',
    GetCustomTemplateDetails: 'GetCustomTemplateDetails',
    GetMasterTemplateDetails: 'GetMasterTemplateDetails',
    GetBusinessUnitFromTenant: 'GetBusinessUnitFromTenant',
    GetDashboardTenantSites: 'GetDashboardTenantSites',
    GetInspectionsByDate: 'GetInspectionsByDate',
    GetInspectionTemplates: 'GetInspectionTemplates',
    GetSignedUrl: 'GetSignedUrl',
    DownloadMasterTemplate: 'DownloadMasterTemplate',
    DownloadReport: 'DownloadReport',
    GetInspectionDetails: 'GetInspectionDetails',
    GetInspections: 'GetInspections',
    GetTenantBasicDetails: 'GetTenantBasicDetails',
    GetSiteStatus: 'GetSiteStatus',
    GetAllSites: 'GetAllSites',
    GetNodeStatus: 'GetNodeStatus',
    GetSiteAssignableUsers: 'GetSiteAssignableUsers',
    GetSiteAssignedUsers: 'GetSiteAssignedUsers',
    GetAlarmsHeatMap: 'GetAlarmsHeatMap',
    ConnectivityStatus: 'ConnectivityStatus',
    GetEventStatus: 'GetEventStatus',
    GetAlarmsByDate: 'GetAlarmsByDate',
    GetGatewaysData: 'GetGatewaysData',
    GetUnitsData: 'GetUnitsData',
    GetDeviceElements: 'GetDeviceElements',
    GetDevices: 'GetDevices',
    GetEventDetails: 'GetEventDetails',
    GetEventsHistoryData: 'GetEventsHistoryData',
    GetEventsBySite: 'GetEventsBySite',
    GetSiteDetails: 'GetSiteDetails',
    GetFalseActivationSystemAnalytics: 'GetFalseActivationSystemAnalytics',
    GetPumpConditionMonitoring: 'GetPumpConditionMonitoring',
    DownloadSystemConfiguration: 'DownloadSystemConfiguration',
    RequestLatestSystemConfiguration: 'RequestLatestSystemConfiguration',
    SystemConfigsWithSystemId: 'SystemConfigsWithSystemId',
    GetSystemWithSystemId: 'GetSystemWithSystemId',
    InstallationLoops: 'InstallationLoops',
    InstallationDevices: 'InstallationDevices',
    GetSignedUrlForTemplateFile: 'GetSignedUrlForTemplateFile',
    InstallationLoopAsCsv: 'InstallationLoopAsCsv',
    InstallationDownloadCount: 'InstallationDownloadCount',
    InstallationTagNames: 'InstallationTagNames',
    SystemsWithSiteIdNodeId: 'SystemsWithSiteIdNodeId',
    GetSystemsBySiteId: 'GetSystemsBySiteId',
    GetUnconnectedSystems: 'GetUnconnectedSystems',
    GetNodesList: 'GetNodesList',
    SystemName: 'SystemName',
    GetUserByOktaId: 'GetUserByOktaId',
    GetUsers: 'GetUsers',
    GetDropdownTenants: 'GetDropdownTenants'
  },
  Mutation: {
    DismissAllNotifications: 'DismissAllNotifications',
    dismissNotification: 'dismissNotification',
    ReadAllNotifications: 'ReadAllNotifications',
    readNotification: 'readNotification',
    DeviceRegistration: 'DeviceRegistration',
    UnRegisterDevice: 'UnRegisterDevice',
    UnassignMasterTemplates: 'UnassignMasterTemplates',
    ArchiveMasterTemplate: 'ArchiveMasterTemplate',
    AssignMasterTemplateToTenants: 'AssignMasterTemplateToTenants',
    CreateMasterTemplate: 'CreateMasterTemplate',
    UpdateMasterTemplate: 'UpdateMasterTemplate',
    DeleteInspection: 'DeleteInspection',
    ScheduleInspection: 'ScheduleInspection',
    CreateInspectionTemplate: 'CreateInspectionTemplate',
    UpdateCustomTemplate: 'UpdateCustomTemplate',
    ShareInspection: 'ShareInspection',
    CreateSite: 'CreateSite',
    AssignUsers: 'AssignUsers',
    UnassignUsers: 'UnassignUsers',
    UpdateSiteWithBusinessUnit: 'UpdateSiteWithBusinessUnit',
    CreateSystem: 'CreateSystem',
    DeleteSystem: 'DeleteSystem',
    UpdateSystem: 'UpdateSystem',
    CreateInstallationLoop: 'CreateInstallationLoop',
    UpdateInstallationLoop: 'UpdateInstallationLoop',
    DeleteInstallationLoop: 'DeleteInstallationLoop',
    CreateInstallationDevice: 'CreateInstallationDevice',
    UpdateInstallationDevice: 'UpdateInstallationDevice',
    DeleteInstallationDevice: 'DeleteInstallationDevice',
    UploadInstallationFile: 'UploadInstallationFile',
    AssignSystemToNode: 'AssignSystemToNode',
    CreateNode: 'CreateNode',
    ArchiveSystem: 'ArchiveSystem',
    ApplySiteNodeConfigurations: 'ApplySiteNodeConfigurations',
    UnassignSystemFromNode: 'UnassignSystemFromNode',
    UpdateNode: 'UpdateNode',
    resetNotificationSettings: 'resetNotificationSettings',
    updateNotificationSettings: 'updateNotificationSettings',
    AddUser: 'AddUser'
  }
}